import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import {
  typeListObject,
  typeRestyle,
  typeSupplier,
  typeSupplierMarkupRange,
} from 'src/types';

export function useGetSuppliers(
  {
    variables,
    skip,
  }: {
    variables: { limit?: number; offset?: number };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeListObject<typeSupplier>>({
    url: `/suppliers`,
    method: 'get',
    skip,
    query: variables,
  });
}

export function useGetSupplier(
  {
    variables,
    skip,
  }: {
    variables: { supplierId?: number | string };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeSupplier>({
    url: `/suppliers/${variables.supplierId}`,
    method: 'get',
    skip,
  });
}

export function usePutMarkupRange() {
  return useRequestWithPayload<
    typeSupplierMarkupRange,
    { id?: number; price: number; markup: number; supplier: { id: number } }
  >({
    url: '/markup-ranges',
    method: 'put',
  });
}

export function useCalculateMarkup() {
  return useRequestWithPayload<
    { startPrice: number; offerPrice: number },
    { startPrice: number; supplier: Partial<typeSupplier> }
  >({
    url: '/markup-ranges/calculate',
    method: 'post',
  });
}
