import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';
import { useGetCurrencies } from 'src/api/billingApi';

type typeProps = {
  currency?: string | null;
  disabled?: boolean;
  onChange?: (currency: { key: string; value: string }) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'BillingCurrencySelect' },
);

export const BillingCurrencySelect: React.FunctionComponent<typeProps> = ({
  currency,
  onChange,
  disabled,
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState<string | null>(null);

  const { data, loading } = useGetCurrencies();

  useEffect(() => {
    if (currency) setValue(currency);
  }, [currency]);

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <Box className={classes.root} display="flex">
      <Autocomplete
        disabled={disabled}
        fullWidth
        value={value}
        onChange={(e, value: any) => hangdeOnChange(value)}
        options={data ? Object.keys(data) : []}
        getOptionLabel={(option: any) => option}
        label="Валюта"
        loading={loading}
      />
    </Box>
  );
};
