import { useRequest } from 'src/hooks/useRequest';

export function useGetCoutries(
  {
    variables,
    skip,
  }: {
    variables?: {};
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<any, typeof variables>({
    url: '/coutries',
    method: 'get',
    query: variables,
    skip,
  });
}
