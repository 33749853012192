import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Autocomplete } from './common/autocomplete';
import { typeOrderCustomerSource } from 'src/types';
import { useGetOrdersCustomersSources } from 'src/api/ordersApi';

interface typeProps {
  onChange?: (value: typeOrderCustomerSource | null) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'OrdersCustomersSourcesSelect' },
);

export const OrdersCustomersSourcesSelect: React.FunctionComponent<typeProps> = ({
  onChange,
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState<typeOrderCustomerSource | null>(null);

  const request = useGetOrdersCustomersSources();

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        fullWidth
        value={value}
        onChange={(e, value: any) => hangdeOnChange(value)}
        options={request.data || []}
        getOptionLabel={(option: any) => option.name}
        label="Где нашел"
        loading={request.loading}
      />
    </div>
  );
};
