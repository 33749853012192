import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { typeContractor } from 'src/types';
import { deepPurple, green, red } from '@material-ui/core/colors';

interface typeProps {
  contractor?: typeContractor | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ContractorLabel' },
);

const bg = {
  razborka: red[700],
  sto: green[700],
  tyurin: deepPurple[700],
};

export const ContractorLabel: React.FunctionComponent<typeProps> = ({
  contractor,
}) => {
  const classes = useStyles({});

  if (!contractor) return null;

  return (
    <span
      className={classes.root}
      style={{
        padding: '1px 4px',
        borderRadius: '3px',
        fontSize: '12px',
        //@ts-ignore
        backgroundColor: bg[contractor?.alias],
      }}
    >
      {contractor?.name}
    </span>
  );
};
