import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';

import { Attributes } from 'src/widgets/attributes';

import { AttributeCreateDialog } from 'src/features/attribute-create-dialog';

import { useGetAttributes } from 'src/entities/attribute';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'AttributesPage' },
);

export const AttributesPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const requestAttributes = useGetAttributes();

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="end">
        <AttributeCreateDialog onResponse={requestAttributes.refetch} />
      </Box>

      <Attributes
        attibutes={requestAttributes.data}
        onChange={requestAttributes.refetch}
      />
    </div>
  );
};
