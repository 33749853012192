import { request } from 'src/services/request';

export function setCharacteristicToStockItem(params: {
  stockItemId: number | string;
  attributeId: number | string;
  value: { value: string | number | boolean };
}) {
  return request.put(
    `/stock-items/${params.stockItemId}/characteristics/${params.attributeId}`,
    params.value,
  );
}
