import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Autocomplete } from './common/autocomplete';
import { typeProductTarget } from 'src/types';
import {
  useGetProductTargets,
  useGetProductTypesCategories,
} from 'src/api/productTypeApi';
import { orderBy } from 'lodash';

interface typeProps {
  target?: typeProductTarget | null;
  onChange?: (value: any) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductTargetCategorySelect' },
);

export const ProductTargetCategorySelect: React.FunctionComponent<typeProps> = ({
  target,
  onChange,
}) => {
  const classes = useStyles({});

  const [value, setValue] = useState<typeProductTarget | null>(null);

  const categoruies = useGetProductTypesCategories({
    variables: { targetId: target?.id },
    skip: !target?.id,
  });

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        fullWidth
        value={value}
        onChange={(e, value: any) => hangdeOnChange(value)}
        options={orderBy(categoruies.data, ['name']) || []}
        getOptionLabel={(option: any) => option.name}
        label="Категрия цели"
        loading={categoruies.loading}
      />
    </div>
  );
};
