import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useCreateOrder } from 'src/api/ordersApi';
import { useRouter } from 'src/hooks/useRouter';
import {
  typeContractor,
  typeCustomer,
  typeOrder,
  typeOrderCustomerSource,
  typeOrderSource,
} from 'src/types';
import { OrdersCustomersSourcesSelect } from './OrdersCustomersSourcesSelect';
import { OrderSourceSelect } from './OrderSourceSelect';
import { WithLoading } from './common/with-loading';
import { CustomerSelect } from './CustomerSelect';
import { ContractorSelect } from './ContractorSelect';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'OrderCreate' },
);

export const OrderCreate: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});
  const { push } = useRouter();

  const [dialog, setDialog] = useState(false);
  const [orderCustomerSource, setCustomerSource] =
    useState<typeOrderCustomerSource | null>(null);
  const [orderSource, setOrderSource] = useState<typeOrderSource | null>(null);
  const [contractor, setContractor] = useState<typeContractor | null>(null);
  const [phone, setPhone] = useState('');
  const [customer, setCustomer] = useState<typeCustomer | null>(null);
  const [newCustomer, setNewCustomer] = useState<typeCustomer | null>({
    firstName: null,
    middleName: null,
    secondName: null,
    organizationName: null,
    phone: { value: null },
  });

  const [create, createData] = useCreateOrder();

  const handleCreate = () => {
    const orderCustomer =
      customer ||
      (phone?.length === 10
        ? {
            ...newCustomer,
            phone: { value: phone },
          }
        : null);
    if (customer || newCustomer) {
      create({
        orderCustomerSource,
        orderSource,
        customer: orderCustomer,
        contractor,
      }).then((order: typeOrder) => {
        push(`/orders/${order.id}`);
      });
    }
  };

  const disabled = Boolean(
    createData.loading || !orderSource || !orderCustomerSource || !contractor,
  );

  return (
    <div className={classes.root}>
      <Fab color="primary" size="small" onClick={() => setDialog(true)}>
        <Add />
      </Fab>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <CustomerSelect
            phone={phone}
            onChange={setPhone}
            onChangeNewCustomer={setNewCustomer}
            onSelect={setCustomer}
            customer={customer}
            newCustomer={newCustomer}
          />
          <Box mt={2}>
            <OrdersCustomersSourcesSelect onChange={setCustomerSource} />
          </Box>
          <Box mt={2}>
            <OrderSourceSelect onChange={setOrderSource} />
          </Box>
          <Box mt={2}>
            <ContractorSelect label="Продавец" onSelect={setContractor} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Закрыть</Button>
          <WithLoading loading={createData.loading}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreate}
              disabled={disabled}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
};
