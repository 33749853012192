import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Box,
  Paper,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import {
  typeBillingAccount,
  typeBillingJournalReason,
  typeBillingJournalType,
  typeContractor,
  typeEmployee,
  typeOrder,
  typeVehicle,
  typeWarehouse,
} from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';
import { useCreateBillingJournal } from 'src/api/billingApi';
import { BillingAccountSelect } from './BillingAccountSelect';
import { BillingJournalTypeSelect } from './BillingJournalTypeSelect';
import { BillingJournalReasonSelect } from './BillingJournalReasonSelect';
import { EmployeeSelect } from './EmployeeSelect';
import { Alert } from '@material-ui/lab';

type typeProps = {
  warehouse?: typeWarehouse | null;
  onCreate: (newPlace?: typeBillingAccount | null) => any;
  order?: typeOrder | null;
  vehicle?: typeVehicle | null;
  value?: number | null;
  from?: typeBillingAccount | null;
  to?: typeBillingAccount | null;
  add?: boolean | null;
  filterReason?: (item: typeBillingJournalReason) => boolean;
  filterType?: (item: typeBillingJournalType) => boolean;
  contractor?: typeContractor | null;
};

type typeState = {
  from: typeBillingAccount | null;
  to: typeBillingAccount | null;
  currency: string | null;
  value: number;
  newCurrencyValue: number;
  comment: string | null;
  order: any;
  vehicle: typeVehicle | null;
  reason: typeBillingJournalReason | null;
  type: typeBillingJournalType | null;
  employee: typeEmployee | null;
  commission: number;
};

const initialState = {
  from: null,
  to: null,
  currency: 'RUB',
  comment: null,
  order: null,
  vehicle: null,
  reason: null,
  type: null,
  value: 0,
  newCurrencyValue: 0,
  commission: 0,
};

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'BillingJournalCreate',
});

export const BillingJournalCreate: React.FunctionComponent<typeProps> = ({
  onCreate,
  order = null,
  vehicle = null,
  value = 0,
  filterReason,
  filterType,
  from = null,
  to = null,
  add = true,
  contractor,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);

  const [state, changeState] = useState<typeState>({
    from: null,
    to: null,
    currency: 'RUB',
    comment: null,
    order: null,
    reason: null,
    vehicle: null,
    type: null,
    employee: null,
    value: 0,
    newCurrencyValue: 0,
    commission: 0,
  });

  const setState = (data: Partial<typeState>) =>
    changeState((prevState: typeState) => ({ ...prevState, ...data }));

  useEffect(() => {
    setState({
      order,
      vehicle,
      value: value || 0,
      from: state.from || from,
      to: state.to || to,
    });
  }, [order, vehicle, value, from, to]);

  const [create, { loading }] = useCreateBillingJournal();

  const handleCreate = () => {
    if (state.from && state.to && state.value && state.type) {
      create({
        from: state.from,
        to: state.to,
        value: state.value * 100,
        comment: state.comment,
        vehicle: state.vehicle,
        order: state.order,
        type: state.type,
        reason: state.reason,
        employee: state.employee,
        commission: state.commission * 100,
        newCurrencyValue: state.newCurrencyValue * 100,
      }).then(() => {
        setDialog(false);
        setState({ ...initialState, order, vehicle });
        if (onCreate) {
          onCreate();
        }
      });
    }
  };

  const getErrors = () => {
    const errors = [];
    if (
      state?.from &&
      state?.to &&
      state.type &&
      state.type.name !== 'Обмен валюты' &&
      state?.from?.currency &&
      state?.to?.currency &&
      state?.from?.currency !== state?.to?.currency
    ) {
      errors.push('Перевод возможен только между счетами с одной валютой');
    }
    if (
      state?.from &&
      state?.to &&
      state.type &&
      state.type.name === 'Обмен валюты' &&
      state?.from?.currency &&
      state?.to?.currency &&
      state?.from?.currency === state?.to?.currency
    ) {
      errors.push('Обмен валюты только между счетами с разной валютой');
    }
    if (state?.from && state?.to && state?.from.id === state?.to.id) {
      errors.push('Перевод возомжен только между разными счетами');
    }

    if (
      state?.reason?.name === 'Перевод между своими счетами' &&
      state?.from?.contractor &&
      state?.to?.contractor &&
      state?.from?.contractor?.id !== state?.to?.contractor?.id
    ) {
      errors.push(
        '"Перевод между своими счетами" возможен только между счетами одной и той же организации',
      );
    }

    return errors;
  };

  const errors = getErrors();

  return (
    <React.Fragment>
      <Fab
        color={add ? 'primary' : 'secondary'}
        onClick={() => setDialog(true)}
        size="small"
      >
        {add ? <Add /> : <Remove />}
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)} fullWidth>
        <DialogContent>
          {order && (
            <Box mt={2}>
              <TextField
                label="Заказ"
                variant="outlined"
                value={order?.id}
                disabled
                fullWidth
              />
            </Box>
          )}
          {vehicle && (
            <Box mt={2}>
              <TextField
                label="Автомобиль"
                variant="outlined"
                value={vehicle?.name}
                disabled
                fullWidth
              />
            </Box>
          )}
          <Box mt={2}>
            <BillingJournalTypeSelect
              type={state.type}
              filter={filterType}
              onChange={type => setState({ type })}
            />
          </Box>
          <Box mt={2}>
            <BillingJournalReasonSelect
              filter={filterReason}
              onChange={reason => setState({ reason })}
            />
          </Box>
          <Box mt={2}>
            <BillingAccountSelect
              label="Отправитель"
              account={state.from}
              from={state.from}
              to={state.to}
              contractor={contractor}
              onChange={from => setState({ from })}
            />
          </Box>
          <Box mt={2}>
            <BillingAccountSelect
              label="Получатель"
              account={state.to}
              from={state.from}
              to={state.to}
              contractor={contractor}
              onChange={to =>
                setState({ to, currency: to?.currency ? to.currency : null })
              }
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              label="Сумма"
              variant="outlined"
              value={state.value}
              type="number"
              onChange={e => {
                setState({
                  value: parseFloat(parseFloat(e.target.value).toFixed(2)),
                  commission: 0,
                });
              }}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              label="Комментарий"
              variant="outlined"
              value={state.comment}
              onChange={e => setState({ comment: e.target.value })}
            />
          </Box>
          <Box mt={2}>
            <EmployeeSelect onChange={employee => setState({ employee })} />
          </Box>
          {state.type?.name === 'Обмен валюты' && (
            <Box mt={2}>
              <TextField
                fullWidth
                label="Сумма в новой валюте"
                variant="outlined"
                value={state.newCurrencyValue}
                type="number"
                onChange={e =>
                  setState({ newCurrencyValue: parseInt(e.target.value, 10) })
                }
              />
            </Box>
          )}
          {Boolean(state?.from && state?.to && state.value) && (
            <>
              <Box mt={8}>
                <TextField
                  fullWidth
                  disabled
                  label={`Со счета [${state?.from?.name}] будет списано`}
                  variant="outlined"
                  value={`${state.value} ${state.from?.currency || ''}`}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  fullWidth
                  disabled
                  label={`На счет [${state?.to?.name}] будет зачислено`}
                  variant="outlined"
                  value={`${state.value} ${state.to?.currency || ''}`}
                />
              </Box>
            </>
          )}
          {Boolean(errors.length) && (
            <Box mt={2}>
              <Paper>
                <Box p={2}>
                  {errors.map(error => (
                    <Box mb={1}>
                      <Alert severity="warning">{error}</Alert>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Box>
          )}
          <Box mt={2}>
            <TextField
              disabled={state.from?.id !== 1}
              fullWidth
              label="Комиссия"
              variant="outlined"
              value={state.commission}
              type='number'
              onChange={e =>
                setState({
                  commission: parseFloat(parseFloat(e.target.value).toFixed(2)),
                })
              }
            />
            <Box mt={1}>
              <Button
                disabled={state.from?.id !== 1}
                variant="outlined"
                onClick={() =>
                  setState({
                    commission: Math.ceil(state.value * 100 * 0.025) / 100,
                  })
                }
              >
                2,5%
              </Button>
              <Button
                disabled={state.from?.id !== 1}
                variant="outlined"
                onClick={() => {
                  const value = Math.ceil(state.value * 100 * 0.007) / 100;
                  setState({
                    commission: value > 1500 ? 1500 : value,
                  });
                }}
              >
                0,007%
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={Boolean(
                !state.from ||
                  !state.to ||
                  !state.type ||
                  !state.value ||
                  errors.length,
              )}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
