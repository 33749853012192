import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Typography,
  Toolbar,
  IconButton,
  TableHead,
  TableCell,
  Table as MuiTable,
  TablePagination,
  TableRow,
  TableBody,
  TableCellBaseProps,
  Checkbox,
} from '@material-ui/core';
import { Close, Refresh } from '@material-ui/icons';
import { WithLoading } from 'src/components/common/with-loading';
import { brown } from '@material-ui/core/colors';
import cn from 'classnames';

type typeProps = {
  onFetch?: (params?: { limit: number; offset: number }) => any;
  onCancel?: any;
  loading: boolean;
  withPagination?: boolean;
  collapsable?: boolean;
  totalCount?: number;
  title?: string;
  onClickRow?: (item: any) => any;
  rowClassNames?: (item: any) => string;
  headers: Array<{
    title: string;
    extract: (item: any, index?: number) => React.ReactNode;
    component?: React.ElementType<TableCellBaseProps>;
    scope?: TableCellBaseProps['scope'];
    warning?: (item: any) => boolean;
    summary?: React.ReactNode;
    align?: 'left' | 'right';
  }>;
  data?: any[] | null;
  limit?: number;
  offset?: number;
  withSummary?: boolean;
  size?: 'small' | 'medium';
  actions?: ((params: { selectedItems: any }) => React.ReactNode)[];
};

type typeState = {
  rowsPerPage: number;
  page: number;
  open: boolean;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      title: {
        flex: '1 1 100%',
      },
      warning: {
        backgroundColor: brown[700],
      },
      index: {
        color: theme.palette.grey[500],
      },
    }),
  { name: 'Table' },
);

export const Table: React.FunctionComponent<typeProps> = ({
  title,
  onFetch,
  onCancel,
  loading,
  data,
  totalCount = 1,
  onClickRow,
  headers,
  actions,
  withSummary,
  size = 'medium',
  collapsable = false,
  rowClassNames,
  withPagination = true,
  limit: providedLimit = 5,
  offset: providedOffset = 0,
}) => {
  const classes = useStyles({});

  const [state, changeState] = useState<typeState>({
    rowsPerPage: 5,
    page: 0,
    open: false,
  });

  const [selectedItems, setSelectedItems] = useState<typeof data>([]);

  useEffect(() => {
    if (selectedItems?.length) {
      setSelectedItems(
        selectedItems.filter(item => data?.find(el => el?.id === item?.id)),
      );
    }
  }, [data]);

  const setState = (data: Partial<typeState>) => {
    const nextState = { ...state, ...data };

    changeState(nextState);

    if (onFetch) {
      onFetch({
        limit: nextState.rowsPerPage,
        offset: nextState.page * nextState.rowsPerPage,
      });
    }
  };

  useEffect(() => {
    changeState({
      ...state,
      rowsPerPage: providedLimit,
      page: providedOffset / providedLimit,
    });
  }, [providedLimit, providedOffset]);

  const table = (
    <WithLoading loading={loading} onCancel={onCancel}>
      <MuiTable aria-label="table" size={size}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.index}>#</TableCell>
            {headers.map(header =>
              header.title === 'checkbox' ? (
                <TableCell key={header.title}>
                  <Checkbox
                    checked={Boolean(data?.length === selectedItems?.length)}
                    onChange={() =>
                      data?.length !== selectedItems?.length
                        ? setSelectedItems(data)
                        : setSelectedItems([])
                    }
                  />
                </TableCell>
              ) : (
                <TableCell key={header.title} align={header.align}>
                  {header.title}
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item: any, index) => (
            <TableRow
              className={rowClassNames && rowClassNames(item)}
              hover
              key={item.id}
              onClick={e => (onClickRow ? onClickRow(item) : null)}
            >
              <TableCell className={classes.index} title={item?.id || ''}>
                {index + 1}
              </TableCell>
              {headers.map(header =>
                header.title === 'checkbox' ? (
                  <TableCell key={header.title}>
                    <Checkbox
                      checked={Boolean(
                        selectedItems?.find(
                          selectedItem => selectedItem.id === item.id,
                        ),
                      )}
                      onChange={() =>
                        selectedItems &&
                        selectedItems.find(
                          selectedItem => selectedItem.id === item.id,
                        )
                          ? setSelectedItems(
                              selectedItems.filter(
                                selectedItem => selectedItem.id !== item.id,
                              ),
                            )
                          : selectedItems &&
                            setSelectedItems([...selectedItems, item])
                      }
                    />
                  </TableCell>
                ) : (
                  <TableCell
                    key={header.title}
                    component={header.component}
                    scope={header.scope}
                    align={header.align}
                    className={cn(
                      header.warning && header.warning(item) && classes.warning,
                    )}
                  >
                    {header.extract(item, index)}
                  </TableCell>
                ),
              )}
            </TableRow>
          ))}
          {withSummary && (
            <TableRow>
              <TableCell />
              {headers.map(header => (
                <TableCell key={header.title} align={header.align}>
                  {header.summary}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
      {withPagination && totalCount > 1 && (
        <TablePagination
          labelRowsPerPage="элементов на странице"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`
          }
          rowsPerPageOptions={totalCount > 50 ? [] : [5, 25, 50]}
          component="div"
          count={totalCount || 1}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={(e, value) => setState({ page: value })}
          onChangeRowsPerPage={e =>
            setState({ rowsPerPage: parseInt(e.target.value, 10) })
          }
        />
      )}
    </WithLoading>
  );

  return (
    <div className={classes.root}>
      {Boolean(title || onFetch) && (
        <Toolbar
          onClick={() => collapsable && setState({ open: !state.open })}
          style={{ cursor: collapsable ? 'pointer' : 'default' }}
        >
          {title && (
            <Typography className={classes.title} variant="h6" component="div">
              {title}
            </Typography>
          )}
          {actions?.map((action, index) => (
            <React.Fragment key={index}>
              {action({ selectedItems })}
            </React.Fragment>
          ))}
          {onFetch && (
            <IconButton onClick={() => (loading ? onCancel() : onFetch())}>
              {loading ? <Close /> : <Refresh />}
            </IconButton>
          )}
        </Toolbar>
      )}
      {collapsable ? state.open && table : table}
    </div>
  );
};
