import { request } from 'src/services/request';
import { typeWarehouse, typeWarehousePlace, typeListObject } from 'src/types';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import { useRequest } from 'src/hooks/useRequest';

export const getWarehouses = (): Promise<{ data: typeWarehouse[] }> =>
  request.get(`/warehouses`);

export function useGetWarehouses({
  variables,
  skip,
}: {
  variables?: {};
  skip?: boolean;
}) {
  return useRequest<typeListObject<typeWarehousePlace>, typeof variables>({
    url: '/warehouses',
    method: 'get',
    query: variables,
    skip,
  });
}

export const uploadPriceList = ({
  warehouseId,
  file,
}: {
  warehouseId: number;
  file: File;
}) => request.post(`/warehouses/${warehouseId}`, file);

export function useGetWarehousePlaces({
  variables,
  skip,
}: {
  variables: { warehouseId?: number | null };
  skip?: boolean;
}) {
  return useRequest<typeListObject<typeWarehousePlace>, typeof variables>({
    url: '/warehouses/places',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useCreateWarehousePlace() {
  return useRequestWithPayload<
    typeWarehousePlace,
    { name: string; warehouse: typeWarehouse }
  >({
    url: '/warehouses/places',
    method: 'post',
  });
}

export function useUploadPrice() {
  return useRequestWithPayload<any, FormData>({
    url: `/prices`,
    method: 'post',
  });
}

export function useGetPriceFiles({
  variables,
  skip,
}: {
  variables?: {};
  skip?: boolean;
}) {
  return useRequest<any, typeof variables>({
    url: '/warehouses/price-files',
    method: 'get',
    query: variables,
    skip,
  });
}
