import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box } from '@material-ui/core';
import { useGetModel } from 'src/api/modelApi';
import { useGetBrand } from 'src/api/brandApi';
import { useGetBody } from 'src/api/bodyApi';
import { useRouter } from 'src/hooks/useRouter';
import { typeBrand, typeModel, typeBody } from 'src/types';
import { BrandSelect } from 'src/components/brand-select';
import { ModelSelect } from 'src/components/model-select';
import { BodySelect } from 'src/components/body-select';
import { Restyles } from 'src/components/restyles';
import { useGetRestyles } from 'src/api/restylesApi';
import { RestyleCreate } from 'src/components/restyle-create';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'RestylesPage' },
);

export const RestylesPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { query, push } = useRouter();

  const { data: brand } = useGetBrand({
    variables: {
      brandId: query.brandId,
    },
    skip: !query.brandId,
  });

  const { data: model } = useGetModel({
    variables: {
      modelId: query.modelId,
    },
    skip: !query.modelId,
  });

  const { data: body } = useGetBody({
    variables: {
      bodyId: query.bodyId,
    },
    skip: !query.bodyId,
  });

  const variables = {
    limit: parseInt(query.limit, 10) || 5,
    offset: parseInt(query.offset, 10) || 0,
    bodyId: parseInt(query.bodyId, 10),
  };

  const { data, loading, refetch, cancel } = useGetRestyles({
    variables,
    skip: !query.bodyId,
  });

  const handleOnFetch = (data?: { limit: number; offset: number }) => {
    if (data) {
      return push(null, { ...query, ...data });
    }
    refetch({ ...variables, ...query });
  };

  return (
    <div className={classes.root}>
      <BrandSelect
        isCarManufacturer
        brand={brand}
        onChange={(value: typeBrand | null) => {
          const brandId = value?.id;
          push(null, { brandId });
        }}
      />
      <Box mt={2}>
        <ModelSelect
          model={model}
          brand={query.brandId ? brand : null}
          onChange={(value: typeModel | null) => {
            const modelId = value?.id;
            const brandId = value?.brand?.id;
            push(null, { modelId, brandId });
          }}
        />
      </Box>
      <Box mt={2}>
        <BodySelect
          model={model}
          body={body}
          onChange={(value: typeBody | null) => {
            const bodyId = value?.id;
            const modelId = value?.model?.id;
            const brandId = value?.model?.brand?.id;
            push(null, { brandId, bodyId, modelId });
          }}
        />
      </Box>
      <Box mt={2}>
        <Restyles
          restyles={data}
          onFetch={handleOnFetch}
          onCancel={cancel}
          loading={loading}
          limit={variables.limit}
          offset={variables.offset}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <RestyleCreate
          body={body}
          onCreate={() =>
            refetch({
              limit: query.limit || 5,
              offset: query.offset || 0,
              bodyId: query.bodyId,
            })
          }
        />
      </Box>
    </div>
  );
};
