import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';

import { Attribute } from 'src/shared/api';

interface typeProps {
  attributes: Attribute[];
  onChange: (attribute: Attribute) => any;
  value: Attribute | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'AttributeSelect' },
);

export const AttributeSelect: React.FunctionComponent<typeProps> = ({
  attributes,
  value,
  onChange,
}) => {
  const classes = useStyles({});

  return (
    <Autocomplete
      fullWidth
      value={value}
      onChange={(e, value: any) => onChange(value)}
      options={attributes || []}
      getOptionLabel={(option: any) => option.name}
      label="Атрибут"
      loading={!Array.isArray(attributes)}
    />
  );
};
