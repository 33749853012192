import React, { ChangeEvent, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { createSTOWork } from 'src/entities/sto-work';

interface typeProps {
  onSave?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'STOWorkCreateDialog' },
);

export const STOWorkCreateDialog: React.FunctionComponent<typeProps> = ({
  onSave,
}) => {
  const classes = useStyles({});

  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [price, setPrice] = useState<null | string>(null);
  const [comment, setComment] = useState<null | string>(null);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSave = () => {
    if (name) {
      createSTOWork({
        name,
        price: price ? parseInt(price, 10) : null,
        comment: comment || null,
      }).then(onSave);
    }
    handleClose();
  };

  return (
    <>
      <Fab size="small" onClick={handleOpen} color="primary">
        <Add />
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Добавить работу</DialogTitle>
        <DialogContent>
          <TextField
            label="Название"
            value={name}
            onChange={e => setName(e.target.value)}
            variant="outlined"
          />
          <Box mt={2}>
            <TextField
              label="Цена"
              value={price || ''}
              onChange={e => setPrice(e.target.value)}
              type="number"
              variant="outlined"
            />
          </Box>
          <Box mt={2}>
            <TextField
              multiline
              rows={3}
              label="Описание"
              value={comment || ''}
              onChange={e => setComment(e.target.value)}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button
            disabled={!name}
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
