import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, IconButton, Paper, Theme } from '@material-ui/core';
import { useGetBrands, useGetUndefinedBrands } from 'src/api/brandApi';
import { Brands } from 'src/components/brands';
import { BrandCreate } from '../BrandCreate';
import { Table } from '../common/table';
import { Add } from '@material-ui/icons';
import { useRouter } from 'src/hooks/useRouter';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'BrandsPage' },
);

export const BrandsPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { query } = useRouter();
  const [undefinedBrand, setUndefinedBrand] = useState<any>(null);
  const vaiables = {
    limit: parseInt(query.limit, 10) || 10,
    offset: parseInt(query.offset, 10) || 0,
  };
  const { data, loading, refetch, cancel } = useGetBrands(vaiables);

  const undefinedBrandsVariables = {
    limit: 50,
    offset: 0,
    name: query.undefinedName,
  };

  const {
    data: undefinedBrands,
    loading: loadingUndefinedBrands,
    refetch: refetchUndefinedBrandsLoading,
  } = useGetUndefinedBrands({ variables: undefinedBrandsVariables });

  return (
    <div className={classes.root}>
      <Brands
        brands={data}
        onFetch={() => refetch(vaiables)}
        onCancel={cancel}
        loading={loading}
      />

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <BrandCreate
          onCreate={() => {
            refetch({ limit: 5, offset: 0 });
            refetchUndefinedBrandsLoading();
            setUndefinedBrand(null);
          }}
          undefinedBrand={undefinedBrand}
        />
      </Box>
      <Box mt={2}>
        <Paper>
          <Table
            withSummary
            title="Неизвестные бренды"
            data={undefinedBrands?.data}
            totalCount={undefinedBrands?.totalCount}
            headers={[
              {
                title: 'id',
                extract: (item: any) => item?.id,

                component: 'th',
                scope: 'row',
              },
              {
                title: 'Название',
                extract: (item: any) => item?.name,
              },
              {
                title: 'Создать',
                extract: (item: any) => (
                  <IconButton onClick={() => setUndefinedBrand(item)}>
                    <Add />
                  </IconButton>
                ),
              },
            ]}
            loading={loadingUndefinedBrands}
            limit={undefinedBrandsVariables.limit || 0}
            offset={undefinedBrandsVariables.offset}
          />
        </Paper>
      </Box>
    </div>
  );
};
