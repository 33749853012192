import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';
import { useGetJournalReasons } from 'src/api/billingApi';
import { typeBillingJournalReason } from 'src/types';
import { orderBy } from 'lodash';

type typeProps = {
  reason?: typeBillingJournalReason | null;
  disabled?: boolean;
  filter?: (item: typeBillingJournalReason) => boolean;
  onChange?: (type: typeBillingJournalReason) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'BillingJournalReasonSelect' },
);

export const BillingJournalReasonSelect: React.FunctionComponent<typeProps> = ({
  reason,
  filter: providedFilter,
  onChange,
  disabled,
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState(null);

  const { data, loading } = useGetJournalReasons();

  const filter = (item: typeBillingJournalReason) => {
    if (providedFilter) return providedFilter(item);
    return item;
  };

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <Box className={classes.root} display="flex">
      <Autocomplete
        disabled={disabled}
        fullWidth
        value={value}
        onChange={(e, value: typeBillingJournalReason) => hangdeOnChange(value)}
        options={orderBy(data?.data?.filter(filter) || [], ['name'])}
        getOptionLabel={(option: typeBillingJournalReason) => `${option.name}`}
        label="Основание"
        loading={loading}
      />
    </Box>
  );
};
