import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField, ListItemText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useGetModels } from 'src/api/modelApi';
import { typeModel, typeBrand } from 'src/types';
import { orderBy } from 'lodash';

type typeProps = {
  onChange?: (model: typeModel | null) => any;
  model?: typeModel | null;
  brand?: typeBrand | null;
  disabled?: boolean;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ModelSelect' },
);

export const ModelSelect: React.FunctionComponent<typeProps> = ({
  onChange,
  model,
  brand,
  disabled,
}) => {
  const classes = useStyles({});

  const [value, setValue] = useState<typeModel | null>(null);

  const { data } = useGetModels({ variables: { brandId: brand?.id } });

  useEffect(() => {
    const valueFromArray =
      data && model
        ? (data?.data.find(item => item.id === model.id) as typeModel)
        : null;
    setValue(valueFromArray);
  }, [model, data]);

  const handleOnChange = (nextBrand: typeModel | null) => {
    setValue(nextBrand);
    if (onChange) {
      onChange(nextBrand);
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      onChange={(e, value) => handleOnChange(value)}
      options={orderBy(data?.data || [], ['name'])}
      getOptionLabel={(option: typeModel) => option.name}
      renderOption={(option: typeModel) => (
        <ListItemText
          primary={option.name}
          secondary={option.brand?.originalName}
        />
      )}
      renderInput={(params: any) => (
        <TextField {...params} label="Модель" variant="outlined" />
      )}
    />
  );
};
