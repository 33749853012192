import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { useGetOrder } from 'src/api/ordersApi';
import { request } from 'src/services/request';
import {
  typeDelivery,
  typeOrder,
  typeOrderElement,
  typeOrderToSupplierElement,
} from 'src/types';
import { WithLoading } from './common/with-loading';
import { Delivery } from './pages/Delivery';
import { Table } from './common/table';
import format from 'date-fns/format';
import { StockItemListItem } from './StockItemListItem';
import { filter } from 'lodash';

interface typeProps {
  delivery?: typeDelivery | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        // backgroundColor: '#fff',
        // color: '#000 !important',
        height: '100%',
      },
      '@media print': {
        root: {
          backgroundColor: '#fff',
          color: '#000',
          height: '100%',
          '& a, div, td': {
            color: '#000',
          },
        },
      },
      '@page': {
        size: 'A4 portrait',
        margin: '0cm 0cm',
      },
    }),
  { name: 'Waybill' },
);

export const Waybill: React.FunctionComponent<typeProps> = ({ delivery }) => {
  const classes = useStyles({});
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<typeOrder[]>([]);

  useEffect(() => {
    if (delivery) {
      Promise.all(
        delivery?.orders?.map(orderId =>
          request.get(`/orders/${orderId.id}`).then(res =>res.data),
        ) as Promise<any>[],
      ).then(orders => {
        setOrders(orders);
        setLoading(false);
        window.print();
      });
    }
  }, [delivery]);

  return (
    <div className={classes.root}>
      <WithLoading loading={loading}>
        <Delivery delivery={delivery} />
        <Box mt={2}>
          {orders.map(order => (
            <Box key={order.id}>
              <Table
                withPagination={false}
                title={`Заказ ${order.id} ${
                  order.createdAt &&
                  format(new Date(order.createdAt), 'yyyy-MM-dd HH:mm:ss')
                }`}
                data={
                  order?.elements
                    ?.filter(
                      item =>
                        (item.quantity || 0) >
                        (item.orderElementReturns?.reduce(
                          (prev, curr) => prev + (curr?.quantity || 0),
                          0,
                        ) || 0),
                    )
                    .sort((a, b) =>
                      a?.id && b?.id && a?.id > b?.id ? -1 : 1,
                    ) || []
                }
                totalCount={order?.elements?.length || 0}
                headers={[
                  {
                    title: 'Товар',
                    extract: (item: typeOrderElement) =>
                      item?.stockItem && (
                        <StockItemListItem
                          stockItem={item?.stockItem}
                          withPrice={false}
                          withVehicle
                        />
                      ),

                    scope: 'row',
                  },
                  {
                    title: 'Место',
                    extract: (item: typeOrderElement) =>
                      item?.stockItem?.warehousePlace?.name,

                    scope: 'row',
                  },
                  {
                    title: 'Количество',
                    extract: (item: typeOrderElement) =>
                      item?.orderRealizationElement && (
                        <span>
                          {(item?.orderRealizationElement?.quantity || 0) -
                            (item.orderElementReturns?.reduce(
                              (prev, curr) => prev + (curr?.quantity || 0),
                              0,
                            ) || 0)}{' '}
                          шт.
                        </span>
                      ),

                    scope: 'row',
                  },
                  {
                    title: 'Сумма',
                    align: 'right',
                    extract: (item: typeOrderElement) => (
                      <Box
                        style={{ cursor: 'pointer' }}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                      >
                        <div title="Сумма">
                          <b>
                            {item?.orderRealizationElement?.totalCost &&
                              item?.orderRealizationElement?.totalCost /
                                100}{' '}
                            ₽
                          </b>
                        </div>
                        <div title="Цена за штуку">
                          {item?.orderRealizationElement?.offerPrice &&
                            item?.orderRealizationElement?.offerPrice /
                              100}{' '}
                          ₽
                        </div>
                      </Box>
                    ),

                    scope: 'row',
                  },
                ]}
                loading={loading}
                limit={order?.elements?.length || 0}
                offset={0}
              />
            </Box>
          ))}
        </Box>
      </WithLoading>
    </div>
  );
};
