import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Attribute, Product, StockItem } from 'src/shared/api';
import { CharacteristicsEdit } from './CharacteristicsEdit';
import { setCharacteristicToProduct } from 'src/entities/product';
import { setCharacteristicToStockItem } from 'src/entities/stock-item';

interface typeProps {
  stockItem?: StockItem | null;
  attributes?: Attribute[]  | null;
  onSave: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'StockItemCharacteristicsEdit' },
);

export const StockItemCharacteristicsEdit: React.FunctionComponent<
  typeProps
> = ({ stockItem, attributes, onSave }) => {
  const classes = useStyles({});

  const handleChange = (
    attributeId: number,
    value: string | number | boolean,
  ) => {
    if (stockItem) {
      setCharacteristicToStockItem({
        stockItemId: stockItem?.id,
        attributeId,
        value: { value },
      }).then(onSave);
    }
  };

  return (
    <CharacteristicsEdit
      onChange={handleChange}
      onSave={onSave}
      characteristics={stockItem?.characteristics}
      attributes={attributes}
    />
  );
};
