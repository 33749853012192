import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { addAValueToStringAttribure } from 'src/entities/attribute';
import { Brand } from 'src/shared/api';
import { addSeriesToBrand } from 'src/entities/series';

interface typeProps {
  onSave?: () => any;
  brand: Brand;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'SeriesCreate' },
);

export const SeriesCreate: React.FunctionComponent<typeProps> = ({
  onSave,
  brand,
}) => {
  const classes = useStyles({});

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSetValue = (e: ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  const handleSave = () => {
    if (value && brand.id) {
      addSeriesToBrand(brand.id, { name: value }).then(onSave);
    }
    handleClose();
  };

  return (
    <>
      <Fab size="small" onClick={handleOpen} color="primary">
        <Add />
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Создать серию для {brand.originalName}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Название"
            value={value}
            onChange={handleSetValue}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button
            disabled={!value}
            onClick={handleSave}
            variant="contained"
            color="primary"
          >
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
