import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Theme,
} from '@material-ui/core';
import { typeOrderElement } from 'src/types';
import { useGetBillingJournal } from 'src/api/billingApi';
import { useChangeOrderElement } from 'src/api/ordersApi';

interface typeProps {
  orderElement: typeOrderElement;
  onSave: () => any;
  disabled?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'OrderElementDialog' },
);

export const OrderElementDialog: React.FunctionComponent<typeProps> = ({
  orderElement,
  children,
  onSave,
  disabled = false,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);
  const [element, setElement] = useState<Partial<typeOrderElement>>({
    quantity: 0,
    offerPrice: 0,
  });

  useEffect(() => {
    if (orderElement) {
      setElement({
        ...element,
        quantity: orderElement.quantity,
        offerPrice: orderElement?.offerPrice && orderElement?.offerPrice / 100,
      });
    }
  }, [orderElement?.quantity, orderElement?.offerPrice]);

  const [change] = useChangeOrderElement({
    variables: { id: orderElement?.id },
  });

  const handleChange = () => {
    change({
      ...element,
      offerPrice: element.offerPrice && element.offerPrice * 100,
    }).then(() => {
      setDialog(false);
      onSave();
    });
  };

  return (
    <div className={classes.root}>
      <div onClick={() => !disabled && setDialog(true)}>{children}</div>
      <Dialog open={dialog} onClose={() => setDialog(!dialog)}>
        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            value={element.quantity}
            label="Количество"
            onChange={e =>
              setElement({ ...element, quantity: parseInt(e.target.value, 10) })
            }
          />
          <Box mt={2}>
            <TextField
              variant="outlined"
              fullWidth
              value={element.offerPrice}
              label="Продажная цена"
              onChange={e =>
                setElement({
                  ...element,
                  offerPrice: parseInt(e.target.value, 10),
                })
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Закрыть</Button>
          <Button color="primary" onClick={handleChange}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
