import { useRequest } from 'src/hooks/useRequest';
import { request } from 'src/services/request';
import { Contractor, Customer, Diagnostics, Vehicle } from 'src/shared/api';

export function createDiagnostic(value: {
  contractor: Partial<Contractor>;
  customer: Partial<Customer>;
  vehice?: Partial<Vehicle>;
}) {
  return request.post(`/diagnostics`, value);
}

export function useGetDiagnostic({
  variables,
  skip,
}: {
  variables: { id: number | string };
  skip?: boolean;
}) {
  return useRequest<Diagnostics, typeof variables>({
    url: `/diagnostics/${variables.id}`,
    method: 'get',
    skip,
  });
}
