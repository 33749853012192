import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Fab, IconButton, Theme } from '@material-ui/core';
import { typeCustomer, typeDelivery, typeOrder } from 'src/types';
import { useGetDeliveries } from 'src/api/deliveryApi';
import { Autocomplete } from 'src/components/common/autocomplete';
import { DeliveryCreate } from 'src/components/DeliveryCreate';
import { Edit, Print } from '@material-ui/icons';

interface typeProps {
  customer?: typeCustomer | null;
  delivery?: typeDelivery | null;
  order?: typeOrder | null;
  disabled?: boolean | null;
  onChange?: (value: typeDelivery | null) => any;
  onCreate?: (value: typeDelivery | null) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'DeliverySelect' },
);

export const DeliverySelect: React.FunctionComponent<typeProps> = ({
  onCreate,
  onChange,
  customer,
  delivery,
  order,
  disabled,
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState<typeDelivery | null>(null);
  const [editOpen, setEditOpen] = useState(false);

  const request = useGetDeliveries({
    variables: {
      customerId: customer?.id,
      finishedAt: 'null',
    },
    skip: !customer,
  });

  useEffect(() => {
    const valueFromArray =
      request?.data && delivery
        ? (request?.data?.data?.find(
            (item: any) => delivery && item?.id === delivery?.id,
          ) as typeDelivery)
        : null;
    if (valueFromArray) {
      setValue(valueFromArray);
    }
  }, [delivery, request?.data]);

  const hangdeOnChange = (value: typeDelivery) => {
    setValue(value);
    onChange && onChange(value);
  };

  const handleOnCreate = (value: typeDelivery | null) => {
    request
      .refetch({
        customerId: customer?.id,
      })
      .then(() => setValue(value));
    onCreate && onCreate(value);
  };

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <DeliveryCreate
        customer={customer}
        delivery={delivery}
        open={editOpen}
        onClose={() => setEditOpen(false)}
        onCreate={handleOnCreate}
        withButton={false}
      />
      <Autocomplete
        disabled={Boolean(disabled)}
        fullWidth
        value={value}
        onChange={(e, value: any) => hangdeOnChange(value)}
        options={request.data?.data || []}
        getOptionLabel={(option: typeDelivery) =>
          [option.id, option.deliveryCompany?.name, option?.address]
            .filter(i => i)
            .join(', ')
        }
        label="Доставка"
        loading={request.loading}
        actions={[
          delivery && (
            <IconButton
              size="small"
              onClick={() => setEditOpen(true)}
              disabled={Boolean(delivery?.finishedAt)}
            >
              <Edit />
            </IconButton>
          ),
        ]}
      />
      <Box ml={2}>
        <DeliveryCreate
          disabled={Boolean(delivery?.finishedAt)}
          customer={customer}
          order={order}
          onCreate={handleOnCreate}
        />
      </Box>
      <Box ml={2}>
        <Fab
          disabled={!delivery}
          size="small"
          target="_blank"
          href={`/deliveries-print?deliveryId=${delivery?.id}`}
          color='primary'
        >
          <Print />
        </Fab>
      </Box>
    </Box>
  );
};
