import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useGetBrands } from 'src/api/brandApi';
import { typeBrand } from 'src/types';
import { orderBy } from 'lodash';

type typeProps = {
  onChange?: (brand: typeBrand | null) => any;
  brand?: typeBrand | null;
  disabled?: boolean;
  isCarManufacturer?: boolean;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'BrandSelect' },
);

export const BrandSelect: React.FunctionComponent<typeProps> = ({
  onChange,
  brand,
  disabled,
  isCarManufacturer,
}) => {
  const classes = useStyles({});

  const [value, setBrand] = useState<typeBrand | null>(null);

  const variables = {};

  if (isCarManufacturer) {
    // @ts-ignore
    variables.isCarManufacturer = true;
  }

  const { data } = useGetBrands(variables);

  useEffect(() => {
    const brandFromArray =
      data && brand
        ? (data?.data.find(item => item.id === brand.id) as typeBrand)
        : null;
    setBrand(brandFromArray);
  }, [brand, data]);

  const handleOnChange = (nextBrand: typeBrand | null) => {
    setBrand(nextBrand);
    if (onChange) {
      onChange(nextBrand);
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      onChange={(e, value) => handleOnChange(value)}
      options={
        orderBy(
          data?.data,
          item =>
            `${JSON.stringify(!item.isCarManufacturer)} ${item.originalName}`,
        ) || []
      }
      getOptionLabel={(option: typeBrand) => option.originalName}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={isCarManufacturer ? 'Марка' : 'Бренд'}
          variant="outlined"
        />
      )}
    />
  );
};
