import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import { request } from 'src/services/request';
import { typeProduct, typeProductAnalog, typeSupplier } from 'src/types';

export const addAnalogsFile = ({ file }: { file: File }) => {
  const analogsFileForm = new FormData();

  analogsFileForm.append(`file`, file);

  return request.post(`/analogs/file`, analogsFileForm);
};

export function useAddAnalog() {
  return useRequestWithPayload<
    typeProductAnalog,
    {
      product: typeProduct;
      analog: typeProduct;
      source: string;
      reversed: boolean;
    }
  >({
    url: '/analogs',
    method: 'post',
  });
}
