import { useState, useEffect } from 'react';
import { request } from 'src/services/request';
import { Canceler } from 'axios';

export function useRequest<T, Q = object | undefined, D = object | undefined>({
  url,
  method,
  query,
  skip,
}: {
  url: string;
  method: keyof Pick<typeof request, 'get' | 'delete'>;
  query?: Q;
  skip?: boolean;
}): {
  loading: boolean;
  error: any;
  data: T | null;
  refetch: (query?: Q, data?: D) => any;
  cancel: () => any;
} {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<T | null>(null);

  const [error, setError] = useState(null);

  const [canceler, setCanceler] = useState<Canceler | null>(null);

  const cancel = () => {
    if (loading && canceler) {
      canceler(`${url} [${method.toUpperCase()}] canceled`);
    }
  };

  const refetch = async (requestQuery?: Q) => {
    if (!loading) {
      setLoading(true);

      const res = await request[method](url, {
        params: requestQuery,
        setCanceler: (method: any) => setCanceler(() => method),
      })
        .then(res => res?.data)
        .catch((error: any) => {
          console.error(error);

          setError(error);

          return data;
        });

      setData(res);
    }

    setLoading(false);
    setCanceler(null);
  };

  const variables = query ? Object.values(query) : [];
  useEffect(() => {
    if (!skip) {
      refetch(query);
    }
  }, [url, skip, ...variables]);

  return {
    loading,
    error,
    data,
    refetch,
    cancel,
  };
}
