import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Paper,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableBody,
  IconButton,
  Toolbar,
  Typography,
  TablePagination,
} from '@material-ui/core';
import { Refresh, Close, CheckCircle } from '@material-ui/icons';
import { typeBrand, typeListObject } from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';

type typeProps = {
  brands: typeListObject<typeBrand> | null;
  onFetch?: (params: { limit: number; offset: number }) => any;
  onCancel: any;
  loading: boolean;
};

type typeState = {
  rowsPerPage: number;
  page: number;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      title: {
        flex: '1 1 100%',
      },
    }),
  { name: 'Brands' },
);

export const Brands: React.FunctionComponent<typeProps> = ({
  brands,
  loading,
  onFetch,
  onCancel,
}) => {
  const classes = useStyles({});

  const [{ rowsPerPage, page }, changeState] = useState<typeState>({
    rowsPerPage: 5,
    page: 0,
  });

  const limit = rowsPerPage;
  const offset = page * rowsPerPage;

  const setState = (data: Partial<typeState>) =>
    changeState(state => ({ ...state, ...data }));

  const handleOnFetch = () => onFetch && onFetch({ limit, offset });

  useEffect(() => {
    handleOnFetch();
  }, [limit, offset]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography className={classes.title} variant="h6" component="div">
          Бренды
        </Typography>
        {onFetch && (
          <IconButton onClick={loading ? onCancel : handleOnFetch}>
            {loading ? <Close /> : <Refresh />}
          </IconButton>
        )}
      </Toolbar>

      <WithLoading loading={loading}>
        <Table aria-label="brands table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>Имя</TableCell>
              <TableCell>Автопроизводитель</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brands?.data?.map((brand: typeBrand) => (
              <TableRow key={brand.id}>
                <TableCell component="th" scope="row">
                  {brand.id}
                </TableCell>
                <TableCell>{brand.originalName}</TableCell>
                <TableCell>
                  {brand.isCarManufacturer && <CheckCircle color="disabled" />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 25, 50]}
          component="div"
          count={brands?.totalCount || 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(e, value) => setState({ page: value })}
          onChangeRowsPerPage={e =>
            setState({ rowsPerPage: parseInt(e.target.value, 10) })
          }
        />
      </WithLoading>
    </Paper>
  );
};
