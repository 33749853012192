import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box } from '@material-ui/core';
import { useGetModel } from 'src/api/modelApi';
import { useGetBodies } from 'src/api/bodyApi';
import { useGetBrand } from 'src/api/brandApi';
import { Bodies } from 'src/components/bodies';
import { useRouter } from 'src/hooks/useRouter';
import { typeBrand, typeModel } from 'src/types';
import { BrandSelect } from 'src/components/brand-select';
import { ModelSelect } from 'src/components/model-select';
import { BodyCreate } from 'src/components/body-create';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'BodiesPage' },
);

export const BodiesPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { query, push } = useRouter();

  const { data: brand } = useGetBrand({
    variables: {
      brandId: query.brandId,
    },
    skip: !query.brandId,
  });

  const { data: model } = useGetModel({
    variables: {
      modelId: query.modelId,
    },
    skip: !query.modelId,
  });

  const variables = {
    limit: parseInt(query.limit, 10) || 5,
    offset: parseInt(query.offset, 10) || 0,
    modelId: parseInt(query.modelId, 10) || null,
  };

  const { data, loading, refetch, cancel } = useGetBodies({
    variables,
  });

  const handleOnFetch = (data?: { limit: number; offset: number }) => {
    if (data) {
      return push(null, { ...query, ...data });
    }
    refetch({ ...variables, ...query });
  };

  return (
    <div className={classes.root}>
      <BrandSelect
        isCarManufacturer
        brand={brand}
        onChange={(value: typeBrand | null) => {
          const brandId = value?.id;
          push(null, { brandId });
        }}
      />
      <Box mt={2}>
        <ModelSelect
          brand={brand}
          model={model}
          onChange={(value: typeModel | null) => {
            const modelId = value?.id;
            const brandId = value?.brand?.id;
            push(null, { modelId, brandId });
          }}
        />
      </Box>
      <Box mt={2}>
        <Bodies
          bodies={data}
          onFetch={handleOnFetch}
          onCancel={cancel}
          loading={loading}
          limit={variables.limit}
          offset={variables.offset}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <BodyCreate model={model} onCreate={() => refetch(variables)} />
      </Box>
    </div>
  );
};
