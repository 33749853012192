import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  InputAdornment,
  TextField,
  Theme,
} from '@material-ui/core';
import { typeCustomer } from 'src/types';
import NumberFormat from 'react-number-format';
import { useChangeCustomer } from 'src/api/customerApi';
import { Edit } from '@material-ui/icons';

interface typeProps {
  customer?: typeCustomer;
  onSave?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'CustomerEdit' },
);

const initialState = {};

export const CustomerEdit: React.FunctionComponent<typeProps> = ({
  customer,
  onSave,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);
  const [state, changeState] = useState<typeCustomer>(initialState);

  const setState = (data: Partial<typeCustomer>) =>
    changeState({ ...state, ...data });

  const [change, changeRequest] = useChangeCustomer();

  useEffect(() => {
    if (customer) setState(customer);
  }, [customer]);

  const handelSave = () =>
    change({ ...customer, ...state }).then(() => {
      if (onSave) onSave();
      setDialog(false);
    });

  return (
    <>
      <Fab
        title="Редактировать данные клиента"
        color="primary"
        size="small"
        onClick={() => setDialog(true)}
      >
        <Edit />
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
              <NumberFormat
                disabled
                customInput={TextField}
                format="(###) ### ## ##"
                mask="_"
                variant="outlined"
                label="Телефон"
                InputLabelProps={{ shrink: true }}
                id="phone"
                fullWidth
                placeholder="(___) __ __ __"
                value={
                  state?.phone?.value
                    ? state.phone.value
                    : customer?.phone?.value || ''
                }
                onChange={e => setState({ phone: { value: e.target.value } })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+7</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Фамилия"
                variant="outlined"
                fullWidth
                value={
                  state?.secondName
                    ? state.secondName
                    : customer?.secondName || ''
                }
                onChange={e => setState({ secondName: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Имя"
                variant="outlined"
                fullWidth
                value={
                  state?.firstName ? state.firstName : customer?.firstName || ''
                }
                onChange={e => setState({ firstName: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Отчество"
                variant="outlined"
                fullWidth
                value={
                  state?.middleName
                    ? state.middleName
                    : customer?.middleName || ''
                }
                onChange={e => setState({ middleName: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              Количество заказов клиента: {customer?.ordersCount || 0}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Закрыть</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handelSave}
            disabled={changeRequest.loading}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
