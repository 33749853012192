import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { IconButton, Theme } from '@material-ui/core';
import { WithConfirm } from './WithConfirm';
import { Close } from '@material-ui/icons';
import { deleteProductType } from 'src/api/productTypeApi';
import { typeProductType } from 'src/types';

interface typeProps {
  type: typeProductType | null;
  onDelete?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductTypeDelete' },
);

export const ProductTypeDelete: React.FunctionComponent<typeProps> = ({
  type,
  onDelete,
}) => {
  const classes = useStyles({});
  const handleDelete = () => {
    if (type && type?.id) {
      deleteProductType({ typeId: type?.id }).then(() => {
        if (onDelete) onDelete();
      });
    }
  };
  return (
    <div className={classes.root}>
      <WithConfirm onConfirm={handleDelete} confirmTitle="Удалить">
        <IconButton title="Удалить">
          <Close />
        </IconButton>
      </WithConfirm>
    </div>
  );
};
