import React, { SyntheticEvent, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuickSearch } from 'src/api/searchApi';
import { useDebounce } from 'src/hooks/useDebounce';
import { ProductListItem } from './ProductListItem';
import { typeProduct, typeProductComponent, typeStockItem } from 'src/types';
import { useRouter } from 'src/hooks/useRouter';
import { StockItemListItem } from './StockItemListItem';

interface typeProps {
  onlyElements?: string[];
  outOfStock?: boolean;
  onClick?: (item: any) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      textField: {
        backgroundColor: '#42424273',
      },
    }),
  { name: 'QuickSearch' },
);

export const QuickSearch: React.FunctionComponent<typeProps> = ({
  onlyElements = ['stockItem', 'products', 'components', 'analogs'],
  outOfStock = true,
  onClick,
}) => {
  const classes = useStyles({});

  const [query, setQuery] = useState('');
  const [open, setOpen] = useState<boolean | undefined>(undefined);
  const [requestQuery, setRequestQuery] = useState('');
  const router = useRouter();

  const { data, loading } = useQuickSearch({
    variables: {
      query: requestQuery,
    },
    skip: !requestQuery,
  });

  const list = [] as any[];

  const handleOnClick = (item: any) => {
    if (onClick) {
      onClick(item);
      return item;
    }
    return null;
  };

  const handleOnChange = (e: any) => {
    setOpen(undefined);
    setQuery(e.target.value.replace(/[^a-z0-9]/gi, ''));
  };

  const handleonKeyDown = (e: any) => {
    setOpen(undefined);
    if (e.key === 'Enter') {
      return setRequestQuery(query);
    }
  };

  if (data) {
    Object.keys(data).forEach(key => {
      const element = data[key];

      // if (key === 'product' && element && onlyElements.includes('product')) {
      //   list.push({
      //     data: element,
      //     type: 'ID Продукта',
      //     render: (item: typeProduct) => (
      //       <div>
      //         <ProductListItem product={item} />
      //       </div>
      //     ),
      //     onClick: (item: typeProduct) =>
      //       handleOnClick(item) || router.push(`/products/${item.id}`),
      //   });
      // }
      if (
        key === 'stockItem' &&
        element &&
        onlyElements.includes('stockItem') &&
        (outOfStock || element.quantity > 0)
      ) {
        list.push({
          data: element,
          type: 'ID товара',
          render: (item: typeStockItem) => (
            <div>
              <StockItemListItem stockItem={item} />
            </div>
          ),
          onClick: (item: typeStockItem) =>
            handleOnClick(item) || router.push(`/stock-items/${item.id}`),
        });
      }
      if (key === 'products' && element && onlyElements.includes('products')) {
        element?.forEach((item: typeProduct) =>
          item.stockItems.forEach(
            stockItem =>
              (outOfStock || stockItem.quantity > 0) &&
              list.push({
                data: stockItem,
                type: 'Артикул продукта',
                render: (item: typeStockItem) => (
                  <div>
                    <StockItemListItem stockItem={item} />
                  </div>
                ),
                onClick: (item: typeStockItem) =>
                  handleOnClick(item) || router.push(`/stock-items/${item.id}`),
              }),
          ),
        );
      }
      if (
        key === 'components' &&
        element &&
        onlyElements.includes('components')
      ) {
        element?.forEach((item: any) =>
          list.push({
            data: item,
            type: 'Компонент для',
            render: (item: typeProductComponent) => (
              <div>
                <ProductListItem product={item.product} />
              </div>
            ),
            onClick: (item: typeProductComponent) =>
              handleOnClick(item) || router.push(`/products/${item.id}`),
          }),
        );
      }
      if (key === 'analogs' && element && onlyElements.includes('analogs')) {
        element?.forEach((item: any) =>
          list.push({
            data: item,
            type: 'Аналоги',
            render: (item: any) => (
              <div>
                <ProductListItem product={item} />
              </div>
            ),
            onClick: (item: any) =>
              handleOnClick(item) || router.push(`/stock-items/${item.id}`),
          }),
        );
      }
    });
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        fullWidth
        disableClearable
        options={loading ? [] : list}
        loading={loading}
        onClose={() => setOpen(false)}
        inputValue={query}
        loadingText="Загрузка..."
        noOptionsText="Ничего не нашлось"
        getOptionLabel={option => option?.id?.toString()}
        renderOption={option => option.render(option.data)}
        filterOptions={options => options}
        groupBy={option => option.type}
        onKeyPress={handleonKeyDown}
        onChange={(e, value) =>
          value ? value.onClick(value.data) : () => null
        }
        renderInput={params => (
          <TextField
            {...params}
            className={classes.textField}
            variant="outlined"
            value={query}
            onChange={handleOnChange}
          />
        )}
      />
    </div>
  );
};
