import React, { SyntheticEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Link, Theme } from '@material-ui/core';
import { typeStockItem } from 'src/types';

interface typeProps {
  stockItem: typeStockItem;
  withPrice?: boolean;
  withId?: boolean;
  withVehicle?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      name: {
        fontSize: theme.typography.pxToRem(12),
      },
    }),
  { name: 'ProductListItem' },
);

export const StockItemListItem: React.FunctionComponent<typeProps> = ({
  stockItem,
  withPrice = true,
  withVehicle,
  withId = true,
}) => {
  const classes = useStyles({});

  const product = stockItem?.product;

  return (
    <div>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Box mr={1}>
          <Link
            target="_blank"
            href={`/stock-items/${stockItem.id}`}
            onClick={(e: SyntheticEvent) => e.stopPropagation()}
          >
            {stockItem?.id}
          </Link>
        </Box>

        <Box mr={1}>
          {product?.mainComponent?.component.brand.originalName ||
            product?.brand?.originalName}
        </Box>
        {product && (
          <>
            <Box mr={1}>
              <Link
                target="_blank"
                href={`/products/${product.id}`}
                onClick={(e: SyntheticEvent) => e.stopPropagation()}
              >
                {product?.mainComponent?.component?.article || product?.article}
              </Link>
            </Box>
            <Box mr={1}>
              <Link
                onClick={(e: SyntheticEvent) => e.stopPropagation()}
                target="_blank"
                href={`https://www.google.com/search?tbm=isch&q=${
                  product?.mainComponent?.component?.article || product?.article
                }`}
              >
                <b>G</b>
              </Link>
            </Box>
          </>
        )}
        <div className={classes.name}>{stockItem?.itemNameWithPosition}</div>
      </Box>
      {withVehicle && (
        <Link
          className={classes.name}
          href={`/vehicles/${stockItem?.vehicle?.id}`}
          target="_blank"
        >
          {stockItem?.vehicle?.name}
        </Link>
      )}
      <div className={classes.name}>{stockItem?.warehouse?.name}</div>
      {withPrice && (
        <div className={classes.name}>
          {stockItem?.offerPrice && stockItem?.offerPrice / 100} ₽
        </div>
      )}
    </div>
  );
};
