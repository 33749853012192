import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import {
  typeProductTarget,
  typeProductTypeAlias,
  typeProductTypeCategory,
} from 'src/types';
import { request } from 'src/services/request';
import { ProductType } from 'src/shared/api';

export function useGetProductTypes() {
  return useRequest<ProductType[]>({
    url: '/product-types',
    method: 'get',
  });
}

export function useGetProductAliases() {
  return useRequest<ProductType[]>({
    url: '/product-types/aliases',
    method: 'get',
  });
}

export function useGetProductType({
  variables,
  skip,
}: {
  variables: { typeId?: number | string };
  skip?: boolean;
}) {
  return useRequest<ProductType>({
    url: `/product-types/${variables.typeId}`,
    method: 'get',
  });
}

export function useGetProductTargets() {
  return useRequest<ProductType[]>({
    url: '/product-types/targets',
    method: 'get',
  });
}

export function useGetProductTypesCategories({
  variables,
  skip,
}: {
  variables: { targetId?: number | string };
  skip?: boolean;
}) {
  return useRequest<ProductType[]>({
    url: `/product-types/categories`,
    method: 'get',
    query: variables,
    skip,
  });
}

export function useCreateProductTargetType({
  variables,
  skip,
}: {
  variables: { typeId?: number };
  skip?: boolean;
}) {
  return useRequestWithPayload<
  ProductType,
    { category: typeProductTypeCategory; target: typeProductTarget }
  >({
    url: `/product-types/${variables.typeId}/targetsTypes`,
    method: 'post',
  });
}

export function useProductType() {
  return useRequestWithPayload<ProductType, ProductType>({
    url: `/product-types`,
    method: 'post',
  });
}

export function useAddProductTypeAlias({
  variables,
  skip,
}: {
  variables: { typeId?: number };
  skip?: boolean;
}) {
  return useRequestWithPayload<
  ProductType,
    { alias: typeProductTypeAlias | null; newAliasName: string | null }
  >({
    url: `/product-types/${variables.typeId}/aliases`,
    method: 'put',
  });
}

export function deleteProductType({ typeId }: { typeId: string | number }) {
  return request.delete(`/product-types/${typeId}`);
}

export function deleteProductTypeAlias({ aliasId }: { aliasId: string | number }) {
  return request.delete(`/product-types/aliases/${aliasId}`);
}

export function deleteЕargetType(typeId: number | string, targetTypeId: number | string) {
  return request.delete(`/product-types/${typeId}/targets-types/${targetTypeId}`)
}
