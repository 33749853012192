import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';
import { useGetJournalTypes } from 'src/api/billingApi';
import { typeBillingJournalType } from 'src/types';

type typeProps = {
  currency?: string | null;
  disabled?: boolean;
  type?: typeBillingJournalType | null;
  onChange?: (type: typeBillingJournalType) => any;
  filter?: (item: typeBillingJournalType) => boolean;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'BillingJournalTypeSelect' },
);

export const BillingJournalTypeSelect: React.FunctionComponent<typeProps> = ({
  currency,
  onChange,
  filter: providedFilter,
  disabled,
  type,
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState(null);

  const { data, loading } = useGetJournalTypes();

  const filter = (item: typeBillingJournalType) => {
    if (providedFilter) return providedFilter(item);
    return item;
  };

  useEffect(() => {
    if (data && type) {
      setValue(
        data.find((item: typeBillingJournalType) => item.id === type.id) ||
          null,
      );
    }
  }, [data, type]);

  useEffect(() => {
    if (data && !value) {
      const defaultValue = data
        ?.filter(filter)
        ?.find((item: typeBillingJournalType) => item.id === 1);
      if (defaultValue) hangdeOnChange(defaultValue);
    }
  }, [data]);

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <Box className={classes.root} display="flex">
      <Autocomplete
        disabled={disabled}
        fullWidth
        value={value}
        onChange={(e, value: typeBillingJournalType) => hangdeOnChange(value)}
        options={data?.filter(filter) || []}
        getOptionLabel={(option: typeBillingJournalType) => `${option.name}`}
        label="Тип операции"
        loading={loading}
      />
    </Box>
  );
};
