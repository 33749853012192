import React, { SyntheticEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link, Theme } from '@material-ui/core';
import { typeProduct } from 'src/types';

interface typeProps {
  product: typeProduct;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      name: {
        fontSize: theme.typography.pxToRem(12),
      },
    }),
  { name: 'ProductListItem' },
);

export const ProductListItem: React.FunctionComponent<typeProps> = ({
  product,
}) => {
  const classes = useStyles({});

  return (
    <div>
      <div>
        {product?.mainComponent?.component?.brand?.originalName ||
          product?.brand?.originalName}{' '}
        {product && (
          <span>
            <Link
              target="_blank"
              href={`/products/${product.id}`}
              onClick={(e: SyntheticEvent) => e.stopPropagation()}
            >
              {product?.mainComponent?.component?.article || product?.article}
            </Link>{' '}
            <Link
              target="_blank"
              href={`https://www.google.com/search?tbm=isch&q=${
                product?.mainComponent?.component?.article || product?.article
              }`}
              onClick={(e: SyntheticEvent) => e.stopPropagation()}
            >
              <b>G</b>
            </Link>
          </span>
        )}
      </div>
      <div className={classes.name}>{product?.nameValue}</div>
    </div>
  );
};
