import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  InputAdornment,
  TextField,
  Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { WithLoading } from 'src/components/common/with-loading';
import {
  typeCustomer,
  typeDelivery,
  typeDeliveryCompany,
  typeOrder,
} from 'src/types';
import { useChangeDelivery, useCreateDelivery } from 'src/api/deliveryApi';
import { CustomerSelect } from './CustomerSelect';
import { Customer } from './Customer';
import { DeliveryCompanySelect } from './DeliveryCompanySelect';
import NumberFormat from 'react-number-format';

interface typeProps {
  customer?: typeCustomer | null;
  delivery?: typeDelivery | null;
  order?: typeOrder | null;
  open?: boolean;
  withButton?: boolean;
  disabled?: boolean;
  onClose?: () => any;
  onCreate?: (delivery: typeDelivery) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'DeliveryCreate' },
);

const initialDelivery = {
  phone: null,
  secondName: null,
  firstName: null,
  middleName: null,
  address: null,
  passportCode: null,
  cost: parseInt(process.env.REACT_APP_DELIVERY_COST),
  isPackage: false,
  packageCost: 0,
  insurance: 0,
  comment: null,
};

type typeDeliveryState = {
  id?: number;
  phone: string | number | null;
  secondName: string | null;
  firstName: string | null;
  middleName: string | null;
  address: string | null;
  passportCode: string | null;
  cost: number;
  isPackage: boolean;
  packageCost: number;
  insurance: number;
  comment: string | null;
};

export const DeliveryCreate: React.FunctionComponent<typeProps> = ({
  order,
  customer,
  delivery,
  disabled = false,
  open,
  onClose,
  onCreate,
  withButton = true,
}) => {
  const classes = useStyles({});
  const [dialogOpen, setDialog] = useState(false);

  const dialog = typeof open !== 'undefined' && !disabled ? open : dialogOpen;

  const closeDialog = onClose || (() => setDialog(false));

  const [deliveryCompany, setDeliveryCompany] =
    useState<typeDeliveryCompany | null>(null);

  const [nextDelivery, setDelivery] =
    useState<typeDeliveryState>(initialDelivery);

  const [create, createRequest] = useCreateDelivery();
  const [change, changeRequest] = useChangeDelivery();

  const changeDelivery = (data: Partial<typeDeliveryState>) =>
    setDelivery({ ...nextDelivery, ...data });

  useEffect(() => {
    let nextState = {};
    if (customer) {
      nextState = {
        ...nextState,
        phone: customer?.phone?.value,
        secondName: customer?.secondName,
        firstName: customer?.firstName,
        middleName: customer?.middleName,
      };
    }
    if (delivery) {
      nextState = {
        ...nextState,
        id: delivery?.id,
        phone: delivery?.phone,
        secondName: delivery?.secondName,
        firstName: delivery?.firstName,
        middleName: delivery?.middleName,
        address: delivery?.address,
        passportCode: delivery?.passportCode,
        cost: delivery?.cost,
        isPackage: delivery?.isPackage,
        packageCost: delivery?.packageCost,
        comment: delivery?.comment,
      };
    }
    if (order) {
      nextState = {
        ...nextState,
        insurance: order?.totalCost,
      };
    }
    if (delivery?.deliveryCompany) {
      setDeliveryCompany(delivery?.deliveryCompany);
    }
    changeDelivery(nextState);
  }, [customer, delivery]);

  const handleOnClick = () => {
    delivery
      ? change({ ...nextDelivery, customer, deliveryCompany }).then(
          (delivery: typeDelivery) => {
            closeDialog();
            if (onCreate) onCreate(delivery);
          },
        )
      : create({ ...nextDelivery, customer, deliveryCompany }).then(
          (delivery: typeDelivery) => {
            closeDialog();
            if (onCreate) onCreate(delivery);
          },
        );
  };

  return (
    <div className={classes.root}>
      {withButton && (
        <Fab
          color="primary"
          size="small"
          onClick={() => setDialog(true)}
          disabled={disabled}
        >
          <Add />
        </Fab>
      )}
      <Dialog open={dialog} onClose={closeDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          <Box>
            <DeliveryCompanySelect
              deliveryCompany={deliveryCompany}
              onChange={setDeliveryCompany}
            />
          </Box>
          <Box mt={2}>
            <NumberFormat
              customInput={TextField}
              format="(###) ### ## ##"
              mask="_"
              variant="outlined"
              label="Телефон"
              InputLabelProps={{ shrink: true }}
              id="phone"
              fullWidth
              placeholder="(___) __ __ __"
              value={nextDelivery.phone || ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+7</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              value={nextDelivery.secondName || ''}
              onChange={e => changeDelivery({ secondName: e.target.value })}
              variant="outlined"
              label="Фамилия"
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              value={nextDelivery.firstName || ''}
              onChange={e => changeDelivery({ firstName: e.target.value })}
              variant="outlined"
              label="Имя"
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              value={nextDelivery.middleName || ''}
              onChange={e => changeDelivery({ middleName: e.target.value })}
              variant="outlined"
              label="Отчество"
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              value={nextDelivery.passportCode || ''}
              onChange={e => changeDelivery({ passportCode: e.target.value })}
              variant="outlined"
              label="Номер паспорта"
            />
          </Box>
          <Box mt={4}>
            <TextField
              fullWidth
              value={nextDelivery.address || ''}
              onChange={e => changeDelivery({ address: e.target.value })}
              variant="outlined"
              label="Адрес"
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              value={nextDelivery.cost / 100}
              onChange={e =>
                changeDelivery({ cost: parseInt(e.target.value) * 100 })
              }
              variant="outlined"
              label="Цена доставки, ₽"
            />
          </Box>
          <Box mt={2}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Checkbox
                      checked={nextDelivery.isPackage}
                      onClick={() =>
                        changeDelivery({ isPackage: !nextDelivery.isPackage })
                      }
                    />
                  </InputAdornment>
                ),
              }}
              fullWidth
              disabled={!nextDelivery.isPackage}
              value={nextDelivery.packageCost / 100}
              onChange={e =>
                changeDelivery({ packageCost: parseInt(e.target.value) * 100 })
              }
              variant="outlined"
              label="Дополнительная упаковка, ₽"
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              value={nextDelivery.insurance / 100}
              onChange={e =>
                changeDelivery({ insurance: parseInt(e.target.value) * 100 })
              }
              variant="outlined"
              label="Страховка"
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              value={nextDelivery.comment}
              multiline
              onChange={e => changeDelivery({ comment: e.target.value })}
              variant="outlined"
              label="Комментарий"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Закрыть</Button>
          <WithLoading loading={createRequest.loading}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOnClick()}
              disabled={!customer || !deliveryCompany || !nextDelivery.address}
            >
              {delivery ? 'Сохранить' : 'Создать'}
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
};
