import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Theme,
} from '@material-ui/core';
import { typeSupplier } from 'src/types';
import { useCalculateMarkup } from 'src/api/suppliersApi';
import { useDebounce } from 'src/hooks/useDebounce';
import { Check } from '@material-ui/icons';

interface Props {
  supplier?: typeSupplier | null;
  updateTrigger?: any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'MarkupCalculate' },
);

export const MarkupCalculate: React.FunctionComponent<Props> = ({
  supplier,
  updateTrigger,
}) => {
  const classes = useStyles({});

  const [startPrice, setStartPrice] = useState(0);

  const [calculate, { data, loading }] = useCalculateMarkup();

  const debouncedStartPrice = useDebounce(startPrice, 500);

  useEffect(() => {
    if (supplier) {
      calculate({ startPrice: startPrice * 100, supplier });
    }
  }, [debouncedStartPrice, updateTrigger]);

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        Калькулятор наценки
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          fullWidth
          variant="outlined"
          value={startPrice}
          label="Закупочная, ₽"
          type="number"
          onChange={e => setStartPrice(parseInt(e.target.value, 10))}
          InputProps={{
            endAdornment: loading && <CircularProgress />,
          }}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          color="primary"
          fullWidth
          variant="outlined"
          value={(data?.offerPrice && data?.offerPrice / 100) || 0}
          label="Продажная, ₽"
          disabled
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          color="primary"
          fullWidth
          variant="outlined"
          value={
            data?.offerPrice &&
            startPrice &&
            data?.startPrice / 100 === startPrice
              ? Math.ceil(
                  ((data?.offerPrice / 100 - startPrice) / startPrice) * 100,
                )
              : 0
          }
          label="Наценка, %"
          disabled
          type="number"
        />
      </Grid>
    </Grid>
  );
};
