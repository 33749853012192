import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Theme,
} from '@material-ui/core';
import { typeOrderToSupplierElement } from 'src/types';
import { useChangeOrderToSupplierElement } from 'src/api/ordersApi';

interface typeProps {
  item: typeOrderToSupplierElement;
  onSave: () => any;
  disabled?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'OrderToSupplierElementDialog' },
);

export const OrderToSupplierElementDialog: React.FunctionComponent<typeProps> = ({
  item,
  children,
  onSave,
  disabled = false,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);
  const [element, setElement] = useState<Partial<typeOrderToSupplierElement>>({
    providedQuantity: 0,
  });

  useEffect(() => {
    if (item) {
      setElement({
        ...element,
        providedQuantity: item.providedQuantity,
      });
    }
  }, [item?.providedQuantity]);

  const [change] = useChangeOrderToSupplierElement({
    variables: { id: item?.id },
  });

  const handleChange = () => {
    change({
      ...element,
      providedQuantity: element.providedQuantity,
    }).then(() => {
      setDialog(false);
      onSave();
    });
  };

  return (
    <div className={classes.root}>
      <div onClick={() => !disabled && setDialog(true)}>{children}</div>
      <Dialog open={dialog} onClose={() => setDialog(!dialog)}>
        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            value={element.providedQuantity}
            label="Поставленное количество"
            onChange={e =>
              setElement({
                ...element,
                providedQuantity: parseInt(e.target.value, 10),
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Закрыть</Button>
          <Button color="primary" onClick={handleChange}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
