import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useGetProduct } from 'src/api/productApi';
import { ProductComponentAdd } from 'src/components/product-component-add';
import { ProductComponents } from 'src/components/product-components';
import { StockItems } from 'src/components/stock-items';
import { StockItemCreate } from 'src/components/stock-item-create';
import { Product } from 'src/components/product';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductPage' },
);

export const ProductPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const { productId } = useParams<{ productId: string }>();

  const { data, loading, refetch, cancel } = useGetProduct({
    variables: { productId },
  });

  return (
    <div className={classes.root}>
      <Box mt={2}>
        <Product
          product={data}
          onChange={() => refetch({ productId })}
          loading={Boolean(loading)}
        />
      </Box>
      <Box mt={4}>
        <StockItems
          onCancel={() => cancel()}
          onFetch={() => refetch({ productId })}
          loading={loading}
          stockItems={{
            data:
              data?.stockItems?.map(item => ({ ...item, product: data })) || [],
            totalCount: data?.stockItems?.length || 0,
          }}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <StockItemCreate
            product={data}
            onChange={() => refetch({ productId })}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <ProductComponents
          product={data}
          components={data?.components}
          loading={loading}
          onCancel={() => cancel()}
          onFetch={() => refetch({ productId })}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <ProductComponentAdd
          product={data}
          onChange={() => refetch({ productId })}
        />
      </Box>
    </div>
  );
};
