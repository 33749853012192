import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { IconButton, Theme } from '@material-ui/core';
import { typeProductTypeAlias } from 'src/types';
import { deleteProductTypeAlias } from 'src/api/productTypeApi';
import { WithConfirm } from './WithConfirm';
import { Close } from '@material-ui/icons';

interface typeProps {
  alias?: typeProductTypeAlias;
  onDelete?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductTypeAliasDelete' },
);

export const ProductTypeAliasDelete: React.FunctionComponent<typeProps> = ({alias, onDelete}) => {
  const classes = useStyles({});

  const handleDelete = () => {
    if (alias && alias?.id) {
      deleteProductTypeAlias({ aliasId: alias?.id }).then(() => {
        if (onDelete) onDelete();
      });
    }
  };
  return (
    <div className={classes.root}>
      <WithConfirm onConfirm={handleDelete} confirmTitle="Удалить">
        <IconButton title="Удалить">
          <Close />
        </IconButton>
      </WithConfirm>
    </div>
  );
};
