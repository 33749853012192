import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box, Paper, Link, IconButton } from '@material-ui/core';
import { Table } from 'src/components/common/table';
import { typePriceExportScenario } from 'src/types';
import { useChangeScenario, useGetScenarios } from 'src/api/scenariosApi';
import { CheckCircle, Refresh } from '@material-ui/icons';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ScenariosPage' },
);

export const ScenariosPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { data, loading, refetch, cancel } = useGetScenarios({});
  const [change, { loading: updateLoading }] = useChangeScenario();

  const handleChange = (scenario: typePriceExportScenario) =>
    change({ id: scenario.id, needUpdate: true }).then(() => refetch());

  return (
    <div className={classes.root}>
      <Box mt={2}>
        <Paper>
          <Table
            withSummary
            title="Сценарии экспорта"
            data={data}
            totalCount={data?.length}
            headers={[
              {
                title: 'id',
                extract: (item: typePriceExportScenario) => item?.id,

                component: 'th',
                scope: 'row',
              },
              {
                title: 'Название',
                extract: (item: typePriceExportScenario) => item?.name,
              },
              {
                title: 'Обновлен',
                extract: (item: typePriceExportScenario) => (
                  <span title={item?.updated}>
                    {formatDistance(new Date(item?.updated), new Date(), {
                      addSuffix: true,
                      locale: ru,
                      includeSeconds: true,
                    })}
                  </span>
                ),
              },
              {
                title: 'Имя файла',
                extract: (item: typePriceExportScenario) => (
                  <Link
                    target="_blank"
                    href={`https://rs125.ru/public/scenario_export/${
                      item?.filename
                    }.${item?.fileType}?${new Date(item.updated).getTime()}`}
                  >
                    {item?.filename}
                  </Link>
                ),
              },
              {
                title: 'Склады',
                extract: (item: typePriceExportScenario) =>
                  item?.warehouses.map(warehouse => (
                    <React.Fragment key={warehouse?.id}>
                      <Link
                        key={warehouse?.id}
                        target="_blank"
                        href={`/warehouses/${item?.id}`}
                      >
                        {warehouse?.name}
                      </Link>{' '}
                    </React.Fragment>
                  )),
              },
              {
                title: 'Расширение',
                extract: (item: typePriceExportScenario) => item?.fileType,
              },
              {
                title: 'Кодировка',
                extract: (item: typePriceExportScenario) => item?.encoding,
              },
              {
                title: 'Включен',
                extract: (item: typePriceExportScenario) =>
                  item.isEnable && <CheckCircle color="disabled" />,
              },
              {
                title: 'Обновить',
                extract: (item: typePriceExportScenario) => (
                  <IconButton
                    onClick={() => handleChange(item)}
                    disabled={updateLoading}
                  >
                    <Refresh />
                  </IconButton>
                ),
              },
            ]}
            loading={loading}
            onCancel={cancel}
            onFetch={refetch}
            limit={data?.length || 0}
            offset={0}
          />
        </Paper>
      </Box>
    </div>
  );
};
