import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Checkbox, Paper, Theme } from '@material-ui/core';
import { Table } from 'src/components/common/table';
import { useGetDeliveryCompanies } from 'src/api/deliveryApi';
import { DeliveryCompanyCreate } from 'src/components/DeliveryCompanyCreate';
import { typeDeliveryCompany } from 'src/types';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'DeliveryCompaniesPage' },
);

export const DeliveryCompaniesPage: React.FunctionComponent<typeProps> =
  ({}) => {
    const classes = useStyles({});

    const request = useGetDeliveryCompanies({});

    return (
      <Paper className={classes.root}>
        <Table
          withSummary
          title="Транспортные компании"
          data={request.data?.data || []}
          totalCount={request?.data?.totalCount}
          actions={[
            () => <DeliveryCompanyCreate onCreate={() => request.refetch()} />,
          ]}
          headers={[
            {
              title: 'id',
              extract: (item: typeDeliveryCompany) => item?.id,

              component: 'th',
              scope: 'row',
            },
            {
              title: 'Название',
              extract: (item: typeDeliveryCompany) => item?.name,
            },
          ]}
          loading={request.loading}
          limit={request?.data?.totalCount || 0}
          offset={0}
        />
      </Paper>
    );
  };
