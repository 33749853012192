import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import { typeEmployee, typeListObject } from 'src/types';

export function useGetEmployees(
  {
    variables,
    skip,
  }: {
    variables: { limit?: number; offset?: number };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeListObject<typeEmployee>, typeof variables>({
    url: '/employees',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useCreateEmployees() {
  return useRequestWithPayload<typeEmployee, typeEmployee>({
    url: '/employees',
    method: 'post',
  });
}

export function useGetEmployee(
  {
    variables,
    skip,
  }: {
    variables: {
      employeeId?: number | string;
    };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<{ data: typeEmployee }, typeof variables>({
    url: `/employees/${variables.employeeId}`,
    method: 'get',
    skip,
  });
}
