import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box, Paper } from '@material-ui/core';
import { useBillingJournalReasons } from 'src/api/billingApi';
import { Table } from '../common/table';
import { typeBillingAccount } from 'src/types';
import { BillingJournalReasonsCreate } from '../BillingJournalReasonsCreate';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'BillingJournalReasonsPage' },
);

export const BillingJournalReasonsPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { data, loading, refetch, cancel } = useBillingJournalReasons();

  return (
    <div className={classes.root}>
      <Paper>
        <Table
          title="Основания движения денег"
          data={data?.data || []}
          totalCount={data?.totalCount}
          headers={[
            {
              title: 'id',
              extract: (item: typeBillingAccount) => item.id,
              component: 'th',
              scope: 'row',
            },
            {
              title: 'Название',
              extract: (item: typeBillingAccount) => item.name,
            },
          ]}
          loading={loading}
          onCancel={cancel}
          withPagination={false}
          onFetch={refetch}
          limit={0}
          offset={0}
        />
      </Paper>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <BillingJournalReasonsCreate onCreate={() => refetch()} />
      </Box>
    </div>
  );
};
