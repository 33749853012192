import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box, Paper, Link } from '@material-ui/core';
import { useUploadPrice } from 'src/api/warehousesApi';
import { Table } from 'src/components/common/table';
import { typeSupplier, typeWarehouse } from 'src/types';
import { useGetSuppliers } from 'src/api/suppliersApi';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'SuppliersPage' },
);

export const SuppliersPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { data, loading, refetch, cancel } = useGetSuppliers();

  return (
    <div className={classes.root}>
      <Box mt={2}>
        <Paper>
          <Table
            withSummary
            title="Поставщики \ владельцы товара"
            data={data?.data}
            totalCount={data?.totalCount}
            headers={[
              {
                title: 'id',
                extract: (item: typeSupplier) => (
                  <Link target="_blank" href={`/suppliers/${item?.id}`}>
                    {item?.id}
                  </Link>
                ),
                component: 'th',
                scope: 'row',
              },
              {
                title: 'Название',
                extract: (item: typeSupplier) => item?.name,
              },
              {
                title: 'alias',
                extract: (item: typeSupplier) => item?.alias,
              },
              {
                title: 'Наценки',
                extract: (item: typeSupplier) => item?.markupRanges?.length,
              },
            ]}
            loading={loading}
            onCancel={cancel}
            onFetch={refetch}
            limit={data?.data?.length || 0}
            offset={0}
          />
        </Paper>
      </Box>
    </div>
  );
};
