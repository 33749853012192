import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField, Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { typeWarehousePlace, typeWarehouse } from 'src/types';
import { useGetWarehouses } from 'src/api/warehousesApi';

type typeProps = {
  onChange?: (place: typeWarehousePlace | null) => any;
  place?: typeWarehousePlace | null;
  warehouse?: typeWarehouse | null;
  disabled?: boolean;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'WarehouseSelect' },
);

export const WarehouseSelect: React.FunctionComponent<typeProps> = ({
  onChange,
  disabled,
  warehouse,
}) => {
  const classes = useStyles({});

  const [name, setName] = useState('');

  const [value, setValue] = useState<typeWarehousePlace | null>(null);

  const { data, refetch, loading } = useGetWarehouses({});

  useEffect(() => {
    const valueFromArray =
      data && warehouse
        ? (data?.data.find(item => item.id === warehouse.id) as typeWarehouse)
        : null;
    setValue(valueFromArray);
  }, [warehouse, data]);

  const handleOnChange = (next: typeWarehouse | null) => {
    setValue(next);
    if (onChange) {
      onChange(next);
    }
  };

  return (
    <Box display="flex">
      <Autocomplete
        fullWidth
        loading={loading}
        disabled={disabled}
        value={value}
        onChange={(e, value) => handleOnChange(value)}
        options={data?.data || []}
        getOptionLabel={(option: typeWarehousePlace) => option?.name || ''}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label="Склад"
            variant="outlined"
            onChange={e => setName(e.target.value)}
          />
        )}
      />
    </Box>
  );
};
