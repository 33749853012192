import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  ListItem,
  Divider,
  AppBar,
  IconButton,
  Theme,
  Toolbar,
  Drawer,
  List,
  Typography,
  ListItemText,
  ListItemIcon,
  Box,
  Collapse,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  Inbox,
  Mail,
  Menu,
  ChevronLeft,
  ExpandLess,
  ExpandMore,
  Settings,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { routes } from 'src/routes';
import { useRouter } from 'src/hooks/useRouter';
import { QuickSearch } from '../QuickSearch';

type typeProps = {};

const drawerWidth = 240;

const menuItems = routes.filter(item => item.title);

const filterVisibleMenuItems = [
  '/orders',
  '/deliveries',
  '/billing-journal',
  '/accounts',
  '/stock-items',
  '/warehouses',
  '/vehicles',
  '/diagnostics',
];

const visibleMenuItems = menuItems.filter(item =>
  filterVisibleMenuItems.includes(item.path),
);

const collapsedMenuItems = menuItems.filter(
  item => !filterVisibleMenuItems.includes(item.path),
);

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    }),
  { name: 'MainLayout' },
);

export const MainLayout: React.FunctionComponent<typeProps> = ({
  children,
}) => {
  const classes = useStyles({});

  const [open, setOpen] = React.useState(false);
  const [openCollapseMenu, setCollapseMenu] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { location } = useRouter();

  const handleToggleCollapsedMenu = () => setCollapseMenu(!openCollapseMenu);

  if (['/labels-print', '/deliveries-print'].includes(location.pathname)) {
    return <div>{children}</div>;
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <Menu />
          </IconButton>
          <Box flexGrow={1}>
            <QuickSearch />
          </Box>
          <Typography variant="h6" noWrap></Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List>
          {visibleMenuItems.map(route => (
            <React.Fragment key={route.path}>
              {[
                '/stock-items',
                '/brands',
                '/billing-journal',
                '/scenarios',
              ].includes(route.path) && <Divider />}
              <ListItem
                button
                component={Link}
                to={route.path}
                selected={location.pathname === route.path}
              >
                <ListItemText primary={route.title} />
              </ListItem>
            </React.Fragment>
          ))}
          <ListItem button onClick={handleToggleCollapsedMenu}>
            <ListItemText primary="Настройки" />
            {openCollapseMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCollapseMenu} timeout="auto" unmountOnExit>
            <List>
              {collapsedMenuItems.map(route => (
                <React.Fragment key={route.path}>
                  <ListItem
                    button
                    component={Link}
                    to={route.path}
                    selected={location.pathname === route.path}
                  >
                    <ListItemText primary={route.title} />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </Collapse>
        </List>
        <Divider />
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <Inbox /> : <Mail />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};
