import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, IconButton } from '@material-ui/core';
import { NoteAddOutlined } from '@material-ui/icons';
import { addAnalogsFile } from 'src/api/analogsApi';

interface ProductsAnalogsForm {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductsAnalogsForm' },
);

export const ProductsAnalogsForm: React.FunctionComponent<ProductsAnalogsForm> = () => {
  const classes = useStyles({});

  const setAttachedFile = (filesList: FileList | null) => {
    if (filesList && filesList[0]) {
      addAnalogsFile({ file: filesList[0] }).then(() => {
          // @ts-ignore
          document.getElementById('attachAnalogsFile').value = '';
        
      });
    }
  };
  
  return (
    <div className={classes.root}>
      <IconButton htmlFor="attachAnalogsFile" component="label">
        <NoteAddOutlined />
        <input
          type="file"
          id="attachAnalogsFile"
          multiple
          style={{ display: 'none' }}
          onChange={event => setAttachedFile(event.target.files)}
        />
      </IconButton>
    </div>
  );
};
