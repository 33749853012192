import { typeVehicle, typeListObject } from 'src/types';
import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';

export function useGetVehicles(
  {
    variables,
    skip,
  }: {
    variables: { limit?: number; offset?: number, customerId?: number | string | null; };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeListObject<typeVehicle>, typeof variables>({
    url: '/vehicles',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useGetVehicle(
  {
    variables,
    skip,
  }: {
    variables: { vehicleId?: number | string };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeVehicle, typeof variables>({
    url: `/vehicles/${variables.vehicleId}`,
    method: 'get',
    skip,
  });
}

export function useCreateVehicle() {
  return useRequestWithPayload<typeVehicle, typeVehicle>({
    url: '/vehicles',
    method: 'post',
  });
}

export function useChangeVehicle() {
  return useRequestWithPayload<typeVehicle, typeVehicle>({
    url: '/vehicles',
    method: 'patch',
  });
}
