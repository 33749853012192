import { request } from 'src/services/request';
import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';

export function useGetScenarios({
  variables,
  skip,
}: {
  variables?: {};
  skip?: boolean;
}) {
  return useRequest<any, typeof variables>({
    url: '/price-scenarios',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useChangeScenario() {
  return useRequestWithPayload<
    any,
    { id: string | number; needUpdate?: boolean }
  >({
    url: '/price-scenarios',
    method: 'post',
  });
}
