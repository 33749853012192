import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  TextField,
  Grid,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { WithLoading } from 'src/components/common/with-loading';
import { typeProduct } from 'src/types';
import { ProductSelect } from '../product-select';
import { useAddComponent } from 'src/api/productApi';

type typeProps = {
  product: typeProduct | null;
  onChange: () => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductComponentAdd' },
);

export const ProductComponentAdd: React.FunctionComponent<typeProps> = ({
  product,
  onChange,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);

  const [component, setComponent] = useState<typeProduct | null>(null);

  const [add, { data, loading }] = useAddComponent();

  const handleAdd = () => {
    if (product && component) {
      add({ product, component }).then(() => {
        setComponent(null);
        setDialog(false);
        onChange();
      });
    }
  };

  const brand =
    product?.brand?.originalName === 'Restan'
      ? product?.stockItems?.find(
          stockItem => stockItem?.vehicle?.body?.model?.brand,
        )?.vehicle?.body?.model?.brand
      : product?.brand;

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setDialog(true)}>
        <Add />
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Бренд товара"
                value={product?.brand?.originalName}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Артикул товара"
                value={product?.article}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              {/* <ManufacturerSelect /> */}
            </Grid>
            <Grid item xs={12}>
              <ProductSelect
                brand={brand}
                product={component}
                onChange={(value: typeProduct | null) => setComponent(value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleAdd}
              color="primary"
              variant="contained"
              disabled={!component || !product}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
