import { useRequest } from 'src/hooks/useRequest';
import { typeBody, typeListObject, typeModel } from 'src/types';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';

export function useGetBodies(
  {
    variables,
    skip,
  }: {
    variables: { modelId?: number | null; limit?: number; offset?: number };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeListObject<typeBody>, typeof variables>({
    url: '/bodies',
    method: 'get',
    query: variables,
    skip,
  });
}


export function useGetBody({
  variables,
  skip,
}: {
  variables: { bodyId?: number };
  skip: boolean;
}) {
  return useRequest<typeBody>({
    url: `/bodies/${variables.bodyId}`,
    method: 'get',
    skip,
  });
}

export function useCreateBody() {
  return useRequestWithPayload<typeBody, { name: string; model: typeModel }>({
    url: '/bodies',
    method: 'post',
  });
}
