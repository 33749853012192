import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Link,
  FormControlLabel,
  Checkbox,
  Fab,
} from '@material-ui/core';
import {
  typeProduct,
  typeStockItem,
  typeSupplier,
  typeVehicle,
  typeWarehouse,
  typeWarehousePlace,
} from 'src/types';
import { ImageForm } from 'src/components/image-form';
import { useAddImages } from 'src/api/imagesApi';
import { WarehousePlaceSelect } from 'src/components/warehouse-place-select';
import { useChangeStockItem, useGetStockItems } from 'src/api/stockItemApi';
import { WithLoading } from 'src/components/common/with-loading';
import { ProductSelect } from 'src/components/product-select';
import { Images } from 'src/components/images';
import { Product } from 'src/components/product';
import { StockItems } from 'src/components/stock-items';
import { SupplierSelect } from '../supplier-select';
import { VehicleSelect } from '../vehicle-select';
import { WarehouseSelect } from '../warehouse-select';
import { Orders } from 'src/components/Orders';
import { useGetOrders } from 'src/api/ordersApi';
import { StockItemCharacteristicsEdit } from 'src/features/characteristic-edit/ui/StockItemCharacteristicsEdit';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ru from 'date-fns/locale/ru';
import { format, startOfDay } from 'date-fns';
import { LocalMall } from '@material-ui/icons';

type typeProps = {
  stockItem?: typeStockItem | null;
  loading: boolean;
  onChange: () => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'StockItem' },
);

type typeState = {
  startPrice: number | null;
  fixedPrice: number | null;
  quantity: number | null;
  title: string | null;
  description: string | null;
  product: typeProduct | null;
  warehouse: typeWarehouse | null;
  warehousePlace: typeWarehousePlace | null;
  owner: typeSupplier | null;
  vehicle: typeVehicle | null;
  isUsed: boolean | null;
  releaseDate: string | null;
};

const includedAttributes = ['Глубина протектора', 'Износ'];

const initialState = {
  startPrice: null,
  fixedPrice: null,
  quantity: null,
  title: null,
  description: null,
  warehousePlace: null,
  warehouse: null,
  owner: null,
  vehicle: null,
  isUsed: null,
  releaseDate: null,
};

export const StockItem: React.FunctionComponent<typeProps> = props => {
  const classes = useStyles({});

  const ordesRequest = useGetOrders({
    variables: { stockItem: props?.stockItem?.id },
    skip: !props?.stockItem?.id,
  });

  const [isChangeProduct, setIsChangeProduct] = useState(false);

  const stockItemsRequestVariables = {
    productTypeId: props.stockItem?.product?.type?.id,
    bodyId: props.stockItem?.vehicle?.body?.id,
  };

  const stockItemsRequest = useGetStockItems({
    variables: stockItemsRequestVariables,
    skip:
      !props.stockItem?.product?.type?.id ||
      !props.stockItem?.vehicle?.body?.id,
  });

  const stockItemsRequestItems = stockItemsRequest?.data?.data?.filter(
    item => item.id !== props.stockItem?.id,
  );

  const stockItemsRequestData = {
    data: stockItemsRequestItems || [],
    totalCount: stockItemsRequestItems?.length || 0,
  };

  const [state, chageState] = useState<typeState>({
    startPrice: null,
    fixedPrice: null,
    quantity: null,
    title: null,
    description: null,
    warehousePlace: null,
    warehouse: null,
    product: null,
    owner: null,
    vehicle: null,
    isUsed: null,
    releaseDate: null,
  });

  const setState = (next: Partial<typeState>) =>
    chageState(prev => ({ ...prev, ...next }));

  const [addImages] = useAddImages();

  const [change, { loading }] = useChangeStockItem();

  const handleChangeItem = (changedPart: {
    warehousePlace?: typeWarehousePlace | null;
    startPrice?: string | number;
    offerPrice?: string | number;
    quantity?: string;
    title?: string;
    description?: string;
    product?: typeWarehousePlace | null;
    owner?: typeSupplier | null;
    vehicle?: typeVehicle | null;
    releaseDate?: string | null;
  }) => {
    if (props.stockItem) {
      change({ id: props.stockItem.id, ...changedPart }).then(() => {
        props.onChange();
        setState(initialState);
        setIsChangeProduct(false);
      });
    }
  };

  const getChangedProps = () => {
    return Object.entries(state)
      .filter(([key, value]) => value !== null)
      .reduce((prev, curr) => ({ ...prev, [curr[0]]: curr[1] }), {});
  };

  const handleAddImages = (files: File[]) => {
    const images = new FormData();

    if (!props.stockItem) return null;

    images.append('entityId', props.stockItem.id.toString());
    images.append('entityName', 'stockItem');

    files.forEach((file, index) => images.append(`image[${index}]`, file));

    return addImages(images).then((res: any) => {
      props.onChange();
      return res;
    });
  };

  // const handleDeleteImage = (image: typeImage) =>
  //   del(image).then(() => {
  //     props.onChange();
  //   });

  return (
    <div className={classes.root}>
      {/* <ProductSelect onChange={product => setState({ product })} /> */}
      <Box mt={2}>
        <Grid container spacing={4}>
          <Grid item sm={12} md={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Товар</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Название"
                  value={(state.title ?? props?.stockItem?.title) || ''}
                  onChange={e => setState({ title: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  label="Описание"
                  value={
                    (state.description ?? props?.stockItem?.description) || ''
                  }
                  onChange={e => setState({ description: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Закупочная, ₽"
                  value={
                    state.startPrice !== null
                      ? state.startPrice / 100
                      : (props?.stockItem?.startPrice &&
                          props?.stockItem?.startPrice / 100) ||
                        ''
                  }
                  onChange={e =>
                    setState({
                      startPrice: e.target.value
                        ? parseInt(e.target.value, 10) * 100
                        : 0,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Фиксированная, ₽"
                  value={
                    state.fixedPrice !== null
                      ? state.fixedPrice / 100
                      : (props?.stockItem?.fixedPrice &&
                          props?.stockItem?.fixedPrice / 100) ||
                        ''
                  }
                  onChange={e =>
                    setState({
                      fixedPrice: e.target.value
                        ? parseInt(e.target.value) * 100
                        : 0,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled
                  label="Продажная, ₽"
                  value={
                    props?.stockItem?.offerPrice &&
                    props?.stockItem?.offerPrice / 100
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled
                  label="Наценка, %"
                  value={
                    props?.stockItem?.startPrice && props?.stockItem?.offerPrice
                      ? Math.ceil(
                          (props?.stockItem?.offerPrice -
                            props?.stockItem?.startPrice) /
                            (props?.stockItem?.startPrice / 100),
                        )
                      : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={
                    state.quantity !== null
                      ? state.quantity
                      : props?.stockItem?.quantity || 0
                  }
                  label="Кол-во, шт."
                  onChange={e =>
                    setState({ quantity: parseInt(e.target.value) || 0 })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <VehicleSelect
                  onChange={vehicle => setState({ vehicle })}
                  vehicle={props?.stockItem?.vehicle}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  checked={Boolean(
                    state.isUsed !== null
                      ? state.isUsed
                      : props?.stockItem?.isUsed,
                  )}
                  onChange={() =>
                    setState({
                      isUsed:
                        state.isUsed !== null
                          ? !state.isUsed
                          : !props?.stockItem?.isUsed,
                    })
                  }
                  control={<Checkbox />}
                  label="Контрактный товар"
                />
              </Grid>
              <Grid item xs={12}>
                {props.stockItem && (
                  <Link
                    target="_blank"
                    href={`https://rs125.ru/stock-items/${props.stockItem.id}`}
                  >
                    В магазин
                  </Link>
                )}
              </Grid>
              <Grid item xs={12}>
                <WarehouseSelect
                  disabled
                  warehouse={props?.stockItem?.warehouse}
                  onChange={warehouse => setState({ warehouse })}
                />
              </Grid>
              <Grid item xs={12}>
                <WarehousePlaceSelect
                  place={props?.stockItem?.warehousePlace}
                  warehouse={props?.stockItem?.warehouse}
                  onChange={warehousePlace => setState({ warehousePlace })}
                />
              </Grid>
              <Grid item xs={12}>
                <SupplierSelect
                  supplier={props?.stockItem?.owner}
                  onChange={owner => setState({ owner })}
                />
              </Grid>{' '}
              <Grid item xs={12}>
                <Box>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                    <DatePicker
                      cancelLabel="Отмена"
                      okLabel={null}
                      autoOk
                      label="Дата выпуска"
                      format="yyyy-MM-dd"
                      fullWidth
                      inputVariant="outlined"
                      value={
                        state.releaseDate !== null
                          ? new Date(state.releaseDate)
                          : props?.stockItem?.releaseDate &&
                            new Date(props?.stockItem.releaseDate)
                      }
                      onChange={date =>
                        date &&
                        setState({
                          releaseDate: format(
                            startOfDay(date),
                            'yyyy-MM-dd HH:mm:ss',
                          ),
                        })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  {Boolean(
                    props?.stockItem?.product?.type?.attributes?.length,
                  ) && (
                    <StockItemCharacteristicsEdit
                      stockItem={props?.stockItem}
                      attributes={props?.stockItem?.product?.type?.attributes?.filter(
                        item => includedAttributes.includes(item.name),
                      )}
                      onSave={props.onChange}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <WithLoading loading={loading}>
                    <Button
                      disabled={
                        !Object.values(state).some(value => value !== null)
                      }
                      onClick={() => handleChangeItem(getChangedProps())}
                      variant="contained"
                      color="primary"
                    >
                      Сохранить
                    </Button>
                  </WithLoading>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {(!props?.stockItem?.product || isChangeProduct) && (
            <Grid item sm={12} md={6}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h4">Выбор продукта</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ProductSelect
                    product={state.product}
                    brand={props?.stockItem?.vehicle?.body?.model.brand}
                    onChange={product => setState({ product })}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {props?.stockItem?.product && !isChangeProduct && (
            <Grid item sm={12} md={6}>
              <div style={{ position: 'relative' }}>
                <Button
                  color="primary"
                  style={{ position: 'absolute', right: 0 }}
                  onClick={() => setIsChangeProduct(true)}
                >
                  Заменить продукт
                </Button>
              </div>

              <Product
                product={props?.stockItem?.product}
                onChange={props.onChange}
                loading={props.loading}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mt={2}>
        <ImageForm onAdd={handleAddImages} id="attachStockItemImages" />
      </Box>
      <Box mt={2}>
        <Images images={props?.stockItem?.images} onChange={props.onChange} />
      </Box>
      <Box mt={2}>
        <StockItems
          stockItems={stockItemsRequestData}
          loading={stockItemsRequest?.loading}
          onCancel={stockItemsRequest?.cancel}
          onFetch={() => stockItemsRequest?.refetch(stockItemsRequestVariables)}
          title="Товары с таким же типом с автомобилей с тем же кузовом"
        />
      </Box>
      <Box mt={2}>
        <Orders
          orders={ordesRequest?.data?.data}
          totalCount={ordesRequest?.data?.totalCount}
          loading={ordesRequest?.loading}
          withPagination={false}
        />
      </Box>
    </div>
  );
};
