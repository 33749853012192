import { useRequest } from 'src/hooks/useRequest';
import { request } from 'src/services/request';
import { Body, Engine, Model, STOWork } from 'src/shared/api';

export function useGetStoWorks(params?: { skip?: boolean }) {
  return useRequest<{ data: STOWork[]; totalCount: number }>({
    url: `/sto-works`,
    method: 'get',
    skip: params?.skip,
  });
}

export function useGetStoWorksPrices(params?: { skip?: boolean }) {
  return useRequest<{ data: STOWork[]; totalCount: number }>({
    url: `/sto-works/prices`,
    method: 'get',
    skip: params?.skip,
  });
}


export function createSTOWork(value: {
  name: string;
  price?: number | string | null;
  comment?: string | null;
}) {
  return request.post(`/sto-works`, value);
}

export function createSTOWorkPrice(params: {
  id: number;
  value: {
    price: number;
    comment: string | null;
    model: Pick<Model, 'id'> | null;
    body: Pick<Body, 'id'> | null;
    engine: Pick<Engine, 'id'> | null;
  };
}) {
  return request.post(`/sto-works/${params.id}/prices`, params.value);
}
