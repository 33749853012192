import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { WithLoading } from '../common/with-loading';
import { useDropzone } from 'react-dropzone';

interface ImageForm {
  onAdd: ((files: File[]) => Promise<any>) | null;
  id: string;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      dropZoneContainer: {
        border: `dashed ${theme.palette.divider} 2px`,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
      },
    }),
  { name: 'ImageForm' },
);

export const ImageForm: React.FunctionComponent<ImageForm> = ({
  onAdd,
  id,
}) => {
  const classes = useStyles({});
  const [loading, setLoading] = useState(false);

  const setAttachedFile = (filesList: any) => {
    const files: File[] = [];
    if (filesList && onAdd) {
      filesList?.forEach((file: any) => files.push(file));
      setLoading(true);
      onAdd(files).then(() => {
        setLoading(false);
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: setAttachedFile,
  });

  return (
    <div className={classes.root}>
      <WithLoading loading={loading}>
        <div
          {...getRootProps({
            className: classes.dropZoneContainer,
          })}
        >
          <input {...getInputProps()} />
          <div>Перетащите файлы сюда или нажмите для выбора файлов</div>
        </div>
      </WithLoading>
    </div>
  );
};
