import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';
import { typeProductName } from 'src/types';
import { useGetProductNames } from 'src/api/productApi';

type typeProps = {
  disabled?: boolean;
  name?: string | null;
  onSelect: (name?: string | null) => any;
  onChange: (name?: string | null) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'NameSelect' },
);

export const NameSelect: React.FunctionComponent<typeProps> = ({
  name,
  onSelect,
  onChange,
  disabled,
}) => {
  const classes = useStyles({});

  const { data, loading } = useGetProductNames();

  const [value, setValue] = useState<typeProductName | null>(null);

  useEffect(() => {
    const valueFromArray =
      data && name?.length
        ? (data?.find(item => item.value === name) as typeProductName)
        : null;
    if (valueFromArray) {
      setValue(valueFromArray);
    }
  }, [name, data]);

  return (
    <div className={classes.root}>
      <Autocomplete
        clearOnBlur={false}
        fullWidth
        value={value}
        onChange={(e: any, name: typeProductName) => onSelect(name?.value)}
        options={
          data?.sort((a, b) =>
            a?.value && b?.value && a.value > b.value ? 1 : -1,
          ) || []
        }
        getOptionLabel={(option: typeProductName) => option.value}
        onChangeTextField={(e: any) => onChange(e.target.value)}
        label="Название продукта"
        loading={loading}
        disabled={disabled}
      />
    </div>
  );
};
