import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Theme,
} from '@material-ui/core';

import { AttributeSelect } from 'src/features/attribute-select';

import { useGetAttributes } from 'src/entities/attribute';
import { Attribute } from 'src/shared/api';
import { Add } from '@material-ui/icons';

interface Props {
  onAdd: (attribute: Attribute) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'AddAttributeDialog' },
);

export const AddAttributeDialog: React.FunctionComponent<Props> = ({
  onAdd,
}) => {
  const classes = useStyles({});

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState<null | Attribute>(null);

  const attributesRequest = useGetAttributes({ skip: !open });

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleAdd = () => {
    if (value) onAdd(value);
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Fab size="small" color="primary" onClick={handleOpen}>
        <Add />
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <AttributeSelect
            attributes={attributesRequest.data}
            onChange={setValue}
            value={value}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button
            disabled={!value}
            variant="contained"
            color="primary"
            onClick={handleAdd}
          >
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
