import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper, Theme, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import { useGetEmployee } from 'src/api/employeeApi';
import { useGetBillingJournal } from 'src/api/billingApi';
import { BillingJournal } from '../BillingJournal';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ru from 'date-fns/locale/ru';
import { useRouter } from 'src/hooks/useRouter';
import { endOfDay, endOfMonth, format, startOfDay, startOfMonth } from 'date-fns';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'EmployeePage' },
);

export const EmployeePage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const { query, push } = useRouter();

  const { employeeId } = useParams<{ employeeId: string }>();

  const requestEmployee = useGetEmployee({
    variables: {
      employeeId,
    },
  });

  const requestJournalVariables = {
    employeeId,
    from: query.from || format(startOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    to: query.to || format(endOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'),
  };

  const requestJournal = useGetBillingJournal({
    variables: requestJournalVariables,
  });

  const handleRefetch = (params: any = {}) => {
    push(null, { ...requestJournalVariables, ...params });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">
        {requestEmployee?.data?.data?.fullName}
      </Typography>
      <Box mt={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <DatePicker
                cancelLabel="Отмена"
                okLabel={null}
                autoOk
                label="От"
                format="yyyy-MM-dd"
                fullWidth
                inputVariant="outlined"
                value={new Date(requestJournalVariables.from)}
                onChange={date =>
                  handleRefetch({
                    // @ts-ignore
                    from: format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DatePicker
                cancelLabel="Отмена"
                okLabel={null}
                autoOk
                format="yyyy-MM-dd"
                fullWidth
                label="До"
                inputVariant="outlined"
                value={new Date(requestJournalVariables.to)}
                onChange={date =>
                  handleRefetch({
                    // @ts-ignore
                    to: format(endOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
                  })
                }
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <Box mt={2}>
          <Paper>
            <BillingJournal journal={requestJournal?.data?.data} />
          </Paper>
        </Box>
      </Box>
    </div>
  );
};
