import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  MenuItem,
  Select,
  Box,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { typeBody } from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';
import { useCreateBody } from 'src/api/bodyApi';
import { useCreateRestyle } from 'src/api/restylesApi';

type typeProps = {
  body: typeBody | null;
  onCreate: () => any;
};

type typeState = {
  index: number;
  body: typeBody | null;
  yearStart: number | null;
  yearEnd: number | null;
};

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'RestyleCreate',
});

export const RestyleCreate: React.FunctionComponent<typeProps> = ({
  body,
  onCreate,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);

  const [state, changeState] = useState<typeState>({
    index: 0,
    body: null,
    yearStart: null,
    yearEnd: null,
  });

  const setState = (data: Partial<typeState>) =>
    changeState((prevState: typeState) => ({ ...prevState, ...data }));

  useEffect(() => setState({ body }), [body]);

  const [create, { loading }] = useCreateRestyle();

  const handleCreate = () => {
    if (state.body && state.yearStart) {
      create({
        index: state.index,
        body: state.body,
        yearStart: state.yearStart,
        yearEnd: state.yearEnd,
      }).then(() => {
        setState({ index: 0, yearStart: null, yearEnd: null });
        setDialog(false);
        onCreate();
      });
    }
  };

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setDialog(true)}>
        <Add />
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <Box mt={2}>
            <Select
              fullWidth
              label="Номер рестайлинга"
              autoFocus
              onChange={e => setState({ index: e.target.value as number })}
              value={state.index}
            >
              <MenuItem value={0}>Первая модель</MenuItem>
              <MenuItem value={1}>Рестайлинг</MenuItem>
              <MenuItem value={2}>2-й рестайлинг</MenuItem>
              <MenuItem value={3}>3-й рестайлинг</MenuItem>
              <MenuItem value={4}>4-й рестайлинг</MenuItem>
              <MenuItem value={5}>5-й рестайлинг</MenuItem>
            </Select>
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              type="number"
              label="Начало производства"
              onChange={e =>
                setState({ yearStart: parseInt(e.target.value, 10) })
              }
              value={state.yearStart}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              type="number"
              label="Окончание производства"
              onChange={e =>
                setState({ yearEnd: parseInt(e.target.value, 10) })
              }
              value={state.yearEnd}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={!state.body || !state.yearStart}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
