import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';
import { useGetContractors, useGetJournalReasons } from 'src/api/billingApi';
import { typeBillingJournalReason, typeContractor } from 'src/types';
import { orderBy } from 'lodash';

type typeProps = {
  contractor?: typeContractor | null;
  disabled?: boolean;
  label?: string;
  filter?: (item: typeBillingJournalReason) => boolean;
  onSelect?: (type: typeContractor) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ContractorSelect' },
);

export const ContractorSelect: React.FunctionComponent<typeProps> = ({
  filter: providedFilter,
  onSelect,
  disabled,
  label = "Контрагент",
  contractor
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState<typeContractor | null>(null);

  const { data, loading } = useGetContractors();

  useEffect(() => {
    const valueFromArray =
      data && contractor
        ? (data?.data?.find(
            (item: any) => contractor && item?.id === contractor?.id,
          ) as typeContractor)
        : null;
    if (valueFromArray) {
      setValue(valueFromArray);
    }
  }, [contractor, data]);

  const filter = (item: typeBillingJournalReason) => {
    if (providedFilter) return providedFilter(item);
    return item;
  };

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onSelect && onSelect(value);
  };

  return (
    <Box className={classes.root} display="flex">
      <Autocomplete
        disabled={disabled}
        fullWidth
        value={value}
        onChange={(e, value: typeContractor) => hangdeOnChange(value)}
        options={orderBy(data?.data?.filter(filter) || [], ['name'])}
        getOptionLabel={(option: typeContractor) => `${option.name}`}
        label={label}
        loading={loading}
      />
    </Box>
  );
};
