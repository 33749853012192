import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  TextField,
  Theme,
} from '@material-ui/core';
import { useQuickSearch } from 'src/api/searchApi';
import { useRouter } from 'src/hooks/useRouter';
import { Table } from '../common/table';
import { typeProduct, typeStockItem, typeProductComponent } from 'src/types';
import { StockItemListItem } from '../StockItemListItem';
import Axios from 'axios';
import { Add, Check } from '@material-ui/icons';
import { Images } from '../images';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'DepricatedSearchPage' },
);

export const DepricatedSearchPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const router = useRouter();
  const [query, setQuery] = useState('');
  const [orderId, setOrderId] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [item, setItem] = useState<typeStockItem | null>(null);
  const [addItems, setAddedItem] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const { data, loading } = useQuickSearch({
    variables: {
      query: router.query.query,
    },
    skip: !router.query?.query,
  });

  useEffect(() => {
    if (router.query.orderId) setOrderId(router.query.orderId);
    if (router.query?.query) setQuery(router.query?.query);
  }, [router.query.orderId, router.query?.query]);

  const handleAddToOrder = (item: typeStockItem) => {
    const formData = new FormData();
    // @ts-ignore
    if (item) {
      formData.append('orderId', orderId);
      formData.append('brand', item?.product?.brand?.originalName || '');
      formData.append('article', item?.product?.articleCanonical || '');
      formData.append('name', item?.itemNameWithPosition || '');
      formData.append('quantity', `${quantity}`);
      formData.append(
        'startPrice',
        `${item.startPrice && item.startPrice / 100}`,
      );
      formData.append(
        'offerPrice',
        `${item.offerPrice && item.offerPrice / 100}`,
      );
      formData.append('supplierId', `${item?.warehouse?.deprecatedId}` || '');
      Axios.post(
        `http://restanservice.ru/ajax/add_spare_to_order_from_symfony_search`,
        formData,
      ).then(() => {
        setQuantity(1);
        setAnchorEl(null);
        setAddedItem([...addItems, item?.id]);
      });
    }
  };

  const renderAddToOrderButton = (itemToAdd: typeStockItem) =>
    Boolean(orderId) && (
      <>
        <IconButton
          color="primary"
          onClick={e => {
            setAnchorEl(e.currentTarget);
            setItem(itemToAdd);
          }}
        >
          {addItems.includes(itemToAdd?.id) ? <Check /> : <Add />}
        </IconButton>
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && item?.id === itemToAdd?.id}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box p={2}>
            <TextField
              variant="outlined"
              type="number"
              value={quantity}
              onChange={e => setQuantity(parseInt(e.target.value, 10))}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => handleAddToOrder(itemToAdd)}
                  >
                    <IconButton>
                      <Check />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Popover>
      </>
    );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            label="Артикул"
            variant="outlined"
            fullWidth
            defaultValue={router.query.query}
            onChange={e => setQuery(e.target.value)}
            InputProps={{
              onKeyUp: e => {
                e.preventDefault();

                if (e.key === 'Enter') {
                  router.push(null, { orderId, query });
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Заказ"
            variant="outlined"
            fullWidth
            onChange={e => setOrderId(e.target.value)}
            defaultValue={router.query.orderId}
            InputProps={{
              onKeyUp: e => {
                e.preventDefault();

                if (e.key === 'Enter') {
                  router.push(null, { orderId, query });
                }
              },
            }}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Paper>
          <Table
            title="Продукты"
            loading={loading}
            data={
              data?.products
                ?.map((product: typeProduct) =>
                  product.stockItems
                    .filter(item => item.quantity)
                    .map((item: typeStockItem) => ({
                      ...item,
                      product,
                    })),
                )
                ?.flat() || []
            }
            headers={[
              {
                title: 'Товар',
                extract: (item: typeStockItem) => (
                  <StockItemListItem stockItem={item} withPrice={false} />
                ),
                component: 'th',
                scope: 'row',
              },
              {
                title: 'Фото',
                extract: (item: typeStockItem) => {
                  if (item.images?.length) {
                    return (
                      <Images images={item.images} preview withDelete={false} />
                    );
                  }
                  if (item?.product?.images?.length) {
                    return (
                      <Images
                        images={item.product.images}
                        preview
                        withDelete={false}
                      />
                    );
                  }
                  return null;
                },
              },
              {
                title: 'Количество',
                extract: (item: typeStockItem) => (
                  <span>{item.quantity} шт.</span>
                ),
                scope: 'row',
              },
              {
                title: 'Цена',
                extract: (item: typeStockItem) => (
                  <span>
                    {item?.offerPrice && item?.offerPrice / 100} ₽ - 5% ={' '}
                    {Math.ceil(
                      item?.offerPrice ? (item?.offerPrice * 0.95) / 100 : 0,
                    )}{' '}
                    ₽
                  </span>
                ),
                scope: 'row',
              },
              {
                title: 'Действия',
                align: 'right',
                extract: (item: typeStockItem) => (
                  <Box display="flex" justifyContent="flex-end">
                    {renderAddToOrderButton(item)}
                  </Box>
                ),
                scope: 'row',
              },
            ]}
          />
        </Paper>
      </Box>
      <Box mt={2}>
        <Paper>
          <Table
            title="Аналоги"
            loading={loading}
            data={
              data?.analogs
                ?.map((product: typeProduct) =>
                  product.stockItems
                    .filter(item => item.quantity)
                    .map((item: typeStockItem) => ({
                      ...item,
                      product,
                    })),
                )
                ?.flat() || []
            }
            headers={[
              {
                title: 'Товар',
                extract: (item: typeStockItem) => (
                  <StockItemListItem
                    withId={false}
                    stockItem={item}
                    withPrice={false}
                  />
                ),
                component: 'th',
                scope: 'row',
              },
              {
                title: 'Фото',
                extract: (item: typeStockItem) => {
                  if (item.images?.length) {
                    return (
                      <Images images={item.images} preview withDelete={false} />
                    );
                  }
                  if (item?.product?.images?.length) {
                    return (
                      <Images
                        images={item.product.images}
                        preview
                        withDelete={false}
                      />
                    );
                  }
                  return null;
                },
              },
              {
                title: 'Количество',
                extract: (item: typeStockItem) => (
                  <span>{item.quantity} шт.</span>
                ),
                scope: 'row',
              },
              {
                title: 'Цена',
                extract: (item: typeStockItem) => (
                  <span>
                    {item?.offerPrice && item?.offerPrice / 100} ₽ - 5% ={' '}
                    {Math.ceil(
                      item?.offerPrice ? (item?.offerPrice * 0.95) / 100 : 0,
                    )}{' '}
                    ₽
                  </span>
                ),
                scope: 'row',
              },
              {
                title: 'Действия',
                align: 'right',
                extract: (item: typeStockItem) => (
                  <Box display="flex" justifyContent="flex-end">
                    {renderAddToOrderButton(item)}
                  </Box>
                ),
                scope: 'row',
              },
            ]}
          />
        </Paper>
      </Box>
      <Box mt={2}>
        <Paper>
          <Table
            title="Компоненты"
            loading={loading}
            data={
              data?.components
                ?.map((component: typeProductComponent) =>
                  component.product.stockItems
                    .filter((item: typeStockItem) => item.quantity)
                    .map((item: typeStockItem) => ({
                      ...item,
                      product: {
                        ...component.product,
                        mainComponent: component,
                      },
                    })),
                )
                ?.flat() || []
            }
            headers={[
              {
                title: 'Товар',
                extract: (item: typeStockItem) => (
                  <StockItemListItem stockItem={item} withPrice={false} />
                ),
                component: 'th',
                scope: 'row',
              },
              {
                title: 'Фото',
                extract: (item: typeStockItem) => {
                  if (item.images?.length) {
                    return (
                      <Images images={item.images} preview withDelete={false} />
                    );
                  }
                  if (item?.product?.images?.length) {
                    return (
                      <Images
                        images={item.product.images}
                        preview
                        withDelete={false}
                      />
                    );
                  }
                  return null;
                },
              },
              {
                title: 'Количество',
                extract: (item: typeStockItem) => (
                  <span>{item.quantity} шт.</span>
                ),
                scope: 'row',
              },
              {
                title: 'Цена',
                extract: (item: typeStockItem) => (
                  <span>
                    {item?.offerPrice && item?.offerPrice / 100} ₽ - 5% ={' '}
                    {Math.ceil(
                      item?.offerPrice ? (item?.offerPrice * 0.95) / 100 : 0,
                    )}{' '}
                    ₽
                  </span>
                ),
                scope: 'row',
              },
              {
                title: 'Действия',
                align: 'right',
                extract: (item: typeStockItem) => (
                  <Box display="flex" justifyContent="flex-end">
                    {renderAddToOrderButton(item)}
                  </Box>
                ),
                scope: 'row',
              },
            ]}
          />
        </Paper>
      </Box>
    </div>
  );
};
