import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  TextField,
  Grid,
  DialogActions,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { WithLoading } from 'src/components/common/with-loading';
import { typeProduct, typeBrand, typeProductType } from 'src/types';
import { useCreateProduct } from 'src/api/productApi';
import { InstallationPositions } from '../installation-positions';
import { BrandSelect } from '../brand-select';
import { NameSelect } from '../name-select';
import { ProductTypeSelect } from '../ProductTypeSelect';

type typeProps = {
  brand?: typeBrand | null;
  article?: string | null;
  disabled?: boolean;
  onChange?: (prodcut: typeProduct) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductCreate' },
);

type typeState = {
  brand: typeBrand | null;
  name: string | null;
  type: typeProductType | null;
  article: string | null;
  topBottom: string | null;
  leftRight: string | null;
  frontRear: string | null;
};

const initialState = {
  brand: null,
  name: null,
  type: null,
  article: '',
  topBottom: null,
  leftRight: null,
  frontRear: null,
};

export const ProductCreate: React.FunctionComponent<typeProps> = ({
  onChange,
  brand,
  article,
  disabled,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);

  const [state, changeState] = useState<typeState>(initialState);
  const [empty, setEmpty] = useState(false);

  const setState = (data: Partial<typeState>) =>
    changeState(prevState => ({ ...prevState, ...data }));

  const [create, { data, loading }] = useCreateProduct();

  useEffect(() => {
    setState({ brand });
  }, [brand]);

  useEffect(() => {
    setState({ article });
  }, [article]);

  const handleCreate = () => {
    create({
      ...state,
      name: state.name
        ? { value: state.name, supplier: { id: 1, alias: 'restan' } }
        : null,
      supplier: { id: 1, alias: 'restan' },
    }).then((product: typeProduct) => {
      setDialog(false);
      if (onChange) {
        onChange(product);
      }
      setState(initialState);
    });
  };

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setDialog(true)} disabled={disabled}>
        <Add />
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormControlLabel
                onChange={() => {
                  setEmpty(!empty);
                  setState({ brand: null, article: null });
                }}
                control={<Checkbox checked={Boolean(empty)} />}
                label="Пустой продукт"
              />
            </Grid>
            {!empty && (
              <>
                <Grid item xs={12}>
                  <BrandSelect
                    brand={state.brand}
                    onChange={brand => setState({ brand })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={state.brand?.originalName === 'Restan'}
                    variant="outlined"
                    fullWidth
                    label="Артикул"
                    value={
                      state.brand?.originalName === 'Restan'
                        ? 'Артикул будет присвоен автоматически'
                        : state.article || ''
                    }
                    onChange={e => setState({ article: e.target.value })}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <ProductTypeSelect onSelect={type => setState({ type })} />
            </Grid>
            <Grid item xs={12}>
              <InstallationPositions
                leftRight={state.leftRight}
                topBottom={state.topBottom}
                frontRear={state.frontRear}
                onChange={setState}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button onClick={handleCreate} color="primary" variant="contained">
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
