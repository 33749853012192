import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
  Theme,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useGetCustomers } from 'src/api/customerApi';
import { useDebounce } from 'src/hooks/useDebounce';
import { WithLoading } from 'src/components/common/with-loading';
import { CustomerCreateDialog } from 'src/components/CustomerCreateDialog';
import { declOfNum } from 'src/lib/declOfNum';
import { typeCustomer } from 'src/types';

interface typeProps {
  disabled?: boolean;
  phone?: string | null;
  onChangeNewCustomer?: (customer: typeCustomer) => any;
  onChange?: (phone: string) => any;
  onSelect?: (customer: typeCustomer | null) => any;
  customer?: typeCustomer | null;
  newCustomer?: typeCustomer | null;
  label?: string
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'CustomerSelect' },
);

export const CustomerSelect: React.FunctionComponent<typeProps> = ({
  phone: providedPhone,
  onChange,
  onChangeNewCustomer,
  customer,
  newCustomer,
  onSelect,
  disabled,
  label = 'Телефон',
}) => {
  const classes = useStyles({});
  const [phone, setPhone] = useState<null | string>('');

  useEffect(() => {
    if (typeof providedPhone !== 'undefined') {
      setPhone(providedPhone);
    }
  }, [providedPhone]);

  const handleChange = (phone: string) => {
    if (onChange) onChange(phone);
    setPhone(phone);
  };

  const handleSelect = (customer: typeCustomer | null) => {
    if (onSelect) onSelect(customer);
  };

  const value = phone;

  const debouncedValue = useDebounce<string>(value, 500);

  const request = useGetCustomers({
    variables: { phone: debouncedValue },
    skip: debouncedValue?.length < 10,
  });

  const customers = customer ? [customer] : request.data;

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center">
        <NumberFormat
          disabled={disabled}
          customInput={TextField}
          format="(###) ### ## ##"
          mask="_"
          variant="outlined"
          label={label}
          InputLabelProps={{ shrink: true }}
          id="phone"
          fullWidth
          placeholder="(___) __ __ __"
          value={value}
          onValueChange={e => handleChange(e.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+7</InputAdornment>
            ),
          }}
        />
        <Box ml={2}>
          <CustomerCreateDialog
            phoneNumber={phone}
            onCreate={data => request.refetch(data)}
          />
        </Box>
      </Box>
      <WithLoading loading={request.loading}>
        <List>
          {customers?.map(item => (
            <ListItem key={item.id} button onClick={() => handleSelect(item)}>
              <ListItemIcon>
                <Radio
                  checked={Boolean(customer && customer?.id === item?.id)}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                primary={item.fullName}
                secondary={`${item.ordersCount} ${declOfNum([
                  'заказ',
                  'заказа',
                  'заказов',
                ])(item.ordersCount || 0)}`}
              />
            </ListItem>
          ))}
          {request.data?.length === 0 && 'Клиент не найден'}
        </List>
      </WithLoading>
    </div>
  );
};
