import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box } from '@material-ui/core';
import { Vehicles } from 'src/components/vehicles';
import { useGetVehicles } from 'src/api/vehicleApi';
import { VehicleCreate } from '../vehicle-create';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'VehiclesPage' },
);

export const VehiclesPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { data, loading, refetch, cancel } = useGetVehicles();

  const handleRefetch = () => refetch();

  return (
    <div className={classes.root}>
      <Vehicles
        vehicles={data}
        onFetch={refetch}
        onCancel={cancel}
        loading={loading}
        actions={[() => <VehicleCreate onCreate={handleRefetch} />]}
      />
    </div>
  );
};
