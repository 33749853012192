import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, List, ListItem, ListItemText, Theme } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';

import { STOWork, STOWorkPrice } from 'src/shared/api';
import { useGetStoWorksPrices } from 'src/entities/sto-work';
import { STOWorkCreateDialog } from './STOWorkCreateDialog';
import { STOWorkPriceCreate } from './STOWorkPriceCreate';

interface typeProps {
  onChange: (value: STOWorkPrice) => any;
  value?: STOWorkPrice | null;
  onClickPrice?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'StoWorkSelect' },
);

export const StoWorkSelect: React.FunctionComponent<typeProps> = ({
  value,
  onChange,
  onClickPrice,
}) => {
  const classes = useStyles({});

  const request = useGetStoWorksPrices();

  return (
    <Box display="flex" alignItems="center">
      <Autocomplete
        disableCloseOnSelect
        fullWidth
        value={value}
        onChange={(e, value: any) => onChange(value)}
        options={request.data?.data || []}
        getOptionLabel={(option: STOWorkPrice) => option.stoWork?.name}
        renderOption={(option: STOWorkPrice) => (
          <ListItemText
            primary={`${option.stoWork?.name}${
              option.stoWork?.comment ? ` (${option.stoWork?.comment})` : ''
            }`}
            secondary={[
              option.price && `${option.price / 100}₽`,
              [option?.model?.name, option.body?.name, option.engine?.name]
                .filter(item => item)
                .join(' '),
              option.comment,
            ]
              .filter(item => item)
              .join(' | ')}
          />
        )}
        label="Работа"
        loading={request.loading}
      />
      <Box ml={2}>
        <STOWorkCreateDialog onSave={() => request.refetch()} />
      </Box>
      <Box ml={2}>
        <STOWorkPriceCreate onSave={() => request.refetch()} stoWork={value?.stoWork} />
      </Box>
    </Box>
  );
};
