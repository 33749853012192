import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Paper, Link } from '@material-ui/core';
import {
  typeListObject,
  typeProduct,
  typeProductAnalog,
  typeVehicle,
} from 'src/types';
import { Table } from 'src/components/common/table';
import { useRouter } from 'src/hooks/useRouter';
import { ProductListItem } from './ProductListItem';
import { format } from 'date-fns';
import { AnalogCreate } from './AnalogCreate';

type typeProps = {
  analogs: typeProductAnalog[] | null;
  product?: typeProduct | null;
  onFetch?: (params?: { limit: number; offset: number }) => any;
  onCancel?: any;
  loading: boolean;
  withPagination?: boolean;
  limit?: number;
  totalCount?: number;
  offset?: number;
  onClickRow?: (item: typeVehicle) => any;
};

type typeState = {
  rowsPerPage: number;
  page: number;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      title: {
        flex: '1 1 100%',
      },
    }),
  { name: 'Analogs' },
);

export const Analogs: React.FunctionComponent<typeProps> = ({
  analogs,
  loading,
  onFetch,
  onCancel,
  limit,
  offset,
  totalCount,
  product,
  withPagination,
  onClickRow,
}) => {
  const classes = useStyles({});

  const { push } = useRouter();

  return (
    <div className={classes.root}>
      <Paper>
        <Table
          title="Аналоги"
          data={analogs}
          withPagination={withPagination}
          totalCount={totalCount ?? analogs?.length}
          headers={[
            {
              title: 'id',
              extract: (item: typeProductAnalog) => item.id,
              component: 'th',
              scope: 'row',
            },
            {
              title: 'Продукт',
              extract: (item: typeProductAnalog) => (
                <ProductListItem product={item.product} />
              ),
            },
            {
              title: 'Аналог',
              extract: (item: typeProductAnalog) => (
                <ProductListItem product={item.analog} />
              ),
            },
            {
              title: 'Источник',
              extract: (item: typeProductAnalog) => item.source,
            },
            {
              title: 'Дата создания',
              extract: (item: typeProductAnalog) =>
                format(new Date(item?.createdAt), 'yyyy-MM-dd HH:mm'),
            },
          ]}
          loading={loading}
          onCancel={onCancel}
          onFetch={onFetch}
          limit={limit}
          offset={offset}
          actions={[
            () => (
              <AnalogCreate
                product={product}
                size="small"
                onChange={() => onFetch && onFetch()}
              />
            ),
          ]}
        />
      </Paper>
    </div>
  );
};
