import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Link,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useGetVehicle } from 'src/api/vehicleApi';
import { Vehicle } from 'src/components/vehicle';
import { StockItems } from 'src/components/stock-items';
import { StockItemCreate } from 'src/components/stock-item-create';
import { BillingJournal } from '../BillingJournal';
import { useGetBillingJournal } from 'src/api/billingApi';
import { BillingJournalCreate } from '../BillingJournalCreate';
import { typeOrderRealizationElement } from 'src/types';
import { Table } from '../common/table';
import { StockItemListItem } from '../StockItemListItem';
import { format, compareAsc } from 'date-fns';
import { SummaryChart } from '../SummaryChart';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'VehiclePage' },
);

export const VehiclePage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});
  const [tab, setTab] = useState(0);

  const { vehicleId } = useParams<{ vehicleId: string }>();

  const { data, loading, refetch, cancel } = useGetVehicle({
    variables: { vehicleId },
  });
  const billingJournal = useGetBillingJournal({
    variables: { vehicleId },
  });

  const realizationsElements = data?.stockItems
    ?.map(stockItem =>
      stockItem.ordersElements
        ?.filter(
          orderElement =>
            orderElement.orderRealizationElement &&
            !orderElement.orderElementReturns?.length,
        )
        .map(orderElement => ({
          ...orderElement.orderRealizationElement,
          orderElement: { ...orderElement, stockItem },
        })),
    )
    .flat()
    .sort((a, b) =>
      a?.orderElement?.orderRealizationElement?.createdAt &&
      b?.orderElement?.orderRealizationElement?.createdAt
        ? compareAsc(
            new Date(a?.orderElement?.orderRealizationElement?.createdAt),
            new Date(b?.orderElement?.orderRealizationElement?.createdAt),
          )
        : 0,
    ) as typeOrderRealizationElement[];

  const realizationsSummary =
    (realizationsElements?.reduce(
      (prev, curr) =>
        curr && curr.totalCost ? prev + curr?.totalCost : prev + 0,
      0,
    ) || 0) / 100;

  const paymentsSummary =
    (billingJournal?.data?.data?.reduce(
      (prev, curr) =>
        curr && curr.posts
          ? curr.posts?.reduce(
              (prevPost, currPost) =>
                currPost && currPost.value && currPost.value < 0
                  ? currPost.value + prevPost
                  : prevPost,
              0,
            ) + prev
          : prev,
      0,
    ) || 0) / 100;

  return (
    <div className={classes.root}>
      {data?.name && <Typography variant="h5">{data?.name}</Typography>}
      <Box mt={2}>
        <Tabs
          value={tab}
          onChange={(e, value) => setTab(value)}
          aria-label="simple tabs example"
        >
          <Tab label="Товары" />
          <Tab
            label={`Продажи${
              realizationsSummary !== 0
                ? ` ${realizationsSummary.toLocaleString('ru-Ru')} ₽`
                : ''
            }`}
          />
          <Tab
            label={`Расходы${
              paymentsSummary !== 0
                ? ` ${paymentsSummary.toLocaleString('ru-Ru')}₽`
                : ''
            }`}
          />
          <Tab label={'Авто'} />
        </Tabs>
        {tab === 0 && (
          <>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <StockItemCreate
                vehicle={data}
                onChange={() => refetch({ vehicleId })}
              />
            </Box>
            <Box mt={2}>
              <StockItems
                vehicle={data}
                limit={data?.stockItems?.length}
                offset={0}
                onFetch={() => refetch({ vehicleId })}
                onCancel={cancel}
                loading={loading}
                stockItems={{
                  data:
                    data?.stockItems
                      ?.sort((a, b) => b.id - a.id)
                      .map(item => ({ ...item, vehicle: data })) || [],
                  totalCount: data?.stockItems?.length || 0,
                }}
              />
            </Box>
          </>
        )}
        {tab === 1 && (
          <Box mt={2}>
            <SummaryChart
              items={realizationsElements || []}
              limit={paymentsSummary ? paymentsSummary * -1 : 0}
            />
            <Paper>
              <Table
                withSummary
                withPagination={false}
                data={realizationsElements || []}
                totalCount={realizationsElements?.length || 0}
                headers={[
                  {
                    title: 'Дата',
                    extract: (item: typeOrderRealizationElement) =>
                      item?.orderElement?.orderRealizationElement?.createdAt &&
                      format(
                        new Date(
                          item?.orderElement?.orderRealizationElement?.createdAt,
                        ),
                        'yyyy-MM-dd',
                      ),
                    scope: 'row',
                  },
                  {
                    title: 'ID',
                    extract: (item: typeOrderRealizationElement) =>
                      item?.orderElement?.stockItem?.id && (
                        <Link
                          target="_blank"
                          href={`/stock-items/${item?.orderElement?.stockItem?.id}`}
                        >
                          {item?.orderElement?.stockItem?.id}
                        </Link>
                      ),
                    scope: 'row',
                  },
                  {
                    title: 'Товар',
                    extract: (item: typeOrderRealizationElement) =>
                      item?.orderElement?.stockItem && (
                        <StockItemListItem
                          stockItem={item?.orderElement?.stockItem}
                          withPrice={false}
                        />
                      ),
                    scope: 'row',
                  },
                  {
                    title: 'Количество',
                    extract: (item: typeOrderRealizationElement) => (
                      <span>{item?.quantity} шт.</span>
                    ),

                    scope: 'row',
                  },
                  {
                    title: 'Цена за штуку',
                    align: 'right',
                    extract: (item: typeOrderRealizationElement) => (
                      <Box
                        style={{ cursor: 'pointer' }}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                      >
                        {item?.offerPrice && item?.offerPrice / 100} ₽
                      </Box>
                    ),

                    scope: 'row',
                  },
                  {
                    title: 'Сумма',
                    align: 'right',
                    summary: `${realizationsSummary}  ₽`,
                    extract: (item: typeOrderRealizationElement) => (
                      <Box
                        style={{ cursor: 'pointer' }}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                      >
                        <b>{item?.totalCost && item?.totalCost / 100} ₽</b>
                      </Box>
                    ),

                    scope: 'row',
                  },
                ]}
                loading={loading}
              />
            </Paper>
          </Box>
        )}
        {tab === 2 && (
          <Box mt={2}>
            <Box display="flex" justifyContent="flex-end">
              <BillingJournalCreate
                vehicle={data}
                onCreate={() => billingJournal?.refetch({ vehicleId })}
              />
            </Box>
            <Box mt={2}>
              <Paper>
                <BillingJournal
                  journal={billingJournal?.data?.data}
                  totalCount={billingJournal?.data?.totalCount}
                  cancel={billingJournal?.cancel}
                  refetch={() => billingJournal?.refetch({ vehicleId })}
                  loading={billingJournal?.loading}
                />
              </Paper>
            </Box>
          </Box>
        )}
        {tab === 3 && (
          <Box mt={2}>
            <Vehicle vehicle={data} onChange={refetch} />
          </Box>
        )}
      </Box>
    </div>
  );
};
