import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { typeBody, typeModel } from 'src/types';
import { useGetBodies } from 'src/api/bodyApi';
import { orderBy } from 'lodash';

type typeProps = {
  onChange?: (body: typeBody | null) => any;
  body?: typeBody | null;
  model?: typeModel | null;
  disabled?: boolean;
  all?: boolean;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      labelSub: {
        color: theme.palette.grey[400],
        fontSize: theme.typography.pxToRem(14),
      },
    }),
  { name: 'BodySelect' },
);

export const BodySelect: React.FunctionComponent<typeProps> = ({
  onChange,
  body,
  model,
  disabled,
  all = false,
}) => {
  const classes = useStyles({});

  const [value, setValue] = useState<typeBody | null>(null);

  const { data } = useGetBodies({
    variables: { modelId: model?.id },
    skip: !all && !model,
  });

  useEffect(() => {
    const valueFromArray =
      data && body
        ? (data?.data.find(item => item.id === body.id) as typeBody)
        : null;
    setValue(valueFromArray);
  }, [body, data]);

  const handleOnChange = (nextBody: typeBody | null) => {
    setValue(nextBody);
    if (onChange) {
      onChange(nextBody);
    }
  };

  return (
    <Autocomplete
      disabled={(!all && !model) || disabled}
      value={value}
      onChange={(e, value) => handleOnChange(value)}
      options={orderBy(data?.data || [], ['name'])}
      getOptionLabel={(option: typeBody) => option.name}
      renderOption={(option: typeBody) => (
        <div>
          <div>{option?.name}</div>
          <div className={classes.labelSub}>{option?.model?.name}</div>
        </div>
      )}
      renderInput={(params: any) => (
        <TextField {...params} label="Кузова" variant="outlined" />
      )}
    />
  );
};
