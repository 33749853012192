import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Paper,
  Dialog,
  IconButton,
  Button,
  TextField,
  Box,
  Link,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  typeStockItem,
  typeListObject,
  typeVehicle,
  typeWarehouse,
  typeWarehousePlace,
} from 'src/types';
import { Table } from 'src/components/common/table';
import { LabelsPrint } from 'src/components/labels-print/LabelsPrint';
import { Print, ExposureZero, Image, Edit } from '@material-ui/icons';
import cn from 'classnames';
import { FuzzySearch } from 'src/components/fuzzy-search';
import { Images } from 'src/components/images';
import { WarehousePlaceSelect } from '../warehouse-place-select';
import { WarehouseSelect } from '../warehouse-select';
import { useBatchChangeStockItem } from 'src/api/stockItemApi';
import { useRouter } from 'src/hooks/useRouter';

type typeProps = {
  stockItems: typeListObject<typeStockItem> | null;
  onFetch: (params?: { limit: number; offset: number }) => any;
  onCancel: any;
  actions?: (() => React.ReactNode)[];
  loading: boolean;
  withFilter?: boolean;
  title?: string;
  withOutOfStock?: boolean;
  limit?: number;
  offset?: number;
  vehicle?: typeVehicle | null;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      printLabelItem: {
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      code: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: theme.typography.pxToRem(20),
      },
      onlyCode: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: theme.typography.pxToRem(72),
      },
      vehicle: {
        fontSize: theme.typography.pxToRem(10),
      },
      description: {
        maxWidth: '200px',
      },
      big: {
        fontSize: theme.typography.pxToRem(72),
      },
      outOfStock: {
        opacity: 0.3,
        '&:hover': {
          opacity: 1,
        },
      },
    }),
  { name: 'StockItems' },
);

export const StockItems: React.FunctionComponent<typeProps> = ({
  stockItems,
  loading,
  onFetch,
  onCancel,
  limit,
  offset,
  vehicle,
  title,
  withOutOfStock = true,
  actions = [],
  withFilter = true,
}) => {
  const classes = useStyles({});
  const router = useRouter();

  const [labelsPritDialog, setLabelsPritDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [onlyImages, setOnlyImages] = useState(false);

  const [warehouse, setWarehouse] = useState<typeWarehouse | null>(null);
  const [warehousePlace, setPlace] = useState<typeWarehousePlace | null>(null);

  const [labelsCount, setLabelsCount] = useState(0);
  const [labelsStart, setLabelsStart] = useState(0);
  const [showLabels, setShowLabels] = useState(false);
  const [zeroQuantity, setZeroQuantity] = useState('all');

  const [filtredList, setFiltredList] = useState<typeStockItem[] | null>(null);

  useEffect(() => {
    setLabelsStart(
      stockItems?.data && stockItems?.data[0] ? stockItems?.data[0].id + 1 : 0,
    );
  }, [stockItems]);

  const [batchChange, batchChangeRequest] = useBatchChangeStockItem();

  const handleBatchChange = (stockItems: typeStockItem[]) => {
    batchChange({ stockItems, warehouse, warehousePlace }).then(() => {
      onFetch().then(() => {
        setWarehouse(null);
        setPlace(null);
        setEditDialog(false);
      });
    });
  };

  const getLabels = (selectedItems: typeStockItem[]) => {
    if (selectedItems.length) {
      return selectedItems
        .reduce(
          (prev: typeStockItem[], curr: typeStockItem) => [
            ...prev,
            ...Array(curr.quantity).fill(curr),
          ],
          [],
        )
        .map((item: typeStockItem, index: number) => (
          <div className={classes.printLabelItem} key={index}>
            <div>
              <div className={classes.onlyCode}>
                <div>
                  <b>{item.id}</b>
                </div>
              </div>
            </div>
          </div>
        ));
    }

    if (labelsCount && showLabels) {
      return Array(labelsCount)
        .fill(null)
        .map((item, index) => (
          <div className={classes.printLabelItem} key={index}>
            <div>
              <div className={classes.onlyCode}>
                <div>
                  <b className={cn(!vehicle && classes.big)}>
                    {labelsStart == 0 ? '' : index + labelsStart}
                  </b>
                </div>
              </div>
            </div>
          </div>
        ));
    }

    return [];
  };

  const totalQuantity =
    stockItems?.data?.reduce((prev, curr) => prev + curr.quantity, 0) || 0;

  const totalPrice =
    stockItems?.data?.reduce(
      (prev, curr) => prev + curr.quantity * (curr.offerPrice || 0),
      0,
    ) || 0;

  const filteredByQuantity = stockItems?.data?.filter(item => {
    if (zeroQuantity === 'all') return true;
    if (zeroQuantity === 'outOfStock') return !item.quantity;
    if (zeroQuantity === 'inStock') return item.quantity;
  });

  const list = filtredList || filteredByQuantity;

  return (
    <div className={classes.root}>
      <Box display="flex">
        {withFilter && (
          <Box flexGrow={1} mr={2}>
            <FuzzySearch
              label="Быстрый фильтр по названию"
              onChange={setFiltredList}
              extract={(item: typeStockItem) =>
                `${item?.product?.articleCanonical} ${item.itemNameWithPosition} `
              }
              list={filteredByQuantity || []}
            />
          </Box>
        )}
        <Box>
          <IconButton
            title={onlyImages ? 'Список' : 'Только фото'}
            onClick={() => setOnlyImages(!onlyImages)}
            color={onlyImages ? 'primary' : 'default'}
          >
            <Image />
          </IconButton>
        </Box>
      </Box>
      <Box mt={2}>
        {!onlyImages && (
          <Paper>
            <Table
              withSummary
              title={title || "Товары"}
              data={list}
              totalCount={stockItems?.totalCount}
              headers={[
                {
                  title: 'id',
                  extract: (item: typeStockItem) => (
                    <Link target="_blank" href={`/stock-items/${item.id}`}>
                      {item.id}
                    </Link>
                  ),
                  component: 'th',
                  scope: 'row',
                },
                {
                  title: 'checkbox',
                  extract: (item: typeStockItem) => null,
                },
                {
                  title: 'Продукт',
                  warning: (item: typeStockItem) =>
                  !item?.product?.brand,
                  extract: (item: typeStockItem) => (
                    <>
                      {item?.product?.mainComponent?.component.brand
                        .originalName ||
                        item?.product?.brand?.originalName}{' '}
                      {item?.product && (
                        <span>
                          <Link
                            target="_blank"
                            href={`/products/${item?.product.id}`}
                          >
                            {item?.product?.mainComponent?.component.article ||
                              item?.product.article}
                          </Link>{' '}
                          <Link
                            target="_blank"
                            href={`https://www.google.com/search?tbm=isch&q=${
                              item?.product?.mainComponent?.component.article ||
                              item?.product.article
                            }`}
                          >
                            <b>G</b>
                          </Link>
                        </span>
                      )}
                      <br />
                      {item?.product?.nameWithPosition}
                      {item.description && (
                        <div className={classes.description}>
                          {item.description}
                        </div>
                      )}
                    </>
                  ),
                },
                {
                  title: 'Количество',
                  extract: (item: typeStockItem) => (
                    <span>{item.quantity} шт.</span>
                  ),
                  summary: `${totalQuantity}  шт.`,
                },
                {
                  title: 'Закупочная',
                  extract: (item: typeStockItem) => (
                    <span>{item.startPrice && item.startPrice / 100} руб.</span>
                  ),
                },
                {
                  title: 'Фиксированная',
                  extract: (item: typeStockItem) => (
                    <span>{item.fixedPrice && item.fixedPrice / 100} руб.</span>
                  ),
                },
                {
                  title: 'Продажная',
                  extract: (item: typeStockItem) => (
                    <span>{item.offerPrice && item.offerPrice / 100} руб.</span>
                  ),
                  warning: (item: typeStockItem) =>
                    Boolean(item.quantity && !item.offerPrice),
                  summary: `${totalPrice && totalPrice / 100}  руб.`,
                },
                {
                  title: 'Место',
                  extract: (item: typeStockItem) => item.warehousePlace?.name,
                  warning: (item: typeStockItem) =>
                    Boolean(item.quantity && !item.warehousePlace),
                },
                {
                  title: 'Фото',
                  extract: (item: typeStockItem) => {
                    if (item.images?.length) {
                      return <Images images={item.images} icon />;
                    }
                    if (item?.product?.images?.length) {
                      return <Images images={item.product.images} icon />;
                    }
                    return null;
                  },
                  warning: (item: typeStockItem) =>
                    Boolean(
                      item.quantity &&
                        ((item?.vehicle && !item.images?.length) ||
                          (!item?.vehicle && !item?.product?.images?.length)),
                    ),
                },
                {
                  title: 'Склад',
                  extract: (item: typeStockItem) => (
                    <Link
                      target="_blank"
                      href={`/warehouses/${item?.warehouse?.id}`}
                    >
                      {item?.warehouse?.name}
                    </Link>
                  ),
                },
                {
                  title: 'Владелец',
                  extract: (item: typeStockItem) => (
                    <Link
                      target="_blank"
                      href={`/suppliers/${item?.owner?.id}`}
                    >
                      {item?.owner?.name}
                    </Link>
                  ),
                },
                {
                  title: 'Авто',
                  extract: (item: typeStockItem) => (
                    <Link
                      className={classes.vehicle}
                      target="_blank"
                      href={`/vehicles/${item.vehicle?.id}`}
                    >
                      {item.vehicle?.name}
                    </Link>
                  ),
                },
              ]}
              loading={loading}
              onCancel={onCancel}
              onFetch={onFetch}
              rowClassNames={(item: typeStockItem) =>
                item.quantity ? '' : classes.outOfStock
              }
              limit={limit}
              offset={offset}
              actions={[
                ...actions,
                () =>
                  withOutOfStock && (
                    <Button
                      title="Наличие товара"
                      onClick={() =>
                        setZeroQuantity(
                          zeroQuantity === 'all'
                            ? 'inStock'
                            : zeroQuantity === 'inStock'
                            ? 'outOfStock'
                            : 'all',
                        )
                      }
                    >
                      {zeroQuantity === 'inStock' && '>0'}
                      {zeroQuantity === 'all' && 'all'}
                      {zeroQuantity === 'outOfStock' && '0'}
                    </Button>
                  ),
                ({ selectedItems }) => (
                  <>
                    <IconButton onClick={() => setEditDialog(true)}>
                      <Edit />
                    </IconButton>
                    <Dialog
                      fullWidth
                      maxWidth="sm"
                      open={editDialog}
                      onClose={() => setEditDialog(false)}
                    >
                      <DialogContent>
                        Выбрано товаров: {selectedItems.length}
                        <Box mt={2}>
                          <WarehouseSelect
                            warehouse={warehouse}
                            onChange={value => setWarehouse(value)}
                          />
                        </Box>
                        <Box mt={2}>
                          <WarehousePlaceSelect
                            warehouse={warehouse}
                            place={warehousePlace}
                            onChange={value => setPlace(value)}
                          />
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setEditDialog(false)}>
                          Закрыть
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            !selectedItems.length ||
                            !warehouse ||
                            !warehousePlace ||
                            batchChangeRequest.loading
                          }
                          onClick={() => handleBatchChange(selectedItems)}
                        >
                          Сохранить
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ),
                ({ selectedItems }) => (
                  <>
                    <IconButton
                      onClick={() =>
                        selectedItems?.length
                          ? router.push(
                              `/labels-print?ids=${selectedItems
                                ?.map((item: typeStockItem) => item.id)
                                .join(',')}`,
                            )
                          : setLabelsPritDialog(true)
                      }
                    >
                      <Print />
                    </IconButton>
                    <Dialog
                      fullScreen
                      open={labelsPritDialog}
                      onClose={() => {
                        setLabelsPritDialog(false);
                        setLabelsCount(0);
                        setShowLabels(false);
                      }}
                    >
                      {!selectedItems.length && !showLabels && (
                        <div>
                          <Box mt={2}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Код первого стикера"
                              value={labelsStart}
                              onChange={e =>
                                setLabelsStart(parseInt(e.target.value, 10))
                              }
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Количество стикеров"
                              value={labelsCount}
                              onChange={e =>
                                setLabelsCount(parseInt(e.target.value, 10))
                              }
                            />
                          </Box>
                          <Box mt={2}>
                            <Button
                              fullWidth
                              target="_blank"
                              variant="contained"
                              color="primary"
                              href={`/labels-print?labelsCount=${labelsCount}&labelsStart=${labelsStart}`}
                            >
                              Сгенерировать
                            </Button>
                          </Box>
                        </div>
                      )}
                      <LabelsPrint data={getLabels(selectedItems)} />
                    </Dialog>
                  </>
                ),
              ]}
            />
          </Paper>
        )}
        <Box display="flex" flexWrap="wrap">
          {onlyImages &&
            list?.map(
              (item: typeStockItem) =>
                Boolean(item?.images?.length) && (
                  <Link
                    target="_blank"
                    href={`/stock-items/${item.id}`}
                    title={item?.product?.nameValue || ''}
                  >
                    <Box
                      m={1}
                      key={item.id}
                      width="100px"
                      height="100px"
                      style={{
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundImage: item.images
                          ? `url(${item?.images[0].publicPath}?h=100)`
                          : '',
                      }}
                    ></Box>
                  </Link>
                ),
            )}
        </Box>
      </Box>
    </div>
  );
};
