import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField, CircularProgress } from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';

type typeProps = {
  options: any[];
  getOptionLabel?: (item: any) => any;
  onChangeTextField?: (item: any) => any;
  label?: string;
  inputValue?: string;
  loading?: boolean;
  clearOnBlur?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
  renderTags?: any;
  filterOptions?: (options: any[], state: any) => any[];
  onChange?: (e: any, value: any) => any;
  renderOption?: (options: any, value: any) => any;
  value?: any;
  fullWidth?: boolean;
  disableCloseOnSelect?: boolean;
  actions?: React.ReactNode[];
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'Autocomplete' },
);

export const Autocomplete: React.FunctionComponent<typeProps> = ({
  options,
  getOptionLabel,
  onChangeTextField,
  label,
  loading,
  renderTags,
  value,
  onChange,
  fullWidth,
  clearOnBlur,
  inputValue,
  disableClearable,
  filterOptions,
  renderOption,
  disabled,
  actions = [],
  disableCloseOnSelect
}) => {
  const classes = useStyles({});

  return (
    <MuiAutocomplete
      disabled={disabled}
      disableClearable={disableClearable}
      fullWidth={fullWidth}
      value={value}
      inputValue={inputValue}
      clearOnBlur={clearOnBlur}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      filterOptions={filterOptions}
      renderTags={renderTags}
      loading={loading}
      renderInput={(params: any) => (
        <TextField
          {...params}
          onChange={onChangeTextField}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {actions}
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
