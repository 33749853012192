import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import {
  typeStockItem,
  typeProduct,
  typeVehicle,
  typeWarehouse,
  typeWarehousePlace,
  typeListObject,
  typeSupplier,
} from 'src/types';
import { useRequest } from 'src/hooks/useRequest';

export function useCreateStockItem() {
  return useRequestWithPayload<
    typeStockItem,
    {
      id?: number;
      product?: Partial<typeProduct> | null;
      warehouse: Partial<typeProduct> | null;
      quantity: number;
      title?: string | null;
      vehicle?: Partial<typeVehicle> | null;
      owner?: Partial<typeSupplier> | null;
      startPrice?: number | null;
      fixedPrice?: number | null;
    }
  >({
    url: '/stock-items',
    method: 'post',
  });
}

export function useBatchCreateStockItem() {
  return useRequestWithPayload<
    typeStockItem,
    {
      batchQuantity?: number;
      product?: Partial<typeProduct> | null;
      warehouse: Partial<typeProduct> | null;
      quantity: number;
      title?: string | null;
      vehicle?: Partial<typeVehicle> | null;
      owner?: Partial<typeSupplier> | null;
      startPrice?: number | null;
      fixedPrice?: number | null;
    }
  >({
    url: '/stock-items/batch',
    method: 'post',
  });
}

export function useChangeStockItem() {
  return useRequestWithPayload<
    typeStockItem,
    {
      id: number;
      warehousePlace?: typeWarehousePlace | null;
      vehicle?: typeVehicle | null;
    }
  >({
    url: '/stock-items',
    method: 'patch',
  });
}

export function useGetStockItem(
  {
    variables,
    skip,
  }: {
    variables: { stockItemId?: number | string };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeStockItem>({
    url: `/stock-items/${variables.stockItemId}`,
    method: 'get',
    skip,
  });
}

export function useGetStockItems(
  {
    variables,
    skip,
  }: {
    variables: {
      limit?: number;
      offset?: number;
      typeId?: string | number;
      bodyId?: string | number;
    };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeListObject<typeStockItem>>({
    url: `/stock-items`,
    method: 'get',
    skip,
    query: variables,
  });
}

export function useBatchChangeStockItem() {
  return useRequestWithPayload<
    typeStockItem,
    {
      stockItems: typeStockItem[];
      warehousePlace?: typeWarehousePlace | null;
      warehouse?: typeVehicle | null;
    }
  >({
    url: '/stock-items/batch',
    method: 'patch',
  });
}
