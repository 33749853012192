import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Attribute, Product } from 'src/shared/api';
import { CharacteristicsEdit } from './CharacteristicsEdit';
import { setCharacteristicToProduct } from 'src/entities/product';

interface typeProps {
  product?: Product;
  onSave: () => any;
  attributes?: Attribute[];
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductCharacteristicsEdit' },
);

export const ProductCharacteristicsEdit: React.FunctionComponent<typeProps> = ({
  product,
  onSave,
  attributes
}) => {
  const classes = useStyles({});

  const handleChange = (
    attributeId: number,
    value: string | number | boolean,
  ) => {
    if (product) {
      setCharacteristicToProduct({
        productId: product?.id,
        attributeId,
        value: { value },
      }).then(onSave);
    }
  };

  return (
    <CharacteristicsEdit
      onChange={handleChange}
      onSave={onSave}
      characteristics={product?.characteristics}
      attributes={attributes}
    />
  );
};
