import { useRequest } from 'src/hooks/useRequest';
import { typeRestyle, typeListObject, typeBody } from 'src/types';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';

export function useGetRestyles({
  variables,
  skip,
}: {
  variables: { bodyId?: number | null; limit?: number; offset?: number };
  skip?: boolean;
}) {
  return useRequest<typeListObject<typeRestyle>, typeof variables>({
    url: '/restyles',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useCreateRestyle() {
  return useRequestWithPayload<
    typeRestyle,
    { index: number; body: typeBody; yearStart: number; yearEnd?: number | null }
  >({
    url: '/restyles',
    method: 'post',
  });
}
