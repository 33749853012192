import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box } from '@material-ui/core';
import { useGetModels } from 'src/api/modelApi';
import { useGetBrand } from 'src/api/brandApi';
import { Models } from 'src/components/models';
import { ModelCreate } from 'src/components/model-create';
import { useRouter } from 'src/hooks/useRouter';
import { typeBrand } from 'src/types';
import { BrandSelect } from 'src/components/brand-select';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ModelsPage' },
);

export const ModelsPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { query, push } = useRouter();

  const { data: brand } = useGetBrand({
    variables: {
      brandId: query.brandId,
    },
    skip: !query.brandId,
  });

  const variables = {
    limit: parseInt(query.limit, 10) || 5,
    offset: parseInt(query.offset, 10) || 0,
    brandId: query.brandId,
  };

  const { data, loading, refetch, cancel } = useGetModels({
    variables,
    skip: !query.brandId,
  });

  const handleOnFetch = (data?: { limit: number; offset: number }) => {
    if (data) {
      return push('/models', { ...query, ...data });
    }
    refetch({ ...variables, ...query });
  };

  return (
    <div className={classes.root}>
      <BrandSelect
        isCarManufacturer
        brand={brand}
        onChange={(value: typeBrand | null) => {
          const brandId = value?.id;
          push('/models', { brandId });
        }}
      />

      <Box mt={2}>
        <Models
          models={data}
          onFetch={handleOnFetch}
          onCancel={cancel}
          loading={loading}
          limit={variables.limit}
          offset={variables.offset}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <ModelCreate brand={brand} onCreate={() => refetch(variables)} />
      </Box>
    </div>
  );
};
