import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router';
import { useGetProductType, deleteЕargetType } from 'src/api/productTypeApi';
import { Table } from '../common/table';
import { orderBy } from 'lodash';
import { typeProduct, typeProductType, typeProductTypeAlias } from 'src/types';
import { ProductListItem } from '../ProductListItem';
import { ProductTargetTypeCreate } from '../ProductTargetTypeCreate';
import { ProductTypeAliasAdd } from '../ProductTypeAliasAdd';
import { ProductTypeAliasDelete } from '../ProductTypeAliasDelete';
import { Close } from '@material-ui/icons';

import { AddAttributeDialog } from 'src/widgets/add-attribute-dialog';
import { Attributes } from 'src/widgets/attributes';
import { addAttributeToProductType } from 'src/entities/product-types';
import { Attribute } from 'src/shared/api';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductTypePage' },
);

export const ProductTypePage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const { typeId } = useParams<{ typeId: string }>();

  const productType = useGetProductType({ variables: { typeId } });

  const handleDelete = (typeId: number, targetTypeId: number) =>
    deleteЕargetType(typeId, targetTypeId)?.then(productType.refetch);

  const handleAddAttribute = (attribute: Attribute) =>
    addAttributeToProductType(typeId, { id: attribute.id }).then(
      productType.refetch,
    );

  return (
    <div className={classes.root}>
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h4">{productType?.data?.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Table
              actions={[
                () => (
                  <ProductTypeAliasAdd
                    type={productType.data}
                    onChange={() => productType.refetch({ typeId })}
                  />
                ),
              ]}
              loading={productType.loading}
              title="Синонимы"
              data={orderBy(productType?.data?.aliases || [], [
                'nameCanonical',
              ])}
              headers={[
                {
                  title: 'Название',
                  extract: (item: typeProductTypeAlias) => item?.name,
                },
                {
                  title: '',
                  extract: (item: typeProductTypeAlias) => item?.nameCanonical,
                },
                {
                  title: 'Действия',
                  extract: (item: typeProductTypeAlias) => (
                    <ProductTypeAliasDelete
                      alias={item}
                      onDelete={() => productType.refetch({ typeId })}
                    />
                  ),
                },
              ]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Table
              actions={[
                () => (
                  <ProductTargetTypeCreate
                    type={productType.data}
                    onChange={() => productType.refetch({ typeId })}
                  />
                ),
              ]}
              loading={productType.loading}
              title="Названия для экспорта"
              data={orderBy(productType?.data?.targetsTypes || [], [
                'nameCanonical',
              ])}
              headers={[
                {
                  title: 'Цель',
                  extract: (item: typeProductType) => item?.target?.name,
                },
                {
                  title: 'Название',
                  extract: (item: typeProductType) => item?.name,
                },
                {
                  title: 'Категрия',
                  extract: (item: typeProductType) => item?.category?.name,
                },
                {
                  title: 'Код категории',
                  extract: (item: typeProductType) => item?.category?.code,
                },
                {
                  title: 'Действия',
                  extract: (item: typeProductType) => (
                    <IconButton
                      title="Удалить"
                      onClick={() =>
                        productType?.data?.id &&
                        item?.id &&
                        handleDelete(productType?.data?.id, item?.id)
                      }
                    >
                      <Close />
                    </IconButton>
                  ),
                },
              ]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Box p={2}>
              <Box display="flex" justifyContent="flex-end">
                <AddAttributeDialog onAdd={handleAddAttribute} />
              </Box>
              <Attributes attibutes={productType.data?.attributes} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Table
              loading={productType.loading}
              title="Продукты"
              data={orderBy(productType?.data?.products || [], [
                'nameCanonical',
              ])}
              headers={[
                {
                  title: 'Товар',
                  extract: (item: typeProduct) => (
                    <ProductListItem product={item} />
                  ),
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
