import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import { request } from 'src/services/request';
import {
  typeOrderSource,
  typeListObject,
  typeOrder,
  typeOrderElement,
  typeOrderElementRetrun,
  typeOrderRealization,
  typeOrderToSupplier,
  typeOrderCustomerSource,
  typeCustomer,
  typeContractor,
} from 'src/types';

export function useGetOrders(
  {
    variables,
    skip,
  }: {
    variables: {
      limit?: number;
      offset?: number;
      stockItem?: number;
    };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeListObject<typeOrder>, typeof variables>({
    url: '/orders',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useGetOrder(
  {
    variables,
    skip,
  }: {
    variables: {
      orderId?: number | string;
    };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeOrder, typeof variables>({
    url: `/orders/${variables.orderId}`,
    method: 'get',
    skip,
  });
}

export function useCreateOrder() {
  return useRequestWithPayload<
    typeOrder,
    {
      orderSource: typeOrderSource | null;
      orderCustomerSource: typeOrderCustomerSource | null;
      customer: typeCustomer | null;
      contractor: typeContractor | null;
    }
  >({
    url: '/orders',
    method: 'post',
  });
}

export function useCreateOrderElement(
  {
    variables,
  }: {
    variables: {
      orderId?: number | string;
    };
  } = { variables: {} },
) {
  return useRequestWithPayload<typeOrder, any>({
    url: `/orders/${variables.orderId}/elements`,
    method: 'post',
  });
}

export function useChangeOrderElement(
  {
    variables,
  }: {
    variables: {
      id?: number | string;
    };
  } = { variables: {} },
) {
  return useRequestWithPayload<typeOrderElement, any>({
    url: `/orders/elements/${variables.id}`,
    method: 'patch',
  });
}

export function useChangeOrderToSupplierElement(
  {
    variables,
  }: {
    variables: {
      id?: number | string;
    };
  } = { variables: {} },
) {
  return useRequestWithPayload<typeOrderElement, any>({
    url: `/orders/supplier-orders/elements/${variables.id}`,
    method: 'patch',
  });
}

export function useCreateOrderRealization(
  {
    variables,
  }: {
    variables: {
      orderId?: number | string;
    };
  } = { variables: {} },
) {
  return useRequestWithPayload<typeOrderRealization, any>({
    url: `/orders/${variables.orderId}/realizations`,
    method: 'post',
  });
}

export function useCreateOrderToSupplier(
  {
    variables,
  }: {
    variables: {
      orderId?: number | string;
    };
  } = { variables: {} },
) {
  return useRequestWithPayload<typeOrderToSupplier, any>({
    url: `/orders/${variables.orderId}/supplier-orders`,
    method: 'post',
  });
}
export function deleteOrderElementReturn(id: number | string) {
  return request.delete(`/orders/elements/${id}`)
}

export function useCreateOrderElementReturn(
  {
    variables,
  }: {
    variables: {
      orderId?: number | string;
    };
  } = { variables: {} },
) {
  return useRequestWithPayload<typeOrderElementRetrun, any>({
    url: `/orders/${variables.orderId}/elements-return`,
    method: 'post',
  });
}

export function useChangeOrder(
  {
    variables,
  }: {
    variables: {
      orderId?: number | string;
    };
  } = { variables: {} },
) {
  return useRequestWithPayload<typeOrderElementRetrun, any>({
    url: `/orders/${variables.orderId}`,
    method: 'patch',
  });
}

export function useGetOrdersSources() {
  return useRequest<typeOrderSource[]>({
    url: '/orders/sources',
    method: 'get',
  });
}

export function useGetOrdersCustomersSources() {
  return useRequest<typeOrderCustomerSource[]>({
    url: '/orders/customers-sources',
    method: 'get',
  });
}
