import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Button, TextField, Box } from '@material-ui/core';
import { signIn } from 'src/api/userApi';
import { useHistory } from 'react-router-dom';

type typeProps = {
  redirect?: string;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        maxWidth: '300px',
      },
      message: {
        minHeight: '50px',
      },
    }),
  { name: 'LoginForm' },
);

export const LoginForm: React.FunctionComponent<typeProps> = ({ redirect }) => {
  const classes = useStyles({});

  const history = useHistory();

  const [state, setState] = useState({
    username: '',
    password: '',
  });

  const [error, setError] = useState<null | string>();

  const onSignIn = () =>
    signIn(state).then(
      () => {
        if (redirect === '/login') {
          history.push('/vehicles');
        }
        redirect && history.push(redirect);
      },
      res => setError(res.message),
    );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="90vh"
    >
      <div className={classes.root}>
        <TextField
          variant="outlined"
          fullWidth
          label="Логин"
          value={state.username}
          onChange={e => setState({ ...state, username: e.target.value })}
        />
        <Box mt={2}>
          <TextField
            variant="outlined"
            fullWidth
            label="Пароль"
            value={state.password}
            type="password"
            onChange={e => setState({ ...state, password: e.target.value })}
          />
        </Box>
        <Box mt={2}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            onClick={onSignIn}
          >
            Войти
          </Button>
        </Box>
        <Box
          mt={2}
          className={classes.message}
          display="flex"
          justifyContent="center"
        >
          {error}
        </Box>
      </div>
    </Box>
  );
};
