import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Chip, Link, Paper, Theme } from '@material-ui/core';
import { Table } from 'src/components/common/table';
import { OrderCreate } from 'src/components/OrderCreate';
import { typeOrder } from 'src/types';
import { format } from 'date-fns';
import { green, red } from '@material-ui/core/colors';

interface typeProps {
  orders?: typeOrder[] | null;
  totalCount?: number;
  limit?: number;
  offset?: number;
  loading: boolean;
  refetch?: any;
  withPagination?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'Orders' },
);

export const Orders: React.FunctionComponent<typeProps> = ({
  orders,
  totalCount,
  loading,
  refetch,
  limit,
  offset,
  withPagination = true,
}) => {
  const classes = useStyles({});

  const total =
    //@ts-ignore
    (orders?.reduce((prev, curr) => prev + curr?.totalCost, 0) || 0) / 100;

  return (
    <Paper>
      <Table
        withPagination={withPagination}
        actions={[() => <OrderCreate />]}
        withSummary
        title="Заказы"
        data={orders}
        totalCount={totalCount}
        headers={[
          {
            title: 'id',
            extract: (item: typeOrder) => (
              <Link target="_blank" href={`/orders/${item?.id}`}>
                {item?.id}
              </Link>
            ),
            component: 'th',
            scope: 'row',
          },
          {
            title: 'Дата',
            extract: (item: typeOrder) => (
              <span>
                {item?.createdAt &&
                  format(new Date(item?.createdAt), 'yyyy-MM-dd HH:mm:ss')}
              </span>
            ),

            scope: 'row',
          },
          {
            title: 'Клиент',
            extract: (item: typeOrder) => (
              <Box fontSize="12px">
                <div>
                  {item?.customer?.phone?.value &&
                    `+7${item?.customer?.phone?.value}`}
                </div>
                <div>{item?.customer?.fullName}</div>
              </Box>
            ),

            scope: 'row',
          },
          {
            title: 'Источник',
            extract: (item: typeOrder) => (
              <Box fontSize="12px">
                <div>{item?.orderCustomerSource?.name}</div>
                {item?.orderSource?.id === 5 ? (
                  <Chip label={item?.orderSource?.name} color="primary" />
                ) : (
                  <div>{item?.orderSource?.name}</div>
                )}
              </Box>
            ),

            scope: 'row',
          },
          {
            title: 'Продавец',
            extract: (item: typeOrder) => item.contractor?.name,

            scope: 'row',
          },
          {
            title: 'Сумма',
            align: 'right',
            extract: (item: typeOrder) => (
              <span>{item?.totalCost && item?.totalCost / 100} ₽</span>
            ),

            scope: 'row',
          },
          {
            title: 'Реализации / Возвраты',
            align: 'right',
            extract: (item: typeOrder) => (
              <Box fontSize="12px">
                {Boolean(item?.paymentsCost) && (
                  <div style={{ color: green[500] }}>
                    {item?.paymentsCost && item?.paymentsCost / 100} ₽
                  </div>
                )}
                {Boolean(item?.returnsCost) && (
                  <div style={{ color: red[500] }}>
                    {item?.returnsCost && item?.returnsCost / 100} ₽
                  </div>
                )}
              </Box>
            ),

            summary: total,

            scope: 'row',
          },
          //   {
          //     title: 'Название',
          //     extract: (item: typeSupplier) => item?.name,
          //   },
          //   {
          //     title: 'alias',
          //     extract: (item: typeSupplier) => item?.alias,
          //   },
          //   {
          //     title: 'Наценки',
          //     extract: (item: typeSupplier) => item?.markupRanges?.length,
          //   },
        ]}
        loading={loading}
        onFetch={refetch}
        limit={limit}
        offset={offset}
      />
    </Paper>
  );
};
