import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import {
  typeCustomer,
  typeDelivery,
  typeDeliveryCompany,
  typeListObject,
} from 'src/types';

export function useGetDeliveryCompanies({
  variables,
  skip,
}: {
  variables?: {};
  skip?: boolean;
}) {
  return useRequest<typeListObject<typeDeliveryCompany>, typeof variables>({
    url: '/delivery-companies',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useCreateDeliveryCompany() {
  return useRequestWithPayload<
    typeDeliveryCompany,
    {
      name: string | null;
    }
  >({
    url: '/delivery-companies',
    method: 'post',
  });
}

export function useGetDeliveries({
  variables,
  skip,
}: {
  variables?: {
    customerId?: number | string;
    limit?: number;
    offset?: number;
    finishedAt?: string | boolean
  };
  skip?: boolean;
}) {
  return useRequest<typeListObject<typeDelivery>, typeof variables>({
    url: '/deliveries',
    method: 'get',
    query: variables,
    skip,
  });
}
export function useGetDelivery(
  {
    variables,
    skip,
  }: {
    variables: {
      deliveryId?: number | string;
    };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeDelivery, typeof variables>({
    url: `/deliveries/${variables.deliveryId}`,
    method: 'get',
    skip,
  });
}

export function useCreateDelivery() {
  return useRequestWithPayload<typeDelivery, typeDelivery>({
    url: '/deliveries',
    method: 'post',
  });
}

export function useChangeDelivery() {
  return useRequestWithPayload<typeDelivery, typeDelivery>({
    url: '/deliveries',
    method: 'patch',
  });
}
