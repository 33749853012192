import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { typeBody, typeRestyle } from 'src/types';
import { useGetRestyles } from 'src/api/restylesApi';

type typeProps = {
  onChange?: (restyle: typeRestyle | null) => any;
  body?: typeBody | null;
  restyle?: typeRestyle | null;
  disabled?: boolean;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'RestyleSelect' },
);

export const RestyleSelect: React.FunctionComponent<typeProps> = ({
  onChange,
  restyle,
  body,
  disabled,
}) => {
  const classes = useStyles({});

  const [value, setValue] = useState<typeRestyle | null>(null);

  const { data } = useGetRestyles({
    variables: { bodyId: body?.id },
    skip: !body,
  });

  useEffect(() => {
    const valueFromArray =
      data && restyle
        ? (data?.data.find(item => item.id === restyle.id) as typeRestyle)
        : null;
    setValue(valueFromArray);
  }, [restyle, data]);

  const handleOnChange = (next: typeRestyle | null) => {
    setValue(next);
    if (onChange) {
      onChange(next);
    }
  };

  return (
    <Autocomplete
      disabled={!body || disabled}
      value={value}
      onChange={(e, value) => handleOnChange(value)}
      options={data?.data || []}
      getOptionLabel={(option: typeRestyle) => option.name}
      renderInput={(params: any) => (
        <TextField {...params} label="Рестайлинг" variant="outlined" />
      )}
    />
  );
};
