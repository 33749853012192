import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogActions,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { typeBody } from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';
import { useCreateEngine } from 'src/api/engineApi';

type typeProps = {
  body: typeBody | null;
  onCreate: () => any;
};

type typeState = {
  dialog: boolean;
  name: string | null;
  body: typeBody | null;
};

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'EngineCreate',
});

export const EngineCreate: React.FunctionComponent<typeProps> = ({
  body,
  onCreate,
}) => {
  const classes = useStyles({});

  const [state, changeState] = useState<typeState>({
    dialog: false,
    name: '',
    body: null,
  });

  const setState = (data: Partial<typeState>) =>
    changeState((prevState: typeState) => ({ ...prevState, ...data }));

  useEffect(() => setState({ body }), [body]);

  const [create, { loading }] = useCreateEngine();

  const handleCreate = () => {
    if (state.body && state.name) {
        create({ name: state.name, body: state.body }).then(() => {
        setState({ dialog: false, name: '' });
        onCreate();
      });
    }
  };

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setState({ dialog: true })}>
        <Add />
      </Fab>
      <Dialog open={state.dialog} onClose={() => setState({ dialog: false })}>
        <DialogContent>
          <TextField
            label="Двигатель"
            autoFocus
            onChange={e => setState({ name: e.target.value })}
            value={state.name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState({ dialog: false })}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={!state.body || !state.name}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
