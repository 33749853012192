import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { LabelsPrint } from '../labels-print';
import { useRouter } from 'src/hooks/useRouter';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'center',
      },
      printLabelItem: {
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      code: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: theme.typography.pxToRem(20),
      },
      onlyCode: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: theme.typography.pxToRem(48),
      },
      vehicle: {
        fontSize: theme.typography.pxToRem(10),
      },
      description: {
        maxWidth: '200px',
      },
      big: {
        fontSize: theme.typography.pxToRem(48),
      },
    }),
  { name: 'LabelsPrintPage' },
);

export const LabelsPrintPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const { query } = useRouter();

  const selectedItems = query.ids?.split(',');
  const { labelsCount, labelsStart } = query;

  const getLabels = () => {
    if (selectedItems?.length) {
      return selectedItems
        ?.sort((a: string, b: string) => parseInt(a) - parseInt(b))
        .map((item: string, index: number) => (
          <div className={classes.printLabelItem} key={index}>
            <div>
              <div className={classes.onlyCode}>
                <div>
                  <b>{item}</b>
                </div>
              </div>
            </div>
          </div>
        ));
    }

    if (labelsCount) {
      return Array(parseInt(labelsCount, 10))
        .fill(null)
        .map((item, index) => (
          <div className={classes.printLabelItem} key={index}>
            <div>
              <div className={classes.onlyCode}>
                <div>
                  <b className={classes.big}>
                    {parseInt(labelsStart, 10) == 0 ? '' : index + parseInt(labelsStart, 10)}
                  </b>
                </div>
              </div>
            </div>
          </div>
        ));
    }

    return [];
  };

  return (
    <div className={classes.root}>
      <LabelsPrint data={getLabels()} />
    </div>
  );
};
