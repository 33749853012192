import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Box,
  InputAdornment,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { typePhoneNumber } from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';
import { useCreateCustomer } from 'src/api/customerApi';
import NumberFormat from 'react-number-format';

type typeProps = {
  onCreate: (value?: any) => any;
  phoneNumber?: string | null;
};

type typeState = {
  firstName?: string | null;
  middleName?: string | null;
  secondName?: string | null;
  organizationName?: string | null;
  phone?: typePhoneNumber | null;
};

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'CustomerCreateDialog',
});

const initState = {
  firstName: null,
  middleName: null,
  secondName: null,
  organizationName: null,
  phone: null,
};

export const CustomerCreateDialog: React.FunctionComponent<typeProps> = ({
  onCreate,
  phoneNumber,
}) => {
  const classes = useStyles({});

  const [open, setOpen] = useState(false);
  const [state, changeState] = useState<typeState>(initState);

  const setState = (data: Partial<typeState>) =>
    changeState((prevState: typeState) => ({ ...prevState, ...data }));

  useEffect(() => {
    if (phoneNumber) {
      setState({
        phone: { value: phoneNumber },
      });
    }
  }, [phoneNumber]);

  const [create, { loading }] = useCreateCustomer();

  const handleCreate = () => {
    if (state.phone) {
      create(state).then(() => {
        setOpen(false);
        changeState(initState);
        onCreate({ phone: state.phone?.value });
      });
    }
  };

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setOpen(!open)} size="small">
        <Add />
      </Fab>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <NumberFormat
            customInput={TextField}
            format="(###) ### ## ##"
            mask="_"
            variant="outlined"
            label="Телефон"
            InputLabelProps={{ shrink: true }}
            id="phone"
            fullWidth
            placeholder="(___) __ __ __"
            value={state.phone?.value || ''}
            onValueChange={e =>
              setState({ phone: { value: state.phone?.value } })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+7</InputAdornment>
              ),
            }}
          />
          <Box mt={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Фамилия"
              autoFocus
              onChange={e => setState({ secondName: e.target.value })}
              value={state.secondName}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Имя"
              onChange={e => setState({ firstName: e.target.value })}
              value={state.firstName}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Отчество"
              onChange={e => setState({ middleName: e.target.value })}
              value={state.middleName}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Организация"
              onChange={e => setState({ organizationName: e.target.value })}
              value={state.organizationName}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={!state.phone}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
