import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Theme,
} from '@material-ui/core';
import { typeProductType, typeProductTypeAlias } from 'src/types';
import { ProductTypeAliasSelect } from './ProductTypeAliasSelect';
import { Add } from '@material-ui/icons';
import { WithLoading } from './common/with-loading';
import { useAddProductTypeAlias } from 'src/api/productTypeApi';

interface typeProps {
  type?: typeProductType | null;
  onChange?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductTypeAliasAdd' },
);

export const ProductTypeAliasAdd: React.FunctionComponent<typeProps> = ({
  type,
  onChange,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);

  const [add, addData] = useAddProductTypeAlias({
    variables: { typeId: type?.id },
  });

  const [alias, setAlias] = useState<typeProductTypeAlias | null>(null);

  const [aliasName, setAliasName] = useState<string | null>(null);

  const handleCreate = () => {
    add({ alias, newAliasName: aliasName }).then(() => {
      if (onChange) onChange();
      setDialog(false);
      setAlias(null);
      setAliasName(null);
    });
  };

  return (
    <div className={classes.root}>
      <Fab size="small" color="primary" onClick={() => setDialog(true)}>
        <Add />
      </Fab>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <ProductTypeAliasSelect
            alias={alias}
            onSelect={alias => setAlias(alias)}
            onChange={aliasName => setAliasName(aliasName)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Закрыть</Button>
          <WithLoading loading={addData.loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={addData.loading || (!alias && !aliasName)}
            >
              Добавить
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
};
