import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';

import { useGetCoutries } from 'src/entities/country';

import { Brand, Series } from 'src/shared/api';
import { useGetBrandSeries } from 'src/entities/series';
import { SeriesCreate } from './SeriesCreate';

interface typeProps {
  onChange?: (value: any) => any;
  series?: Series | null;
  brand: Brand;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'SeriesSelect' },
);

export const SeriesSelect: React.FunctionComponent<typeProps> = ({
  onChange,
  series,
  brand,
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState<Series | null>(null);

  const request = useGetBrandSeries({
    variables: { brandId: brand.id as number },
  });

  useEffect(() => {
    if (request.data && series) {
      setValue(
        request.data?.find((item: Series) => item.id === series.id) || null,
      );
    }
  }, [request.data, series]);

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <Box display="flex" alignItems="center">
      <Autocomplete
        fullWidth
        value={value}
        onChange={(e, value: any) => hangdeOnChange(value)}
        options={
          request.data?.sort((a: Series, b: Series) =>
            a.name > b.name ? 1 : -1,
          ) || []
        }
        getOptionLabel={(option: any) => option.name}
        label="Серия"
        loading={request.loading}
      />
      <Box ml={2}>
        <SeriesCreate brand={brand} onSave={request.refetch} />
      </Box>
    </Box>
  );
};
