import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Fab,
  Grid,
  IconButton,
  TextField,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { QuickSearch } from 'src/components/QuickSearch';
import { useCreateOrderElement } from 'src/api/ordersApi';
import { typeStockItem } from 'src/types';
import { StockItemListItem } from './StockItemListItem';
import { Add, Close } from '@material-ui/icons';

interface typeProps {
  orderId: number | string;
  onChange?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'OrderElementCreate' },
);

const initialState = {
  stockItem: null,
  searchArticle: null,
  requestedName: null,
  quantity: 1,
};

type State = {
  stockItem: typeStockItem | null;
  searchArticle: string | null;
  requestedName: string | null;
  quantity: number | null;
};

export const OrderElementCreate: React.FunctionComponent<typeProps> = ({
  orderId,
  onChange,
}) => {
  const classes = useStyles({});
  const [state, changeState] = useState<State>(initialState);

  const setState = (data: Partial<State>) => changeState({ ...state, ...data });

  const [create] = useCreateOrderElement({ variables: { orderId } });

  const handleCreate = () => {
    create({ ...state, order: { id: orderId } }).then(() => {
      if (onChange) onChange();
      changeState(initialState);
    });
  };

  const quantityError = Boolean(
    state.stockItem &&
      state.quantity &&
      state.stockItem?.quantity < state.quantity,
  );

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item sm={6} md={4}>
        {state.stockItem && (
          <Box mr={2} display="flex" alignItems="center">
            <StockItemListItem stockItem={state.stockItem} withPrice={false} />
            <IconButton onClick={() => setState({ stockItem: null })}>
              <Close />
            </IconButton>
          </Box>
        )}
        {!state.stockItem && (
          <QuickSearch
            onClick={stockItem => setState({ stockItem })}
            outOfStock={false}
            onlyElements={['stockItem', 'products']}
          />
        )}
      </Grid>
      <Grid item sm={3} md={2}>
        <Tooltip title="Количество больше допуступного" open={quantityError}>
          <TextField
            error={quantityError}
            type="number"
            label="Количество"
            variant="outlined"
            value={state.quantity}
            onChange={e => setState({ quantity: parseInt(e.target.value, 10) })}
          />
        </Tooltip>
      </Grid>
      <Grid item md={2}>
        <Fab
          color="primary"
          onClick={handleCreate}
          title="Добавить"
          disabled={!state.stockItem}
        >
          <Add />
        </Fab>
      </Grid>
    </Grid>
  );
};
