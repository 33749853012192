import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  TextField,
  Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { typeDeliveryCompany } from 'src/types';
import { WithLoading } from './common/with-loading';
import { useCreateDeliveryCompany } from 'src/api/deliveryApi';

interface typeProps {
  name?: string | null;
  onCreate?: (value?: typeDeliveryCompany) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'DeliveryCompanyCreate' },
);

export const DeliveryCompanyCreate: React.FunctionComponent<typeProps> = ({
  name: providedName,
  onCreate,
}) => {
  const classes = useStyles({});

  const [name, setName] = useState<string | null>(null);

  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    if (providedName) setName(providedName);
  }, [providedName]);

  const [create, createData] = useCreateDeliveryCompany();

  const handleCreate = () => {
    if (name) {
      create({ name }).then((res: typeDeliveryCompany) => {
        if (onCreate) onCreate(res);
        setDialog(false);
        setName(null);
      });
    }
  };

  return (
    <div className={classes.root}>
      <Fab size="small" color="primary" onClick={() => setDialog(true)}>
        <Add />
      </Fab>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            label="Название"
            autoFocus
            onChange={e => setName(e.target.value)}
            value={name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Закрыть</Button>
          <WithLoading loading={createData.loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={createData.loading || !name}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
};
