import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box, Typography, Fab } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { StockItem } from 'src/components/stock-item';
import { useGetStockItem } from 'src/api/stockItemApi';
import { WithLoading } from 'src/components/common/with-loading';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'StockItemPage' },
);

export const StockItemPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { stockItemId } = useParams<{ stockItemId: string }>();

  const { data, loading, refetch, cancel } = useGetStockItem({
    variables: { stockItemId },
  });

  return (
    <WithLoading loading={loading} onCancel={cancel}>
      <div className={classes.root}>
        <Box display="flex" justifyContent="space-between">
          <Fab
            color="primary"
            href={`/stock-items/${parseInt(stockItemId, 10) - 1}`}
            title="Предыдущий товар"
          >
            <ChevronLeft />
          </Fab>
          <Fab
            color="primary"
            href={`/stock-items/${parseInt(stockItemId, 10) + 1}`}
            title="следующий товар"
          >
            <ChevronRight />
          </Fab>
        </Box>
        <Box mt={2}>
          <Typography variant="h4">{data?.itemNameWithPosition}</Typography>
        </Box>
        <Box mt={2}>
          <StockItem
            stockItem={data}
            loading={loading}
            onChange={() => refetch({ stockItemId })}
          />
        </Box>
      </div>
    </WithLoading>
  );
};
