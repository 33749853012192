import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';
import { typeWarehousePlace, typeSupplier } from 'src/types';
import { useGetSuppliers } from 'src/api/suppliersApi';

type typeProps = {
  onChange?: (supplier: typeSupplier | null) => any;
  supplier?: typeSupplier | null;
};

export const SupplierSelect: React.FunctionComponent<typeProps> = ({
  onChange,
  supplier,
}) => {
  const [value, setValue] = useState<typeWarehousePlace | null>(null);

  const { data, loading } = useGetSuppliers();

  useEffect(() => {
    const valueFromArray =
      data && supplier
        ? (data?.data?.find(item => item.id === supplier.id) as typeSupplier)
        : null;
    setValue(valueFromArray);
  }, [supplier, data]);

  const handleOnChange = (next: typeSupplier | null) => {
    setValue(next);
    if (onChange) {
      onChange(next);
    }
  };

  return (
    <Box display="flex">
      <Autocomplete
        fullWidth
        loading={loading}
        value={value}
        onChange={(e, value) => handleOnChange(value)}
        options={data?.data || []}
        label="Владелец"
        getOptionLabel={(option: typeWarehousePlace) => option?.name || ''}
      />
    </Box>
  );
};
