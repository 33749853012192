import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { addAValueToStringAttribure } from 'src/entities/attribute';
import { Attribute } from 'src/shared/api';

interface typeProps {
  onSave?: () => any;
  attribute: Attribute;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'AttributeValueCreate' },
);

export const AttributeValueCreate: React.FunctionComponent<typeProps> = ({
  onSave,
  attribute,
}) => {
  const classes = useStyles({});

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSetValue = (e: ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  const handleSave = () => {
    if (value) {
      addAValueToStringAttribure(attribute.id, { value }).then(onSave);
    }
    handleClose();
  };

  return (
    <>
      <Fab size="small" onClick={handleOpen} color="primary">
        <Add />
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{attribute.name}</DialogTitle>
        <DialogContent>
          <TextField
            label="Значение"
            value={value}
            onChange={handleSetValue}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button disabled={!value} onClick={handleSave}>
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
