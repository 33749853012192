import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link, Paper, Theme } from '@material-ui/core';
import { useGetProductTypes } from 'src/api/productTypeApi';
import { Table } from '../common/table';
import { orderBy } from 'lodash';
import { typeProductType } from 'src/types';
import { ProductTypeCreate } from '../ProductTypeCreate';
import { ProductTypeDelete } from '../ProductTypeDelete';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductTypesPage' },
);

export const ProductTypesPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const productTypes = useGetProductTypes();

  return (
    <div className={classes.root}>
      <Paper>
        <Table
          title="Типы продуктов"
          actions={[
            () => <ProductTypeCreate onCreate={() => productTypes.refetch()} />,
          ]}
          headers={[
            {
              title: 'id',
              extract: (item: typeProductType) => (
                <Link target="_blank" href={`/product-types/${item.id}`}>
                  {item.id}
                </Link>
              ),
              component: 'th',
              scope: 'row',
            },
            {
              title: 'Название',
              extract: (item: typeProductType) => item?.name,
            },
            {
              title: 'Род',
              extract: (item: typeProductType) =>
                item?.gender && ['Он', 'Она', 'Оно', 'Они'][item?.gender - 1],
            },
            {
              title: 'Кол-во целей для экспорта',
              extract: (item: typeProductType) => item?.targetsTypesCount,
            },
            {
              title: 'Кол-во синонимов',
              extract: (item: typeProductType) => item?.aliasesCount,
            },
            {
              title: 'Действия',
              extract: (item: typeProductType) => (
                <ProductTypeDelete
                  type={item}
                  onDelete={() => productTypes.refetch()}
                />
              ),
            },
          ]}
          data={orderBy(productTypes?.data || [], ['name'])}
          loading={productTypes.loading}
        />
      </Paper>
    </div>
  );
};
