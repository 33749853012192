import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import QRCode from 'react-qr-code';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      cut: {
        margin: '8px',
        border: '1px solid red',
        borderRadius: '16px',
        backgroundColor: 'white',
        color: 'white',
        padding: '16px',
      },
      bg: {
        backgroundColor: '#159C52',
        borderRadius: '8px',
        padding: '16px',
      },
      qr: {
        backgroundColor: 'white',
        padding: '8px 8px 4px 8px',
      },
      title: {
        textAlign: 'center',
        fontSize: '40px',
      },
      caption: {
        fontSize: '22px',
      },
      codeCaption: {
        fontSize: '40px',
        color: 'black',
        fontWeight: 900,
      },
      code: {
        textAlign: 'center',
        fontSize: '40px',
        color: 'black',
        fontWeight: 900,
        letterSpacing: 2.5,
        lineHeight: 1,
        backgroundColor: 'white',
        padding: '8px',
      },
      url: {
        textAlign: 'center',
        color: 'black',
        fontSize: '30px',
        fontWeight: 900,
        textDecoration: 'underline',
      },
    }),
  { name: 'StickersPage' },
);

const codes = [
  'M7MB7',
  'Z7RS2',
  'W4NZ6',
  'O0FI0',
  'L6NA9',
  'O9UY4',
  'I3ZK7',
  'B1GL0',
  'C6SE5',
  'Z7ZN4',
  'H7NG7',
  'T4MR1',
  'Q8YL5',
  'C8NI9',
  'L2AW6',
  'V3IR1',
  'E6UG8',
  'X1XQ6',
  'O8YR1',
  'F9UM6',
  'R8GE0',
  'Q8SJ1',
  'W8IO8',
  'A8XY8',
  'D5TP9',
  'L4BS9',
  'V3TA8',
  'B3YK6',
  'T3IX6',
  'Q0AY1',
  'F2WA2',
  'P1IT3',
  'V2DO2',
  'I6NP7',
  'F3TS0',
  'N5FO1',
  'L2FT1',
  'T3JQ8',
  'D7EU8',
  'Q5CP5',
  'R2KE3',
  'O9LH3',
  'Y6CS4',
  'D4RL4',
  'A4QC8',
  'V5KO0',
  'M3TN4',
  'O2EP4',
  'W2IM7',
  'N9HT3',
  'M9NP4',
  'U1QR9',
  'N3HR9',
  'G4VH9',
  'J5NW9',
  'I5QS7',
  'J4NB9',
  'C6LW6',
  'T0YG0',
  'Z2DD8',
  'B3LG0',
  'Y4YU7',
  'O9FH6',
  'N7TL5',
  'H2PD3',
  'W6IK5',
  'F7UY5',
  'X6DS4',
  'S9DP6',
  'S0VF6',
  'I9UY9',
  'N6PU0',
  'X5XI1',
  'G2DU5',
  'D3JC6',
  'S0DF6',
  'Y9SP4',
  'E5ZC5',
  'V3TV3',
  'R4MD4',
  'W5RU9',
  'Q5YD9',
  'P5HI5',
  'M9OK2',
  'Q5CU5',
  'A7OV4',
  'F8SK1',
  'K0EF5',
  'H5MX2',
  'R1KB5',
  'T6XF3',
  'O6AS3',
  'G4MQ3',
  'H6WZ7',
  'S1QQ7',
  'N0OA5',
  'A3SG7',
  'F2WR5',
  'B9TJ2',
  'P8VI7',
  'A3WW3',
  'U6GO3',
  'D8QL0',
  'L1NF1',
  'L0SR0',
  'N8RC7',
  'H9AD7',
  'E3SM1',
  'C9BU3',
  'H0BD7',
  'A9CJ0',
  'L8FZ5',
  'A3VM1',
  'K5HB5',
  'G3HQ3',
  'C4AY8',
  'N0QM6',
  'K4PW9',
  'N3UC1',
  'C6FO8',
  'R2GN0',
  'R8ID7',
  'E0QJ0',
  'P7KB0',
  'H1IS6',
  'A6WO2',
  'Z0CT6',
  'G1EL4',
  'Z0JH0',
  'O5CG3',
  'V2DN9',
  'T7GZ3',
  'R5YU2',
  'B4VZ2',
  'U0OT5',
  'U5MI5',
  'B4EG6',
  'H1OS8',
  'F6VB0',
  'X0KP8',
  'R0NT9',
  'U3UQ5',
  'L2OV4',
  'Z9VX5',
  'U4RB7',
  'Q9WA6',
  'L8EQ9',
  'T2SC5',
  'A0XX7',
  'V0YT5',
  'W4OW8',
  'P4NS8',
  'W8LC0',
  'R2NU4',
  'D5LP4',
  'Q7JD0',
  'L1EA8',
  'L3RC3',
  'H3NU3',
  'Z2ZE8',
  'Y8UP9',
  'I1DS8',
  'J5FT5',
  'L6JQ7',
  'S7WC0',
  'Q6AZ0',
  'S4JQ0',
  'F7DK3',
  'Z8KP0',
  'F3OK5',
  'Z7NL3',
  'A4WQ1',
  'U4OH8',
  'F7BD9',
  'V3GE2',
  'I1TE4',
  'D1QP5',
  'X4JJ5',
  'P8QG8',
  'D0PI7',
  'E6HW3',
  'B2GN1',
  'I3GC6',
  'N8XU2',
  'C2ZU9',
  'E0HW2',
  'A8EB9',
  'V2JW6',
  'U6UY1',
  'J4XJ1',
  'A6KS7',
  'B4KY9',
  'E9IQ7',
  'B1QV0',
  'A8OY4',
  'J1GF9',
  'J9ZS2',
  'N8RB5',
  'W4LZ3',
  'V9AS6',
];

export const StickersPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      {codes.map(code => (
        <div className={classes.cut} key={code}>
          <div className={classes.bg}>
            <div className={classes.title}>Автосервис «Рестан»</div>
            <Box display="flex" justifyContent="space-between">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                mr={2}
              >
                <div className={classes.caption}>
                  Сервисная книжка
                  <br />
                  вашего автомобиля
                </div>
                <div>
                  <div className={classes.codeCaption}>КОД:</div>
                  <div className={classes.code}>{code}</div>
                </div>
              </Box>
              <div className={classes.qr}>
                <QRCode
                  size={180}
                  value={`https://restanservice.ru/sb?from=qr&hash=${code}`.toLocaleLowerCase()}
                />
              </div>
            </Box>

            <Box mt={2} fontSize="25px" textAlign="center">
              Отсканируйте QR-код телефоном
              <br />
              или используйте код на сайте
            </Box>
            <div className={classes.url}>restanservice.ru</div>
          </div>
        </div>
      ))}
    </div>
  );
};
