import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { Attribute, Characteristic } from 'src/shared/api';
import { useDebounce } from 'src/hooks/useDebounce';
import { AttributeValueCreate } from 'src/features/attribute-value-create';

interface typeProps {
  characteristics?: Characteristic[];
  attributes?: Attribute[] | null;
  onChange: (attributeId: number, value: string | number | boolean) => any;
  onSave: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'CharacteristicsEdit' },
);

const AttributeEdit = ({
  attribute,
  characteristic,
  onChange,
  onSave,
}: {
  attribute: Attribute;
  characteristic?: Characteristic | null;
  onChange: (attributeId: number, value: string | number | boolean) => any;
  onSave: () => any;
}) => {
  const [value, setValue] = useState<any>(null);

  const debouncedValue = useDebounce(value, 700);
  useEffect(() => {
    if (debouncedValue && debouncedValue !== characteristic?.value) {
      onChange(attribute.id, debouncedValue as string);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (typeof characteristic?.value === 'number') {
      setValue(characteristic?.value);
    }
  }, [characteristic]);

  if (attribute.type === 'numeric') {
    return (
      <Box display="flex" alignItems="center">
        <TextField
          fullWidth
          label={attribute.name}
          variant="outlined"
          onChange={e => setValue(e.target.value)}
          value={value}
        />
        {characteristic?.humanFriendlyValue && (
          <Box ml={2}>{characteristic?.humanFriendlyValue}</Box>
        )}
      </Box>
    );
  }
  if (attribute.type === 'string') {
    return (
      <Box display="flex" alignItems="center">
        <FormControl variant="outlined" fullWidth>
          <InputLabel id={attribute.name}>{attribute.name}</InputLabel>
          <Select
            label={attribute.name}
            variant="outlined"
            labelId={attribute.name}
            onChange={e => onChange(attribute.id, e.target.value as string)}
            //@ts-ignore
            value={characteristic?.value?.value}
          >
            {attribute?.listPossibleValues?.sort((a, b) => a.value > b.value ? 1 : -1)?.map(item => (
              <MenuItem value={item.value} key={item.id}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box ml={2}>
          <AttributeValueCreate attribute={attribute} onSave={onSave} />
        </Box>
      </Box>
    );
  }
  if (attribute.type === 'bool') {
    return (
      <Box display="flex" alignItems="center">
        <FormControlLabel
          checked={Boolean(characteristic?.value)}
          control={<Checkbox />}
          label={attribute.name}
          onChange={() => onChange(attribute.id, !characteristic?.value)}
        />
      </Box>
    );
  }
  return <>{attribute.name}</>;
};

export const CharacteristicsEdit: React.FunctionComponent<typeProps> = ({
  characteristics,
  onSave,
  onChange,
  attributes,
}) => {
  const classes = useStyles({});

  return (
    <Grid container spacing={2}>
      {attributes?.map(attribute => (
        <Grid key={attribute.id} item xs={3}>
          <AttributeEdit
            attribute={attribute}
            characteristic={characteristics?.find(
              characteristic => characteristic.attribute.id === attribute.id,
            )}
            onChange={onChange}
            onSave={onSave}
          />
        </Grid>
      ))}
    </Grid>
  );
};
