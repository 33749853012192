import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';

import { AttributeValueCreate } from 'src/features/attribute-value-create';

import { Attribute } from 'src/shared/api';
import { divide } from 'lodash';
interface typeProps {
  attibutes?: Attribute[];
  onChange?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'Attributes' },
);

export const Attributes: React.FunctionComponent<typeProps> = ({
  attibutes,
  onChange,
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Typography variant="h5">Атрибуты</Typography>
      {attibutes?.map(attribute => (
        <Box mt={2}>
          <Paper key={attribute.id}>
            <Box
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Box display="flex" alignItems="center">
                  {attribute.name}
                  <Box ml={2}>
                    <Chip label={attribute.type} />
                  </Box>
                </Box>
              </Box>
              {attribute.type === 'string' && (
                <AttributeValueCreate onSave={onChange} attribute={attribute} />
              )}
            </Box>
            {Boolean(attribute.listPossibleValues?.length) && (
              <List dense>
                {attribute.listPossibleValues?.map(stringValue => (
                  <ListItem key={stringValue.id} divider>
                    {stringValue.value}
                  </ListItem>
                ))}
              </List>
            )}

            <Divider />
          </Paper>
        </Box>
      ))}
    </div>
  );
};
