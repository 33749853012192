import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { Autocomplete } from './common/autocomplete';
import { typeDeliveryCompany } from 'src/types';
import { useGetDeliveryCompanies } from 'src/api/deliveryApi';
import { DeliveryCompanyCreate } from './DeliveryCompanyCreate';

interface typeProps {
  onChange?: (value: typeDeliveryCompany | null) => any;
  deliveryCompany?: typeDeliveryCompany | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'DeliveryCompanySelect' },
);

export const DeliveryCompanySelect: React.FunctionComponent<typeProps> = ({
  onChange,
  deliveryCompany,
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState<typeDeliveryCompany | null>(null);

  const request = useGetDeliveryCompanies({});

  useEffect(() => {
    if (deliveryCompany) {
      setValue(deliveryCompany);
    }
  }, [deliveryCompany]);

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <Box className={classes.root} display="flex" alignItems="center">
      <Autocomplete
        fullWidth
        value={value}
        onChange={(e, value: any) => hangdeOnChange(value)}
        options={request.data?.data || []}
        getOptionLabel={(option: any) => option.name}
        label="Транспортная компания"
        loading={request.loading}
      />
      <Box ml={1}>
        <DeliveryCompanyCreate onCreate={() => request.refetch()} />
      </Box>
    </Box>
  );
};
