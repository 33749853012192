import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Box,
  Paper,
  Link,
  Dialog,
  DialogContent,
  Button,
} from '@material-ui/core';
import {
  useGetPriceFiles,
  useGetWarehouses,
  useUploadPrice,
} from 'src/api/warehousesApi';
import { Table } from 'src/components/common/table';
import { typeWarehouse } from 'src/types';
import { ImageForm } from '../image-form';
import { format, formatDistance } from 'date-fns/esm';
import JSONPretty from 'react-json-pretty';
import { ru } from 'date-fns/locale';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'WarehousesPage' },
);

export const WarehousesPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const [reports, setReports] = useState<any>(null);

  const { data, loading, refetch, cancel } = useGetWarehouses({});
  const { data: files, refetch: refetchFiles } = useGetPriceFiles({});

  const [upoad] = useUploadPrice();

  const handleUploadPrice = (files: File[], alias?: string) => {
    const price = new FormData();

    if (!alias) return null;

    price.append('alias', alias);

    files.forEach((file, index) => price.append(`file[${index}]`, file));

    return upoad(price).then((res: any) => {
      refetch();
      refetchFiles();
      return res;
    });
  };

  return (
    <div className={classes.root}>
      <Box mt={2}>
        <Paper>
          <Table
            withSummary
            title="Склады"
            data={data?.data}
            totalCount={data?.totalCount}
            headers={[
              {
                title: 'Название',
                extract: (item: typeWarehouse) => (
                  <Link target="_blank" href={`/warehouses/${item.id}`}>
                    {item?.name}
                  </Link>
                ),
              },
              {
                title: 'alias',
                extract: (item: typeWarehouse) => item?.alias,
              },
              {
                title: 'Обновлен',
                extract: (item: typeWarehouse) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {files
                      ?.filter((file: any) =>
                        file?.warehouses.find(
                          (warehouse: any) => warehouse.id === item.id,
                        ),
                      )
                      ?.slice(0, 1)
                      ?.map((file: any) => (
                        <>
                          {formatDistance(
                            new Date(file?.supplierDispatchTime),
                            new Date(),
                            {
                              addSuffix: true,
                              locale: ru,
                              includeSeconds: true,
                            },
                          )}
                          <Button
                            size="small"
                            color="primary"
                            onClick={() =>
                              files
                                ?.filter((file: any) =>
                                  file?.warehouses.find(
                                    (warehouse: any) =>
                                      warehouse.id === item.id,
                                  ),
                                )
                                ?.forEach((item: any) =>
                                  setReports(
                                    reports
                                      ? [...reports, ...item.reports]
                                      : [...item.reports],
                                  ),
                                )
                            }
                          >
                            {file.statusParsing}
                          </Button>
                        </>
                      ))}
                  </Box>
                ),
              },
              {
                title: 'Обновить прайс-лист',
                extract: (item: typeWarehouse) =>
                  item?.alias && item?.alias.search('restan') === -1 ? (
                    <ImageForm
                      onAdd={files => handleUploadPrice(files, item?.alias)}
                      id={`attachVehicleImages_${item?.alias}`}
                    />
                  ) : null,
              },
            ]}
            loading={loading}
            onCancel={cancel}
            onFetch={refetch}
            limit={data?.data?.length || 0}
            offset={0}
          />
        </Paper>
        <Dialog open={Boolean(reports)} onClose={() => setReports(null)}>
          <DialogContent>
            <JSONPretty id="json-pretty" data={reports}></JSONPretty>
          </DialogContent>
        </Dialog>
      </Box>
    </div>
  );
};
