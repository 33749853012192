import { useRequest } from 'src/hooks/useRequest';
import { typeEngine, typeListObject, typeBody } from 'src/types';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';

export function useGetEngines({
    variables,
    skip,
  }: {
    variables: { bodyId?: number | null; limit?: number; offset?: number };
    skip?: boolean;
  }) {
    return useRequest<typeListObject<typeEngine>, typeof variables>({
      url: '/engines',
      method: 'get',
      query: variables,
      skip,
    });
  }

export function useCreateEngine() {
  return useRequestWithPayload<typeEngine, { name: string; body: typeBody }>({
    url: '/engines',
    method: 'post',
  });
}