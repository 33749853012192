import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Paper } from '@material-ui/core';
import { typeEngine, typeListObject } from 'src/types';
import { Table } from 'src/components/common/table';

type typeProps = {
  engines: typeListObject<typeEngine> | null;
  onFetch: (params?: { limit: number; offset: number }) => any;
  onCancel: any;
  loading: boolean;
  limit?: number;
  offset?: number;
};

type typeState = {
  rowsPerPage: number;
  page: number;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      title: {
        flex: '1 1 100%',
      },
    }),
  { name: 'Engines' },
);

export const Engines: React.FunctionComponent<typeProps> = ({
  engines,
  loading,
  onFetch,
  onCancel,
  limit,
  offset,
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Paper>
        <Table
          title="Двигатели"
          data={engines?.data}
          totalCount={engines?.totalCount}
          headers={[
            {
              title: 'id',
              extract: (item: typeEngine) => item.id,
              component: 'th',
              scope: 'row',
            },
            { title: 'Двигатель', extract: (item: typeEngine) => item.name },
          ]}
          loading={loading}
          onCancel={onCancel}
          onFetch={onFetch}
          limit={limit}
          offset={offset}
        />
      </Paper>
    </div>
  );
};
