import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
} from '@material-ui/core';
import { typeSupplier, typeSupplierMarkupRange } from 'src/types';
import { Check } from '@material-ui/icons';
import { settings } from 'cluster';
import { usePutMarkupRange } from 'src/api/suppliersApi';

type typeProps = {
  range?: typeSupplierMarkupRange | null;
  supplier?: typeSupplier | null;
  onChange?: () => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'MarkupRange' },
);

const onlyNumbersInt = (string: string) =>
  parseInt(string.replace(/\D/g, ''), 10) || 0;

export const MarkupRange: React.FunctionComponent<typeProps> = ({
  range,
  onChange,
  supplier,
}) => {
  const classes = useStyles({});

  const [price, setPrice] = useState(0);
  const [markup, setMarkup] = useState(0);

  const [put] = usePutMarkupRange();

  useEffect(() => {
    if (range) {
      setPrice(range.price / 100);
      setMarkup(range.markup / 100);
    }
  }, [range]);

  const handleSave = () => {
    if (supplier?.id) {
      put({
        ...range,
        price: price * 100,
        markup: markup * 100,
        supplier: { id: supplier.id },
      }).then(() => {
        setPrice(0);
        setMarkup(0);
        if (onChange) {
          onChange();
        }
      });
    }
  };

  return (
    <Grid
      className={classes.root}
      container
      spacing={4}
      title={`id ${range?.id}`}
    >
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          variant="outlined"
          value={price}
          label="Закупочная, ₽"
          onChange={e => setPrice(onlyNumbersInt(e.target.value))}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          variant="outlined"
          value={markup + price}
          label="Продажная, ₽"
          onChange={e => setMarkup(onlyNumbersInt(e.target.value) - price)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          variant="outlined"
          value={markup}
          label="Маржа, ₽"
          onChange={e => setMarkup(onlyNumbersInt(e.target.value))}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          variant="outlined"
          value={price ? Math.ceil(markup / (price / 100)) : ''}
          label="Наценка, %"
          onChange={e => {
            setMarkup(
              price
                ? (price / 100) * onlyNumbersInt(e.target.value)
                : markup,
            );
          }}
          InputProps={{
            endAdornment: ((range?.markup && range?.markup / 100) !== markup ||
              (range?.price && range?.price / 100) !== price) && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleSave()}
                  title="Сохранить"
                  color="secondary"
                  disabled={!markup}
                >
                  <Check />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
