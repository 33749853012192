import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  IconButton,
  Theme,
} from '@material-ui/core';
import { typeImage } from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';
import { Close, FileCopy, Image } from '@material-ui/icons';
// @ts-ignore
import ImageZoom from 'react-image-zoom';
import { useDeletemage } from 'src/api/imagesApi';
import { WithConfirm } from 'src/components/WithConfirm';
import { copyImageToClipboard } from 'copy-image-clipboard';

type typeProps = {
  images?: typeImage[] | null;
  onChange?: () => any;
  icon?: boolean;
  preview?: boolean;
  withDelete?: boolean;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      item: {
        width: '100px',
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        filter: 'contrast(100%)',
        transition: theme.transitions.create('filter'),
        '&:hover': {
          filter: 'contrast(150%)',
        },
      },
      itemContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 8px',
      },
      button: {
        marginTop: theme.spacing(2),
      },
      preview: {
        width: '100px',
        height: '100px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      image: {
        width: '100%',
        height: '600px',
      },
    }),
  { name: 'Images' },
);

export const Images: React.FunctionComponent<typeProps> = ({
  images,
  onChange,
  icon,
  preview,
  withDelete = true,
}) => {
  const classes = useStyles({});
  const [selectedImage, setSelectedImage] = useState<typeImage | null>(null);
  const [loadingImage, setLoadingImage] = useState<typeImage | null>(null);
  const [del] = useDeletemage();
  const [copied, setCopied] = useState<typeImage | null>(null);

  //   ${process.env.REACT_APP_API_HOST}

  const handleOnDelete = (image: typeImage) => {
    setLoadingImage(image);
    del(image).then(() => {
      setLoadingImage(null);
      if (onChange) {
        onChange();
      }
    });
  };

  const copyToClipboard = (image: typeImage) =>
    copyImageToClipboard(image.publicPath).then(() => setCopied(image));

  const renderImages = () =>
    images?.map(image => (
      <div key={image.id} className={classes.item}>
        <WithLoading loading={Boolean(loadingImage?.id === image.id)}>
          <div className={classes.itemContent}>
            <ButtonBase
              onClick={() => setSelectedImage(image)}
              className={classes.preview}
              style={{
                backgroundImage: `url(${image.publicPath}?w=100)`,
              }}
            />
            <Box display="flex" alignItems="center">
              <IconButton
                title="Копировать в буфер"
                onClick={() => copyToClipboard(image)}
                color={copied?.id === image.id ? 'primary' : 'default'}
              >
                <FileCopy />
              </IconButton>
              {withDelete && (
                <WithConfirm
                  onConfirm={() => handleOnDelete(image)}
                  confirmTitle="Удалить"
                >
                  <Button title="Удалить">Удалить</Button>
                </WithConfirm>
              )}
            </Box>
          </div>
        </WithLoading>
      </div>
    ));

  const renderDialog = () => (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={Boolean(selectedImage)}
      onClose={() => setSelectedImage(null)}
    >
      <DialogContent>
        <IconButton
          style={{ position: 'absolute', right: 40 }}
          color="secondary"
          onClick={() => setSelectedImage(null)}
        >
          <Close />
        </IconButton>
        <Box display="flex" justifyContent="center">
          {selectedImage && (
            <ImageZoom
              width={
                selectedImage?.width && selectedImage?.width > 940
                  ? 940
                  : selectedImage?.width
              }
              zoomPosition="original"
              img={selectedImage?.publicPath}
            />
          )}
        </Box>
        <Box mt={4} display="flex" justifyContent="center">
          {renderImages()}
        </Box>
      </DialogContent>
    </Dialog>
  );

  if (images && icon) {
    return (
      <div>
        {renderDialog()}
        <IconButton onClick={() => setSelectedImage(images[0])}>
          <Image color="action" />
        </IconButton>
      </div>
    );
  }
  if (images && preview) {
    return (
      <div>
        {renderDialog()}
        <IconButton onClick={() => setSelectedImage(images[0])}>
          <img src={`${images[0]?.publicPath}?w=40&h=40`} />
        </IconButton>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {renderDialog()}

      {renderImages()}
    </div>
  );
};
