import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { typeBody, typeEngine } from 'src/types';
import { useGetBodies } from 'src/api/bodyApi';
import { useGetEngines } from 'src/api/engineApi';

type typeProps = {
  onChange?: (body: typeEngine | null) => any;
  body?: typeBody | null;
  engine?: typeEngine | null;
  disabled?: boolean;
  all?: boolean;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      labelSub: {
        color: theme.palette.grey[400],
        fontSize: theme.typography.pxToRem(14),
      },
    }),
  { name: 'EngineSelect' },
);

export const EngineSelect: React.FunctionComponent<typeProps> = ({
  onChange,
  engine,
  body,
  disabled,
  all = false,
}) => {
  const classes = useStyles({});

  const [value, setValue] = useState<typeEngine | null>(null);

  const { data } = useGetEngines({
    variables: { bodyId: body?.id },
    skip: !all && !body,
  });

  useEffect(() => {
    const valueFromArray =
      data && engine
        ? (data?.data.find(item => item.id === engine.id) as typeEngine)
        : null;
    setValue(valueFromArray);
  }, [engine, data]);

  const handleOnChange = (next: typeEngine | null) => {
    setValue(next);
    if (onChange) {
      onChange(next);
    }
  };

  return (
    <Autocomplete
      disabled={(!all && !body) || disabled}
      value={value}
      onChange={(e, value) => handleOnChange(value)}
      options={data?.data?.sort((a, b) => (a.name > b.name ? 1 : -1)) || []}
      getOptionLabel={(option: typeEngine) => option.name}
      renderOption={(option: typeEngine) => (
        <div>
          <div>{option?.name}</div>
          <div className={classes.labelSub}>
            {option?.bodies?.reduce(
              (prev: string[], curr: typeBody) =>curr?.model?.brand?.originalName && 
                prev?.find(value => value === curr?.model?.brand?.originalName)
                  ? prev
                  : [...prev, curr.model?.brand?.originalName],
              [],
            )}{' '}
            {option?.bodies?.map(item => item.model?.name).join(', ')}
          </div>
        </div>
      )}
      renderInput={(params: any) => (
        <TextField {...params} label="Двигатель" variant="outlined" />
      )}
    />
  );
};
