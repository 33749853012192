import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Grid, Theme, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import {
  useGetBillingAccout,
  useGetBillingAccoutPosts,
} from 'src/api/billingApi';
import format from 'date-fns/format';
import { BillingJournal } from '../BillingJournal';
import { ContractorLabel } from '../ContractorLabel';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ru from 'date-fns/locale/ru';
import { endOfDay, startOfDay, startOfMonth } from 'date-fns';
import { useRouter } from 'src/hooks/useRouter';
import DateFnsUtils from '@date-io/date-fns';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'AccountPage' },
);

export const AccountPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});
  const { query, push } = useRouter();

  const { accountId } = useParams<{ accountId: string }>();

  const { data, loading, refetch, cancel } = useGetBillingAccout({
    variables: { accountId },
  });

  const postsRequestVariables = {
    accountId,
    from: query.from || format(startOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    to: query.to || format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
  };

  const postsRequest = useGetBillingAccoutPosts({
    variables: postsRequestVariables,
  });

  const handleRefetch = (params: any = {}) => {
    push(null, { ...postsRequestVariables, ...params });
  };

  return (
    <div className={classes.root}>
      <Box>
        <Typography variant="h4">
          {data?.name} <ContractorLabel contractor={data?.contractor} />
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h4">
          Баланс{' '}
          {data?.balance && (data?.balance / 100)?.toLocaleString('ru-RU')}{' '}
          {data?.currency}
        </Typography>
      </Box>
      <Box mt={2}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <DatePicker
                cancelLabel="Отмена"
                okLabel={null}
                autoOk
                label="От"
                format="yyyy-MM-dd"
                fullWidth
                inputVariant="outlined"
                value={new Date(postsRequestVariables.from)}
                onChange={date =>
                  handleRefetch({
                    // @ts-ignore
                    from: format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DatePicker
                cancelLabel="Отмена"
                okLabel={null}
                autoOk
                format="yyyy-MM-dd"
                fullWidth
                label="До"
                inputVariant="outlined"
                value={new Date(postsRequestVariables.to)}
                onChange={date =>
                  handleRefetch({
                    // @ts-ignore
                    to: format(endOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
                  })
                }
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        </Box>
      <Box mt={2}>
        <BillingJournal
          journal={postsRequest?.data?.data?.map(item => ({
            ...item.journal,
            posts: [item],
          }))}
        />
      </Box>
    </div>
  );
};
