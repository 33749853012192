import { request } from 'src/services/request';

export function setCharacteristicToProduct(params: {
  productId: number | string;
  attributeId: number | string;
  value: { value: string | number | boolean };
}) {
  return request.put(
    `/products/${params.productId}/characteristics/${params.attributeId}`,
    params.value,
  );
}
