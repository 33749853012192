import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  TextField,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { typeVehicle } from 'src/types';
import { useAddImages } from 'src/api/imagesApi';
import { ImageForm } from 'src/components/image-form';
import { BrandSelect } from 'src/components/brand-select';
import { ModelSelect } from 'src/components/model-select';
import { BodySelect } from 'src/components/body-select';
import { EngineSelect } from 'src/components/engine-select';
import { RestyleSelect } from 'src/components/restyle-select';
import { Images } from 'src/components/images';
import { useChangeVehicle } from 'src/api/vehicleApi';

type typeProps = {
  vehicle?: typeVehicle | null;
  onChange: () => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        padding: '16px',
      },
    }),
  { name: 'Vehicle' },
);

export const Vehicle: React.FunctionComponent<typeProps> = props => {
  const classes = useStyles({});

  const [vehicle, setVehicle] = useState<typeVehicle>({
    id: null,
    year: null,
    description: null,
    engine: null,
    frame: null,
    body: null,
    mileage: null,
    restyle: null,
    stockItems: [],
    arrival: null,
    isArrived: false,
  });

  useEffect(() => {
    if (props.vehicle) {
      setVehicle(props.vehicle);
    }
  }, [props.vehicle]);

  const changeVehicle = (data: Partial<typeVehicle>) =>
    setVehicle(prev => ({ ...prev, ...data }));

  const [addImages, { data }] = useAddImages();

  const [change] = useChangeVehicle();

  const handleAddImages = (files: File[]) => {
    const images = new FormData();

    if (!vehicle.id) return null;

    images.append('entityId', vehicle.id.toString());
    images.append('entityName', 'vehicle');

    files.forEach((file, index) => images.append(`image[${index}]`, file));

    return addImages(images).then((res: any) => {
      props.onChange();
      return res;
    });
  };

  const handleSave = () => {
    change(vehicle).then(() => props.onChange());
  };

  return (
    <div className={classes.root}>
      <Box mt={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <BrandSelect
              isCarManufacturer
              brand={vehicle.body?.model.brand}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ModelSelect
              model={vehicle.body?.model}
              brand={vehicle.body?.model.brand}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BodySelect
              body={vehicle.body}
              model={vehicle.body?.model}
              onChange={body => changeVehicle({ body })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <EngineSelect
              engine={vehicle.engine}
              body={vehicle.body}
              onChange={engine => changeVehicle({ engine })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <RestyleSelect
              restyle={vehicle.restyle}
              body={vehicle.body}
              onChange={restyle => changeVehicle({ restyle })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              value={vehicle.year || ''}
              label="Год"
              variant="outlined"
              onChange={e => changeVehicle({ year: parseInt(e.target.value, 10) || null })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              value={vehicle.colorCode || ''}
              label="Код цвета"
              variant="outlined"
              onChange={e => changeVehicle({ colorCode: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              value={vehicle.colorName || ''}
              label="Название цвета"
              variant="outlined"
              onChange={e => changeVehicle({ colorName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              value={vehicle.mileage || ''}
              label="Пробег"
              onChange={e =>
                changeVehicle({ mileage: parseInt(e.target.value, 10) || null })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              value={vehicle.frame || ''}
              label="VIN"
              onChange={e => changeVehicle({ frame: e.target.value })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              value={vehicle.description || ''}
              label="Описание"
              multiline
              onChange={e => changeVehicle({ description: e.target.value })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              value={vehicle.arrival || ''}
              label="Ожидаемая дата прибытия"
              onChange={e => changeVehicle({ arrival: e.target.value })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} alignItems="center">
            <FormControlLabel
              onChange={() => changeVehicle({ isArrived: !vehicle.isArrived })}
              control={<Checkbox checked={vehicle.isArrived} />}
              label="Прибыл"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ImageForm onAdd={handleAddImages} id="attachVehicleImages" />
        </Grid>
        <Grid item xs={12}>
          <Images images={props?.vehicle?.images} onChange={props.onChange} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button onClick={handleSave} color="primary" variant="contained">
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
