import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box, IconButton } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import { typeBrand, typeProduct } from 'src/types';
import { useDebounce } from 'src/hooks/useDebounce';
import { useSearchProduct } from 'src/api/productApi';
import { Autocomplete } from 'src/components/common/autocomplete';
import { ProductCreate } from '../product-create';

type typeProps = {
  product?: typeProduct | null;
  brand?: typeBrand | null;
  disabled?: boolean;
  label?: string;
  onChange?: (product: typeProduct | null) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductSelect' },
);

export const ProductSelect: React.FunctionComponent<typeProps> = ({
  product,
  brand,
  onChange,
  label,
  disabled,
}) => {
  const classes = useStyles({});
  const [article, setArticle] = useState('');

  const debouncedArticle = useDebounce<string>(article, 700);
  const { data, loading } = useSearchProduct({
    variables: { article: debouncedArticle },
    skip: !debouncedArticle,
  });

  return (
    <Box className={classes.root} display="flex">
      <Autocomplete
        disabled={disabled}
        fullWidth
        value={product}
        onChange={(e, value: typeProduct) => onChange && onChange(value)}
        options={data?.products || []}
        filterOptions={(options: any, state: any) =>
          options.filter(
            (item: typeProduct) =>
              item.articleCanonical.toLowerCase() ===
              state.inputValue.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
          )
        }
        getOptionLabel={(option: typeProduct) =>
          `${option?.brand?.originalName || 'Без бренда'} ${
            option?.articleCanonical || 'Без артикула'
          } ${option?.type?.name || option?.nameValue}`
        }
        onChangeTextField={e => setArticle(e.target.value)}
        label={label || 'Продукт'}
        loading={loading}
        actions={
          product
            ? [
                <IconButton
                  size="small"
                  target="_blank"
                  href={`/products/${product.id}`}
                  title="Открыть продукт"
                  onClick={e => e.stopPropagation()}
                >
                  <Link />
                </IconButton>,
              ]
            : []
        }
      />
      <Box ml={1}>
        <ProductCreate
          disabled={disabled}
          brand={brand}
          article={article}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};
