import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Paper,
  Theme,
} from '@material-ui/core';
import { useChangeDelivery, useGetDeliveries } from 'src/api/deliveryApi';
import { Table } from 'src/components/common/table';
import { typeDelivery } from 'src/types';
import { format } from 'date-fns';
import { Print } from '@material-ui/icons';
import { useRouter } from 'src/hooks/useRouter';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'DeliveriesPage' },
);

export const DeliveriesPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});
  const { query, push } = useRouter();

  const variables = {
    limit: parseInt(query.limit, 10) || 50,
    offset: parseInt(query.offset, 10) || 0,
  };

  const request = useGetDeliveries({ variables });

  const [change, changeRequest] = useChangeDelivery();

  const handleChange = (delivery: typeDelivery) =>
    change({
      ...delivery,
      finishedAt: delivery.finishedAt
        ? null
        : format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    }).then(() => request.refetch({ ...variables }));

    const handleRefetch = (params: any = {}) => {
      push('/deliveries', { ...variables, ...params });
    };

  return (
    <div className={classes.root}>
      <Paper>
        <Table
          withSummary
          title="Доставки"
          data={request?.data?.data}
          totalCount={request.data?.totalCount}
          headers={[
            {
              title: 'id',
              extract: (item: typeDelivery) => item?.id,

              component: 'th',
              scope: 'row',
            },
            {
              title: 'Дата создания',
              extract: (item: typeDelivery) =>
                item?.createdAt &&
                format(new Date(item?.createdAt), 'yyyy-MM-dd HH:mm'),

              component: 'th',
              scope: 'row',
            },
            {
              title: 'ТК',
              extract: (item: typeDelivery) => item?.deliveryCompany?.name,
            },
            {
              title: 'Адрес',
              extract: (item: typeDelivery) => item?.address,
            },
            {
              title: 'Получатель',
              extract: (item: typeDelivery) => (
                <Box fontSize="12px">
                  <Box>{item?.fullName}</Box>
                  <Box>{item?.passportCode}</Box>
                </Box>
              ),
            },
            {
              title: 'Заказы',
              extract: (item: typeDelivery) =>
                item?.orders?.map(order => (
                  <Box key={order.id} marginRight={1}>
                    <Link href={`/orders/${order.id}`}>{order.id}</Link>
                  </Box>
                )),
            },
            {
              title: 'Выполнена',
              extract: (item: typeDelivery) => (
                <FormControlLabel
                  onChange={() => handleChange(item)}
                  control={<Checkbox checked={Boolean(item.finishedAt)} />}
                  label={
                    item.finishedAt &&
                    format(new Date(item.finishedAt), 'yyyy-MM-dd HH:mm')
                  }
                />
              ),
            },
            {
              title: 'Печать',
              extract: (item: typeDelivery) => (
                <IconButton
                  size="small"
                  target="_blank"
                  href={`/deliveries-print?deliveryId=${item?.id}`}
                >
                  <Print />
                </IconButton>
              ),
            },
          ]}
          loading={request.loading}
          limit={variables.limit}
          offset={variables.offset}
          onFetch={handleRefetch}
        />
      </Paper>
    </div>
  );
};
