import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, TextField, Box } from '@material-ui/core';
import { typeRestyle, typeWarehousePlace, typeWarehouse } from 'src/types';
import { WarehousePlaceCreate } from '../warehouse-place-create/WarehousePlaceCreate';
import { useGetWarehousePlaces } from 'src/api/warehousesApi';
import { Autocomplete } from '../common/autocomplete';
import { orderBy } from 'lodash';

type typeProps = {
  onChange?: (place: typeWarehousePlace | null) => any;
  place?: typeWarehousePlace | null;
  warehouse?: typeWarehouse | null;
  disabled?: boolean;
  withCreate?: boolean;
  disableClearable?: boolean;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'WarehousePlaceSelect' },
);

export const WarehousePlaceSelect: React.FunctionComponent<typeProps> = ({
  onChange,
  place,
  disabled,
  warehouse,
  withCreate = true,
  disableClearable = true,
}) => {
  const classes = useStyles({});

  const [name, setName] = useState('');

  const [value, setValue] = useState<typeWarehousePlace | null>(null);

  const { data, refetch, loading } = useGetWarehousePlaces({
    variables: { warehouseId: warehouse?.id },
    skip: !warehouse,
  });

  useEffect(() => {
    const valueFromArray =
      data && place
        ? (data?.data.find(item => item.id === place.id) as typeWarehousePlace)
        : null;
    setValue(valueFromArray);
  }, [place, data]);

  const handleOnChange = (next: typeWarehousePlace | null) => {
    setValue(next);
    if (onChange) {
      onChange(next);
    }
  };
  return (
    <Box display="flex">
      <Autocomplete
        disableClearable={disableClearable}
        fullWidth
        loading={loading}
        disabled={!warehouse || disabled}
        value={value}
        onChange={(e, value) => handleOnChange(value)}
        onChangeTextField={e => setName(e.target.value)}
        options={orderBy(data?.data || [], ['name'])}
        label="Место хранения"
        getOptionLabel={(option: typeWarehousePlace) => option?.name || ''}
      />
      {withCreate && (
        <Box ml={1}>
          <WarehousePlaceCreate
            warehouse={warehouse}
            name={name}
            onCreate={(newPlace: typeWarehousePlace) => {
              handleOnChange(newPlace);

              return refetch({ warehouseId: warehouse?.id });
            }}
          />
        </Box>
      )}
    </Box>
  );
};
