import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { Waybill } from '../Waybill';
import { useGetDelivery } from 'src/api/deliveryApi';
import { useRouter } from 'src/hooks/useRouter';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > div': {
          width: '100%',
          maxWidth: '1000px',
          padding: '16px'
        },
      },
    }),
  { name: 'DeliveriesPrint' },
);

export const DeliveriesPrint: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const { query } = useRouter();

  const request = useGetDelivery({
    variables: { deliveryId: query.deliveryId },
  });

  return (
    <div className={classes.root}>
      <Waybill delivery={request.data} />
    </div>
  );
};
