import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Paper,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { typeListObject, typeVehicle } from 'src/types';
import { Table } from 'src/components/common/table';
import { useRouter } from 'src/hooks/useRouter';
import { orderBy } from 'lodash';
import { format } from 'date-fns';
import { Height } from '@material-ui/icons';

type typeProps = {
  vehicles: typeListObject<typeVehicle> | null;
  onFetch: (params?: { limit: number; offset: number }) => any;
  onCancel: any;
  loading: boolean;
  limit?: number;
  offset?: number;
  onClickRow?: (item: typeVehicle) => any;
  actions?: any[];
};

type typeState = {
  rowsPerPage: number;
  page: number;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      title: {
        flex: '1 1 100%',
      },
    }),
  { name: 'Vehicles' },
);

export const Vehicles: React.FunctionComponent<typeProps> = ({
  vehicles,
  loading,
  onFetch,
  onCancel,
  limit,
  offset,
  actions,
  onClickRow,
}) => {
  const classes = useStyles({});

  const [onlyWithVin, setOnlyWithVin] = useState(true);
  const [onlyWithMileaage, setOnlyWithMileaage] = useState(true);

  const { push } = useRouter();

  const list = orderBy(
    vehicles?.data,
    item =>
      `${item?.body?.model?.brand?.originalName} ${item?.body?.model?.name}`,
  )?.filter(item => {
    let criteria = [];
    if (onlyWithVin) criteria.push(Boolean(item.frame));
    if (onlyWithMileaage) criteria.push(Boolean(item.mileage));
    return criteria.every(value => value);
  });

  return (
    <div className={classes.root}>
      <Box mb={2} display="flex" flexWrap="wrap">
        <FormControlLabel
          onChange={() => setOnlyWithVin(!onlyWithVin)}
          control={<Checkbox checked={onlyWithVin} />}
          label="Только с VIN"
        />
        <FormControlLabel
          onChange={() => setOnlyWithMileaage(!onlyWithMileaage)}
          control={<Checkbox checked={onlyWithMileaage} />}
          label="Только с пробегом"
        />
      </Box>
      <Paper>
        <Table
          actions={actions}
          title="Автомобили"
          data={list}
          totalCount={vehicles?.totalCount}
          headers={[
            {
              title: 'id',
              extract: (item: typeVehicle) => item.id,
              component: 'th',
              scope: 'row',
            },
            {
              title: 'Дата',
              extract: (item: typeVehicle) =>
                item?.createdAt &&
                format(new Date(item?.createdAt), 'yyyy-MM-dd'),
            },
            {
              title: 'Фото',
              extract: (item: typeVehicle) =>
                item.images &&
                item.images[0] && (
                  <div
                    style={{
                      width: '64px',
                      height: '64px',
                      backgroundImage: `url(${item.images[0].publicPath}?h=64)`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  />
                ),
            },
            {
              title: 'Марка',
              extract: (item: typeVehicle) =>
                item.body?.model?.brand?.originalName,
            },
            {
              title: 'Модель',
              extract: (item: typeVehicle) => item.body?.model?.name,
            },
            {
              title: 'Кузов',
              extract: (item: typeVehicle) => item.body?.name,
            },
            {
              title: 'Двигатель',
              extract: (item: typeVehicle) => item.engine?.name,
            },
            {
              title: 'VIN',
              extract: (item: typeVehicle) => item.frame,
            },
            {
              title: 'Год',
              extract: (item: typeVehicle) => item.year,
            },
            {
              title: 'Рестайлин',
              extract: (item: typeVehicle) => item.restyle?.name,
            },
            {
              title: 'Пробег',
              extract: (item: typeVehicle) => item.mileage,
            },
            {
              title: 'Описание',
              extract: (item: typeVehicle) => item.description,
            },
            {
              title: 'RU название',
              extract: (item: typeVehicle) => item.ruName,
            },
          ]}
          onClickRow={(item: typeVehicle) =>
            onClickRow
              ? onClickRow(item)
              : window.open(`/vehicles/${item.id}`, '_blank')
          }
          loading={loading}
          onCancel={onCancel}
          withPagination={false}
          onFetch={onFetch}
          limit={limit}
          offset={offset}
        />
      </Paper>
    </div>
  );
};
