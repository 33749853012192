import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { ProductTargetSelect } from './ProductTargetSelect';
import { ProductTargetCategorySelect } from './ProductTargetCategorySelect';
import { useCreateProductTargetType } from 'src/api/productTypeApi';
import {
  typeProductTarget,
  typeProductType,
  typeProductTypeCategory,
} from 'src/types';
import { WithLoading } from './common/with-loading';

interface typeProps {
  type?: typeProductType | null;
  onChange?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductTargetTypeCreate' },
);

export const ProductTargetTypeCreate: React.FunctionComponent<typeProps> = ({
  type,
  onChange,
}) => {
  const classes = useStyles({});

  const [target, setTarget] = useState<typeProductTarget | null>(null);
  const [category, setCategory] = useState<typeProductTypeCategory | null>(
    null,
  );
  const [dialog, setDialog] = useState(false);

  const [create, createData] = useCreateProductTargetType({
    variables: { typeId: type?.id },
  });

  const handleCreate = () => {
    if (target && category) {
      create({ target, category }).then(() => {
        if (onChange) onChange();
        setDialog(false);
        setCategory(null);
        setTarget(null);
      });
    }
  };

  return (
    <div className={classes.root}>
      <Fab size="small" color="primary" onClick={() => setDialog(true)}>
        <Add />
      </Fab>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <ProductTargetSelect onChange={setTarget} />
          <Box mt={2}>
            <ProductTargetCategorySelect
              onChange={setCategory}
              target={target}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Закрыть</Button>
          <WithLoading loading={createData.loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={createData.loading || !target || !category}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
};
