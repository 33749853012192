import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Box,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { typePhoneNumber } from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';
import { useCreateEmployees } from 'src/api/employeeApi';

type typeProps = {
  onCreate: (value?: any) => any;
  phoneNumber?: string | null;
};

type typeState = {
  firstName?: string | null;
  middleName?: string | null;
  secondName?: string | null;
  organizationName?: string | null;
  phone?: typePhoneNumber | null;
};

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'EmployeeCreateDialog',
});

const initState = {
  firstName: null,
  middleName: null,
  secondName: null,
};

export const EmployeeCreateDialog: React.FunctionComponent<typeProps> = ({
  onCreate,
}) => {
  const classes = useStyles({});

  const [open, setOpen] = useState(false);
  const [state, changeState] = useState<typeState>(initState);

  const setState = (data: Partial<typeState>) =>
    changeState((prevState: typeState) => ({ ...prevState, ...data }));

  const [create, { loading }] = useCreateEmployees();

  const handleCreate = () => {
    if (state.firstName) {
      create(state).then(() => {
        setOpen(false);
        changeState(initState);
        onCreate();
      });
    }
  };

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setOpen(!open)} size="small">
        <Add />
      </Fab>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            label="Фамилия"
            autoFocus
            onChange={e => setState({ secondName: e.target.value })}
            value={state.secondName}
          />

          <Box mt={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Имя"
              onChange={e => setState({ firstName: e.target.value })}
              value={state.firstName}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Отчество"
              onChange={e => setState({ middleName: e.target.value })}
              value={state.middleName}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={!state.firstName}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
