import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Theme,
} from '@material-ui/core';
import { BodySelect } from '../body-select';
import { EngineSelect } from '../engine-select';
import { Add } from '@material-ui/icons';
import { WithLoading } from '../common/with-loading';
import { useCreateProductUsage } from 'src/api/productApi';
import { typeBody, typeEngine, typeProduct } from 'src/types';

interface typeProps {
  product?: typeProduct | null;
  onChange?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductUsageCreate' },
);

type typeState = {
  dialog: boolean;
  body: typeBody | null;
  engine: typeEngine | null;
};

const initialState = {
  dialog: false,
  body: null,
  engine: null,
  model: null,
};

export const ProductUsageCreate: React.FunctionComponent<typeProps> = ({
  product,
  onChange,
}) => {
  const classes = useStyles({});

  const [state, changeState] = useState<typeState>(initialState);

  const setState = (data: Partial<typeState>) =>
    changeState((prevState: typeState) => ({ ...prevState, ...data }));

  const [createUsage, { loading }] = useCreateProductUsage();

  const handleCreate = () => {
    if (product && (state.body || state.engine)) {
      createUsage({ product, body: state.body, engine: state.engine, model: null });
      setState(initialState);
      if (onChange) {
        onChange();
      }
    }
  };

  return (
    <React.Fragment>
      <Fab
        color="primary"
        onClick={() => setState({ dialog: true })}
        title="Добавить применимость"
      >
        <Add />
      </Fab>
      <Dialog
        open={state.dialog}
        onClose={() => setState({ dialog: false })}
        fullWidth
      >
        <DialogContent>
          <BodySelect
            body={state.body}
            all
            onChange={body => setState({ body })}
            disabled={Boolean(state.engine)}
          />
          <Box mt={2}>
            <EngineSelect
              all
              engine={state.engine}
              onChange={engine => setState({ engine })}
              disabled={Boolean(state.body)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState({ dialog: false })}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={!state.body && !state.engine}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
