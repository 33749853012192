import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';
import { typeProductType, typeProductTypeAlias } from 'src/types';
import { orderBy } from 'lodash';
import { useGetProductAliases } from 'src/api/productTypeApi';

type typeProps = {
  alias?: typeProductTypeAlias | null;
  onSelect: (type: typeProductTypeAlias | null) => any;
  onChange: (value: string | null) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      muted: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.grey[300],
      },
    }),
  { name: 'ProductTypeAliasSelect' },
);

export const ProductTypeAliasSelect: React.FunctionComponent<typeProps> = ({
  alias,
  onSelect,
  onChange,
}) => {
  const classes = useStyles({});

  const { data, loading } = useGetProductAliases();

  const [value, setValue] = useState<typeProductTypeAlias | null>(null);

  useEffect(() => {
    const valueFromArray =
      data && alias
        ? (data?.find(
            (item: any) => alias && item?.id === alias?.id,
          ) as typeProductTypeAlias)
        : null;
    if (valueFromArray) {
      setValue(valueFromArray);
    }
  }, [alias, data]);

  return (
    <div className={classes.root}>
      <Autocomplete
        clearOnBlur={false}
        fullWidth
        value={value}
        onChange={(e: any, alias: typeProductTypeAlias) => onSelect(alias)}
        options={orderBy(data || [], 'name')}
        getOptionLabel={(option: typeProductTypeAlias) => option.name}
        renderOption={(option: typeProductTypeAlias) => (
          <div>
            <div>{option.type?.name}</div>
            <div className={classes.muted}>{option.name}</div>
          </div>
        )}
        onChangeTextField={(e: any) => onChange(e.target.value)}
        label="Синоним"
        loading={loading}
      />
    </div>
  );
};
