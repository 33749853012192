import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box, Paper, Link } from '@material-ui/core';
import { Table } from 'src/components/common/table';
import { typeOrder } from 'src/types';
import { useGetOrders } from 'src/api/ordersApi';
import { format } from 'date-fns';
import { useRouter } from 'src/hooks/useRouter';
import { green, red } from '@material-ui/core/colors';
import { Orders } from '../Orders';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'OrdersPage' },
);

export const OrdersPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { query, push } = useRouter();

  const variables = {
    limit: parseInt(query.limit, 10) || 50,
    offset: parseInt(query.offset, 10) || 0,
  };

  const { data, loading, refetch } = useGetOrders({ variables });

  const handleRefetch = (params: any = {}) => {
    push('/orders', { ...variables, ...params });
    refetch({ ...variables, ...params });
  };

  return (
    <div className={classes.root}>
      <Box mt={2}>
        <Orders
          orders={data?.data}
          totalCount={data?.totalCount}
          loading={loading}
          refetch={handleRefetch}
          limit={variables.limit}
          offset={variables.offset}
        />
      </Box>
    </div>
  );
};
