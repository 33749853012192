import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { useGetStockItems } from 'src/api/stockItemApi';
import { StockItems } from 'src/components/stock-items';
import { useRouter } from 'src/hooks/useRouter';
import { StockItemCreate } from 'src/components/stock-item-create';
import { Search } from '@material-ui/icons';
import { WarehousePlaceSelect } from 'src/components/warehouse-place-select';
import { WarehouseSelect } from 'src/components/warehouse-select';
import { ProductCodeSelect } from 'src/components/ProductCodeSelect';
import { ProductTypeSelect } from 'src/components/ProductTypeSelect';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      formControl: {
        minWidth: 120,
        width: '100%',
      },
      '@media print': {
        root: {
          backgroundColor: '#fff !important',
          color: '#000 !important',
        },
      },
    }),
  { name: 'StockItemsPage' },
);

export const StockItemsPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const [code, setCode] = useState('');
  const [article, setArticle] = useState('');

  const { query, push } = useRouter();

  const initialVariables = {
    limit: 50,
    offset: 0,
    filter: null,
    article: null,
    warehouseId: null,
    bodyId: null,
    typeId: null,
    warehousePlaceId: null,
    codes: null,
    stock: null,
    productTypeId: null,
  };

  const variables = {
    limit: parseInt(query.limit, 10) || 50,
    offset: parseInt(query.offset, 10) || 0,
    bodyId: query.bodyId || null,
    typeId: query.typeId || null,
    filter: query.filter,
    article: query.article || null,
    warehouseId: query.warehouseId || null,
    warehousePlaceId: query.warehousePlaceId || null,
    codes: query.codes || null,
    stock: query.stock || null,
    productTypeId: query.productTypeId || null,
  };

  const { data, loading, refetch, cancel } = useGetStockItems({
    variables,
  });

  const handleRefetch = (params: any = {}) => {
    let nextParams = params;

    if (params.codes) {
      nextParams['codes'] = params.codes.join(',');
    }

    push('/stock-items', { ...variables, ...nextParams });

    return refetch({ ...variables, ...nextParams });
  };

  return (
    <div className={classes.root}>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={article !== null ? article : query.article}
              label="Артикул"
              onChange={e => setArticle(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter' && article) {
                  handleRefetch({ ...initialVariables, article });
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {Boolean(article) && (
                      <IconButton
                        onClick={() =>
                          handleRefetch({
                            ...initialVariables,
                            article: article || null,
                          })
                        }
                      >
                        <Search />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <WarehouseSelect
              warehouse={
                variables.warehouseId && {
                  id: parseInt(variables.warehouseId, 10),
                }
              }
              onChange={warehouse =>
                handleRefetch({
                  warehouseId: warehouse && warehouse.id,
                  offset: 0,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <WarehousePlaceSelect
              disableClearable={false}
              withCreate={false}
              warehouse={
                variables.warehouseId && {
                  id: parseInt(variables.warehouseId, 10),
                }
              }
              place={
                variables.warehousePlaceId && {
                  id: parseInt(variables.warehousePlaceId, 10),
                }
              }
              onChange={place =>
                handleRefetch({
                  warehousePlaceId: place && place.id,
                  offset: 0,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ProductTypeSelect
              type={
                query?.productTypeId
                  ? { id: parseInt(query?.productTypeId) }
                  : null
              }
              withCreate={false}
              onSelect={type =>
                handleRefetch({ productTypeId: type?.id, offset: 0 })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="fliter-label">Фильтр</InputLabel>
              <Select
                labelId="fliter-label"
                label="Фильтр"
                value={query.filter}
                // @ts-ignore
                onChange={e =>
                  handleRefetch({
                    ...variables,
                    filter: e.target.value,
                  })
                }
              >
                <MenuItem value="">
                  <em>Не выбрано</em>
                </MenuItem>
                <MenuItem value="nullProduct">Без продукта</MenuItem>
                <MenuItem value="zeroPrice">Без цены</MenuItem>
                <MenuItem value="nullWarehousePlace">Без места</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box display="flex" alignItems="center" height="100%">
              <FormControlLabel
                label="В наличии"
                onChange={() =>
                  handleRefetch({
                    stock: query.stock
                      ? // @ts-ignore
                        { true: 'false', false: null }[query.stock]
                      : 'true',
                  })
                }
                control={
                  <Checkbox
                    checked={Boolean(query.stock === 'true')}
                    indeterminate={query.stock === 'false'}
                  />
                }
              ></FormControlLabel>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ProductCodeSelect
              codes={variables.codes ? variables.codes.split(',') : []}
              onChange={codes => handleRefetch({ codes, offset: 0 })}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <StockItems
          withOutOfStock={false}
          actions={[() => <StockItemCreate onChange={handleRefetch} />]}
          withFilter={false}
          stockItems={data}
          limit={variables.limit}
          offset={variables.offset}
          onFetch={handleRefetch}
          onCancel={cancel}
          loading={loading}
        />
      </Box>
    </div>
  );
};
