import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { useGetDiagnostics } from 'src/api/actApi';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'Diagnostics' },
);

export const Diagnostics: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const request = useGetDiagnostics();

  return <div className={classes.root}>{JSON.stringify(request?.data)}</div>;
};
