import { request } from 'src/services/request';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import { typeImage } from 'src/types';

export const addImagesToProduct = ({
  productId,
  files,
}: {
  productId: number;
  files: File[];
}) => {
  const images = new FormData();

  files.forEach((file, index) => images.append(`image[${index}]`, file));

  return request.post(`/products/${productId}/images`, images);
};

export const addImagesToStockItem = ({
  stockItemId,
  files,
}: {
  stockItemId: number;
  files: File[];
}) => {
  const images = new FormData();

  files.forEach((file, index) => images.append(`image[${index}]`, file));

  return request.post(`/stock-items/${stockItemId}/images`, images);
};

export const addImagesToVehicle = ({
  vehicleId,
  files,
}: {
  vehicleId: number;
  files: File[];
}) => {
  const images = new FormData();

  files.forEach((file, index) => images.append(`image[${index}]`, file));

  return request.post(`/vehicles/${vehicleId}/images`, images);
};

export function useAddImagesToVehicle() {
  return useRequestWithPayload<any, FormData>({
    url: `/vehicles/images`,
    method: 'post',
  });
}

export function useAddImagesToProduct() {
  return useRequestWithPayload<any, FormData>({
    url: `/products/images`,
    method: 'post',
  });
}

export function useAddImagesToStockItem() {
  return useRequestWithPayload<any, FormData>({
    url: `/stock-items/images`,
    method: 'post',
  });
}

export function useDeletemage() {
  return useRequestWithPayload<any, typeImage>({
    url: '/images/delete',
    method: 'post',
  });
}

export function useAddImages() {
  return useRequestWithPayload<any, FormData>({
    url: `/images`,
    method: 'post',
  });
}
