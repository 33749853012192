import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import {
  typeBillingAccount,
  typeBillingJournalReason,
  typeBillingJournalType,
  typeListObject,
  typeVehicle,
  typeOrder,
  typeBillingJournal,
  typeContractor,
  typeBillingPost,
  typeEmployee,
} from 'src/types';

export function useBillingAccounts(
  {
    variables,
    skip,
  }: {
    variables: { limit?: number; offset?: number };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeListObject<typeBillingAccount>, typeof variables>({
    url: '/billing/accounts',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useGetBillingAccout(
  {
    variables,
    skip,
  }: {
    variables: { accountId?: number | string };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeBillingAccount, typeof variables>({
    url: `/billing/accounts/${variables.accountId}`,
    method: 'get',
    query: variables,
    skip,
  });
}

export function useGetBillingAccoutPosts(
  {
    variables,
    skip,
  }: {
    variables: {
      accountId?: number | string;
      from?: number | string | null;
      to?: number | string | null;
    };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeListObject<typeBillingPost>, typeof variables>({
    url: `/billing/accounts/${variables.accountId}/posts`,
    method: 'get',
    query: variables,
    skip,
  });
}

export function useGetCurrencies() {
  return useRequest<any>({
    url: '/billing/currencies',
    method: 'get',
  });
}

export function useGetJournalTypes() {
  return useRequest<any>({
    url: '/billing/journal-types',
    method: 'get',
  });
}

export function useGetJournalReasons() {
  return useRequest<any>({
    url: '/billing/journal-reasons',
    method: 'get',
  });
}

export function useCreateBillingAccount() {
  return useRequestWithPayload<
    typeBillingAccount,
    { name: string; currency: string; ownerName: string }
  >({
    url: '/billing/accounts',
    method: 'post',
  });
}

export function useCreateBillingJournal() {
  return useRequestWithPayload<
    typeBillingAccount,
    {
      from: typeBillingAccount;
      to: typeBillingAccount;
      type: typeBillingJournalType;
      reason: typeBillingJournalReason | null;
      vehicle: typeVehicle | null;
      order: typeOrder | null;
      employee: typeEmployee | null;
      comment: string | null;
      value: number | null;
      newCurrencyValue: number | null;
      commission: number | null;
    }
  >({
    url: '/billing/journal',
    method: 'post',
  });
}

export function useBillingJournalReasons(
  {
    variables,
    skip,
  }: {
    variables: { limit?: number; offset?: number };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeListObject<typeBillingJournalReason>, typeof variables>(
    {
      url: '/billing/journal-reasons',
      method: 'get',
      query: variables,
      skip,
    },
  );
}

export function useGetBillingSummary(
  {
    variables,
    skip,
  }: {
    variables: { limit?: number; offset?: number };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<
    {
      globalBalance: number;
      companyBalance: {
        [key: string]: { RUB: number; USD: number; JPY: number };
      };
    },
    typeof variables
  >({
    url: '/billing/summary',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useCreateBillingJournalReasons() {
  return useRequestWithPayload<typeBillingAccount, { name: string }>({
    url: '/billing/journal-reasons',
    method: 'post',
  });
}

export function useGetBillingJournal(
  {
    variables,
    skip,
  }: {
    variables: {
      limit?: number | null;
      offset?: number | null;
      orderId?: number | string;
      vehicleId?: number | string;
      employeeId?: number | string;
      from?: number | string | null;
      to?: number | string | null;
      reasons?: number | string | null;
    };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeListObject<typeBillingJournal>, typeof variables>({
    url: '/billing/journal',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useGetContractors() {
  return useRequest<typeListObject<typeContractor>>({
    url: '/contractors',
    method: 'get',
  });
}
