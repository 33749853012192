import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Autocomplete } from './common/autocomplete';
import { useGetProductTargets } from 'src/api/productTypeApi';
import { typeProductTarget } from 'src/types';

interface typeProps {
  onChange?: (value: any) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductTargetSelect' },
);

export const ProductTargetSelect: React.FunctionComponent<typeProps> = ({
  onChange,
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState<typeProductTarget | null>(null);

  const targets = useGetProductTargets();

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        fullWidth
        value={value}
        onChange={(e, value: any) => hangdeOnChange(value)}
        options={targets.data || []}
        getOptionLabel={(option: any) => option.name}
        label="Цель экспорта"
        loading={targets.loading}
      />
    </div>
  );
};
