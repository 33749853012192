import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Grid, InputAdornment, TextField, Theme } from '@material-ui/core';
import { typeCustomer } from 'src/types';
import NumberFormat from 'react-number-format';
import { CustomerEdit } from './CustomerEdit';

interface typeProps {
  customer?: typeCustomer | null;
  onChange?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'Customer' },
);

export const Customer: React.FunctionComponent<typeProps> = ({
  customer,
  onChange,
}) => {
  const classes = useStyles({});
  if (!customer) return null;
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <NumberFormat
          disabled
          customInput={TextField}
          format="(###) ### ## ##"
          mask="_"
          variant="outlined"
          label="Телефон"
          InputLabelProps={{ shrink: true }}
          id="phone"
          fullWidth
          placeholder="(___) __ __ __"
          value={customer.phone?.value}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+7</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Фамилия"
          variant="outlined"
          fullWidth
          value={customer.secondName}
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Имя"
          variant="outlined"
          fullWidth
          value={customer.firstName}
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Отчество"
          variant="outlined"
          fullWidth
          value={customer.middleName}
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {customer.organizationName && (
        <Grid item xs={12}>
          <TextField
            label="Организация"
            variant="outlined"
            fullWidth
            value={customer.organizationName}
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        Количество заказов клиента: {customer.ordersCount}
      </Grid>
      {onChange && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <CustomerEdit customer={customer} onSave={onChange} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
