import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { StoWorkSelect } from 'src/features/sto-work-select';
import { STOWork, STOWorkPrice } from 'src/shared/api';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'DiagnosticPage' },
);

export const DiagnosticPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});
  const [STOWorkPrice, setStioWorkPrice] = useState<null | STOWorkPrice>(null);

  return (
    <div className={classes.root}>
      <StoWorkSelect onChange={setStioWorkPrice} value={STOWorkPrice}/>
    </div>
  );
};
