import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  TextField,
  Grid,
  DialogActions,
  Button,
  Box,
  Tabs,
  Tab,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { WithLoading } from 'src/components/common/with-loading';
import {
  typeBrand,
  typeProduct,
  typeSupplier,
  typeVehicle,
  typeWarehouse,
  typeWarehousePlace,
} from 'src/types';
import { ProductSelect } from 'src/components/product-select';
import {
  useCreateStockItem,
  useBatchCreateStockItem,
} from 'src/api/stockItemApi';
import { ProductCreate } from 'src/components/product-create';
import { SupplierSelect } from '../supplier-select';
import { VehicleSelect } from '../vehicle-select';
import { WarehouseSelect } from '../warehouse-select';

type typeProps = {
  vehicle?: typeVehicle | null;
  product?: typeProduct | null;
  onChange: (place?: typeWarehousePlace) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'StockItemCreate' },
);

type typeState = {
  quantity: number;
  title: string | null;
  description: string | null;
  startPrice: number;
  fixedPrice: number;
  warehousePlace: typeWarehousePlace | null;
};

const initialState = {
  quantity: 1,
  title: null,
  description: null,
  warehousePlace: null,
  owner: null,
  startPrice: 0,
  fixedPrice: 0,
};

export const StockItemCreate: React.FunctionComponent<typeProps> = ({
  vehicle: providedVehicle = null,
  product: providedProduct = null,
  onChange,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);

  const [tab, setTab] = useState(0);

  const [product, setProduct] = useState<typeProduct | null>(null);

  const [vehicle, setVehicle] = useState<typeVehicle | null>(null);

  const [owner, setOwner] = useState<typeSupplier | null>(null);

  const [warehouse, setWarehouse] = useState<typeWarehouse | null>(null);

  const [batchQuantity, setStockItemsBatchQuantity] = useState(0);

  useEffect(() => {
    setProduct(providedProduct);
  }, [providedProduct]);
  useEffect(() => {
    setVehicle(providedVehicle);
  }, [providedVehicle]);

  const [state, changeState] = useState<typeState>(initialState);

  const setState = (data: Partial<typeState>) =>
    changeState(prevState => ({ ...prevState, ...data }));

  const [add, { loading }] = useCreateStockItem();
  const [addBatch, { loading: batchLoading }] = useBatchCreateStockItem();

  const handleCreate = () => {
    if (tab === 0) {
      add({
        ...state,
        warehouse: warehouse && { id: warehouse?.id },
        product: product && { id: product?.id },
        vehicle: vehicle && { id: vehicle?.id },
        owner: owner && { id: owner?.id },
      }).then(() => {
        setDialog(false);
        onChange();
        setOwner(null);
        setProduct(null);
      });
    }
    if (tab === 1) {
      addBatch({
        ...state,
        warehouse: warehouse && { id: warehouse?.id },
        product: product && { id: product?.id },
        vehicle: vehicle && { id: vehicle?.id },
        owner: owner && { id: owner?.id },
        batchQuantity,
        startPrice: 0,
        fixedPrice: 0,
        quantity: 1,
      }).then(() => {
        setDialog(false);
        onChange();
        setOwner(null);
        setWarehouse(null);
        setProduct(null);
        setVehicle(null);
        setStockItemsBatchQuantity(0);
      });
    }
  };

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setDialog(true)} size="small">
        <Add />
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <Tabs value={tab} centered onChange={(e, value) => setTab(value)}>
            <Tab label="Один товар" />
            <Tab label="Несколько товаров" />
          </Tabs>
          <Box mt={2}>
            {tab === 0 && (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <ProductSelect
                    brand={vehicle?.body?.model?.brand}
                    product={product}
                    onChange={(value: typeProduct | null) => setProduct(value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Название товара"
                    value={state.title || ''}
                    onChange={e => setState({ title: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    label="Описание"
                    value={state.description || ''}
                    onChange={e => setState({ description: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <VehicleSelect onChange={setVehicle} vehicle={vehicle} />
                </Grid>
                <Grid item xs={12}>
                  <SupplierSelect onChange={setOwner} />
                </Grid>
                <Grid item xs={12}>
                  <WarehouseSelect
                    onChange={setWarehouse}
                    warehouse={warehouse}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Количество"
                    value={state.quantity}
                    type="number"
                    onChange={e =>
                      setState({ quantity: parseInt(e.target.value, 10) })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Базовая цена"
                    value={state.startPrice}
                    type="number"
                    onChange={e =>
                      setState({ startPrice: parseInt(e.target.value, 10) })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Фиксированная  цена"
                    value={state.fixedPrice}
                    type="number"
                    onChange={e =>
                      setState({ fixedPrice: parseInt(e.target.value, 10) })
                    }
                  />
                </Grid>
              </Grid>
            )}
            {tab === 1 && (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Количество товаров"
                    type="number"
                    value={batchQuantity}
                    onChange={e =>
                      setStockItemsBatchQuantity(parseInt(e.target.value, 10))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <VehicleSelect onChange={setVehicle} vehicle={vehicle} />
                </Grid>
                <Grid item xs={12}>
                  <SupplierSelect onChange={setOwner} />
                </Grid>
                <Grid item xs={12}>
                  <WarehouseSelect
                    onChange={setWarehouse}
                    warehouse={warehouse}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading loading={loading || batchLoading}>
            <Button onClick={handleCreate} color="primary" variant="contained">
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
