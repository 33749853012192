import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  typeBody,
  typeBrand,
  typeModel,
  typeEngine,
  typeRestyle,
  typeCustomer,
  typeContractor,
  typeVehicle,
} from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';
import { BrandSelect } from 'src/components/brand-select';
import { ModelSelect } from 'src/components/model-select';
import { BodySelect } from 'src/components/body-select';
import { EngineSelect } from 'src/components/engine-select';
import { useCreateVehicle } from 'src/api/vehicleApi';
import { RestyleSelect } from 'src/components/restyle-select';
import { CustomerSelect } from '../CustomerSelect';
import { ContractorSelect } from '../ContractorSelect';

type typeProps = {
  onCreate: (value: typeVehicle | null) => any;
  customer?: typeCustomer | null;
};

type typeState = {
  description: string | null;
  brand: typeBrand | null;
  model: typeModel | null;
  body: typeBody | null;
  engine: typeEngine | null;
  restyle: typeRestyle | null;
  year: number | null;
  mileage: number | null;
  frame: string | null;
  colorName: string | null;
  colorCode: string | null;
  arrival: string | null;
  isArrived: boolean;
  customer: typeCustomer | null;
  contractor: typeContractor | null;
};

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'VehicleCreate',
});

const initialState = {
  description: '',
  brand: null,
  model: null,
  body: null,
  engine: null,
  year: null,
  mileage: null,
  restyle: null,
  frame: null,
  colorCode: null,
  colorName: null,
  arrival: null,
  isArrived: true,
  customer: null,
  contractor: null,
};

export const VehicleCreate: React.FunctionComponent<typeProps> = ({
  onCreate,
  customer,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);
  const [state, changeState] = useState<typeState>(initialState);

  useEffect(() => {
    if (customer) {
      setState({ customer });
    }
  }, [customer]);

  const setState = (data: Partial<typeState>) =>
    changeState((prevState: typeState) => ({ ...prevState, ...data }));

  const [create, { data, loading }] = useCreateVehicle();

  const handleCreate = () => {
    if (state.body) {
      create({
        year: state.year,
        body: state.body,
        description: state.description,
        mileage: state.mileage,
        engine: state.engine,
        frame: state.frame,
        restyle: state.restyle,
        colorName: state.colorName,
        colorCode: state.colorCode,
        arrival: state.arrival,
        customer: state.customer,
        contractor: state.contractor,
      }).then((res: typeVehicle) => {
        setDialog(false);
        setState(initialState);
        onCreate(res);
      });
    }
  };

  return (
    <React.Fragment>
      <Fab color="primary" size="small" onClick={() => setDialog(true)}>
        <Add />
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomerSelect
                onSelect={customer => setState({ customer })}
                customer={state.customer}
                phone={state?.customer?.phone?.value}
                disabled={Boolean(state.contractor)}
              />
            </Grid>
            <Grid item xs={12}>
              <ContractorSelect
                disabled={Boolean(state.customer)}
                onSelect={contractor => setState({ contractor })}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <BrandSelect
                isCarManufacturer
                brand={state.brand}
                onChange={brand => setState({ brand })}
              />
              <Box mt={2}>
                <ModelSelect
                  brand={state.brand}
                  model={state.model}
                  onChange={model => setState({ model })}
                />
              </Box>
              <Box mt={2}>
                <BodySelect
                  body={state.body}
                  model={state.model}
                  onChange={body => setState({ body })}
                />
              </Box>
              <Box mt={2}>
                <EngineSelect
                  body={state.body}
                  engine={state.engine}
                  onChange={engine => setState({ engine })}
                />
              </Box>
              <Box mt={2}>
                <RestyleSelect
                  body={state.body}
                  restyle={state.restyle}
                  onChange={restyle => setState({ restyle })}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  variant="outlined"
                  label="VIN"
                  onChange={e => setState({ frame: e.target.value })}
                  value={state.frame}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  variant="outlined"
                  label="Год"
                  type="number"
                  onChange={e =>
                    setState({ year: parseInt(e.target.value, 10) })
                  }
                  value={state.year}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                fullWidth
                variant="outlined"
                label="Пробег"
                type="number"
                onChange={e =>
                  setState({ mileage: parseInt(e.target.value, 10) })
                }
                value={state.mileage}
              />
              <Box mt={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Код цвета"
                  onChange={e => setState({ colorCode: e.target.value })}
                  value={state.colorCode}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Название цвета"
                  onChange={e => setState({ colorName: e.target.value })}
                  value={state.colorName}
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Описание"
                  multiline
                  rows="5"
                  onChange={e => setState({ description: e.target.value })}
                  value={state.description}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Ожидаемая дата прибытия"
                  multiline
                  onChange={e => setState({ arrival: e.target.value })}
                  value={state.arrival}
                />
              </Box>
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.isArrived}
                      onChange={() => setState({ isArrived: !state.isArrived })}
                    />
                  }
                  label="Прибыл"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={!state.body}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
