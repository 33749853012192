import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link, Paper, Theme } from '@material-ui/core';
import { useGetEmployees } from 'src/api/employeeApi';
import { Table } from '../common/table';
import { typeEmployee } from 'src/types';
import { EmployeeCreateDialog } from '../EmployeeCreateDialog';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'EmployeesPage' },
);

export const EmployeesPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const request = useGetEmployees();

  return (
    <div className={classes.root}>
      <Paper>
        <Table
          title="Сотрудники"
          actions={[() => <EmployeeCreateDialog onCreate={request.refetch} />]}
          data={request?.data?.data || []}
          totalCount={request?.data?.totalCount || 0}
          loading={request?.loading}
          headers={[
            {
              title: 'id',
              extract: (item: typeEmployee) => (
                <Link target="_blank" href={`/employees/${item?.id}`}>
                  {item?.id}
                </Link>
              ),
            },
            { title: 'ФИО', extract: (item: typeEmployee) => item?.fullName },
          ]}
        />
      </Paper>
    </div>
  );
};
