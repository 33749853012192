import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  closeShift,
  openShift,
  registerCheck,
  startDevices,
  stopDevices,
  useGetKkmDevices,
  useGetKkt,
} from 'src/entities/kkm';
import {
  KkmDevice,
  CashRegisterSessionState,
  TaxVariant,
  TypeCheck,
} from 'src/shared/api';
import { WithLoading } from 'src/components/common/with-loading';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'KkmPage' },
);

const KktDevice: React.FunctionComponent<{ device: KkmDevice }> = ({
  device,
}) => {
  const [shiftError, setShiftError] = useState(null);

  const kktRequest = useGetKkt({
    variables: { deviceNumber: device.NumDevice },
  });

  const handleChangeShift = (method: any) => {
    setShiftError(null);
    method().then((res: any) => {
      res.data.Error && setShiftError(res.data.Error);
    });
  };

  return (
    <WithLoading loading={kktRequest.loading}>
      <div>
        <ListItemText
          primary={device.NameDevice}
          secondary={device.TypeDevice}
        />
        {!device.Active && (
          <Box mt={2} display="flex">
            <Alert severity="warning">Не активен</Alert>
          </Box>
        )}
        {kktRequest.data?.Error && (
          <Box mt={2} display="flex">
            <Alert severity="error">{kktRequest.data?.Error}</Alert>
          </Box>
        )}
        {kktRequest.data?.Info && (
          <div>
            <Box mt={2} display="flex">
              <Alert severity="info">
                Смена {CashRegisterSessionState[kktRequest.data?.Info?.SessionState]}
              </Alert>
            </Box>
            {shiftError && (
              <Box mt={2} display="flex">
                <Alert severity="error">{shiftError}</Alert>
              </Box>
            )}
            <Box mt={2}>
              {kktRequest.data?.Info?.SessionState ===
                CashRegisterSessionState.Закрыта && (
                <Button
                  onClick={() => handleChangeShift(openShift)}
                  variant="contained"
                >
                  Открыть смену
                </Button>
              )}
              {kktRequest.data?.Info?.SessionState !==
                CashRegisterSessionState.Закрыта && (
                <Button
                  onClick={() => handleChangeShift(closeShift)}
                  variant="contained"
                >
                  Закрыть смену
                </Button>
              )}
            </Box>
          </div>
        )}
      </div>
    </WithLoading>
  );
};

export const KkmPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const requestDevices = useGetKkmDevices({});

  return (
    <div className={classes.root}>
      {Boolean(requestDevices.data?.ListUnit?.length) && (
        <Button
          variant="outlined"
          onClick={() => stopDevices().then(requestDevices.refetch)}
        >
          Отключить устройства
        </Button>
      )}
      {requestDevices.data && !requestDevices.data?.ListUnit?.length && (
        <Button onClick={() => startDevices().then(requestDevices.refetch)}>
          Подключить устройства
        </Button>
      )}
      {requestDevices.data?.Error && (
        <Alert severity="error">{requestDevices.data?.Error}</Alert>
      )}
      <WithLoading loading={requestDevices.loading}>
        {requestDevices.data?.ListUnit?.map(device => (
          <Box mt={2} key={device.NumDevice}>
            <Paper>
              <Box p={2}>
                {device.IsRegisterCheck ? (
                  <KktDevice device={device} />
                ) : (
                  <div>
                    <ListItemText
                      primary={device.NameDevice}
                      secondary={device.TypeDevice}
                    />
                    {!device.Active && (
                      <div>
                        <Alert severity="warning">Не активен</Alert>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Paper>
          </Box>
        ))}
      </WithLoading>
    </div>
  );
};
