import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Paper,
  Checkbox,
  IconButton,
  Link,
  Box,
} from '@material-ui/core';
import { typeProduct, typeProductComponent } from 'src/types';
import { Table } from 'src/components/common/table';
import {
  useChangeProduct,
  useDeleteComponent,
  useGetProductsByCmponent,
} from 'src/api/productApi';
import { Close } from '@material-ui/icons';

type typeProps = {
  components?: typeProductComponent[] | null;
  onFetch?: (params?: { limit: number; offset: number }) => any;
  onCancel?: any;
  loading: boolean;
  limit?: number;
  offset?: number;
  product?: typeProduct | null;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      title: {
        flex: '1 1 100%',
      },
    }),
  { name: 'ProductComponents' },
);

export const ProductComponents: React.FunctionComponent<typeProps> = ({
  components,
  loading,
  onFetch,
  onCancel,
  limit,
  offset,
  product,
}) => {
  const classes = useStyles({});

  const [change, data] = useChangeProduct();

  const handleChange = (mainComponent: typeProductComponent) =>
    change({ id: mainComponent.product.id, mainComponent }).then(() => {
      if (onFetch) {
        onFetch();
      }
    });

  const [del] = useDeleteComponent();
  const products = useGetProductsByCmponent({
    variables: { productComponentId: product?.id },
    skip: !product?.id,
  });

  const handleDeleteComponent = (component: typeProductComponent) =>
    del(component).then(() => {
      if (onFetch) {
        onFetch();
      }
    });

  return (
    <div className={classes.root}>
      <Paper>
        <Table
          title="Компоненты узла"
          data={components}
          totalCount={components?.length}
          headers={[
            {
              title: 'id',
              extract: (item: typeProductComponent) => item.id,
              component: 'th',
              scope: 'row',
            },
            {
              title: 'Бренд',
              extract: (item: typeProductComponent) =>
                item.component?.brand?.originalName,
            },
            {
              title: 'Артикул',
              extract: (item: typeProductComponent) => (
                <Link href={`/products/${item.component.id}`}>
                  {item.component.article}
                </Link>
              ),
            },
            {
              title: 'Название',
              extract: (item: typeProductComponent) => item.component.nameValue,
            },
            {
              title: 'Главный',
              extract: (item: typeProductComponent) => (
                <Checkbox
                  checked={Boolean(product?.mainComponent?.id === item.id)}
                  onChange={() => handleChange(item)}
                />
              ),
            },
            {
              title: '',
              extract: (item: typeProductComponent) =>
                Boolean(product?.mainComponent?.id !== item.id) && (
                  <IconButton
                    onClick={() => handleDeleteComponent(item)}
                    title="Удалить компонент"
                  >
                    <Close />
                  </IconButton>
                ),
            },
          ]}
          loading={loading}
          onCancel={onCancel}
          onFetch={onFetch}
          limit={limit}
          offset={offset}
        />
      </Paper>
      <Box mt={4}>
        <Paper>
          <Table
            title="Продукт является компонентом"
            data={products?.data || []}
            totalCount={products?.data?.length || 0}
            headers={[
              {
                title: 'id',
                extract: (item: typeProductComponent) => item.id,
                component: 'th',
                scope: 'row',
              },
              {
                title: 'Бренд',
                extract: (item: typeProductComponent) =>
                  item.product?.brand?.originalName,
              },
              {
                title: 'Артикул',
                extract: (item: typeProductComponent) => (
                  <Link href={`/products/${item.product.id}`}>
                    {item.product.article}
                  </Link>
                ),
              },
              {
                title: 'Название',
                extract: (item: typeProductComponent) =>
                  item.product.nameValue,
              },
            ]}
            loading={loading}
            onCancel={onCancel}
            onFetch={onFetch}
            limit={limit}
            offset={offset}
          />
        </Paper>
      </Box>
    </div>
  );
};
