import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import { typeBrand, typeListObject } from 'src/types';

export function useGetBrands(query?: {
  limit?: number;
  offset?: number;
  isCarManufacturer?: boolean;
}) {
  return useRequest<typeListObject<typeBrand>, typeof query>({
    url: '/brands',
    method: 'get',
    query,
  });
}

export function useGetBrand({
  variables,
  skip,
}: {
  variables: { brandId?: number };
  skip: boolean;
}) {
  return useRequest<typeBrand>({
    url: `/brands/${variables.brandId}`,
    method: 'get',
    skip,
  });
}

export function useCreateBrand() {
  return useRequestWithPayload<
     typeBrand,
    typeBrand & {
      undefinedBrandId?: number | string | null;
    }
  >({
    url: '/brands',
    method: 'post',
  });
}

export function useGetUndefinedBrands({
  variables,
  skip,
}: {
  variables?: {
    limit?: number;
    offset?: number;
    name?: string;
  };
  skip?: boolean;
}) {
  return useRequest<any>({
    url: `/brands/undefined`,
    method: 'get',
    skip,
    query: variables,
  });
}
