import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Box,
  Paper,
  Grid,
  Typography,
  Badge,
  Tooltip,
  IconButton,
  Button,
  TextField,
  Fab,
} from '@material-ui/core';
import { Table } from 'src/components/common/table';
import cn from 'clsx';
import {
  typeOrderElement,
  typeOrderRealizationElement,
  typeOrderToSupplierElement,
  typeOrderElementRetrun,
  typeBillingJournalReason,
  typeBillingJournalType,
  typeCustomer,
  typeOrder,
  typeContractor,
} from 'src/types';
import {
  useGetOrder,
  useCreateOrderRealization,
  useCreateOrderToSupplier,
  useCreateOrderElementReturn,
  useChangeOrder,
  deleteOrderElementReturn,
} from 'src/api/ordersApi';
import {
  Close,
  Print,
  Remove,
  Replay,
  Settings,
  Store,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { useParams } from 'react-router';
import { StockItemListItem } from 'src/components/StockItemListItem';
import { OrderElementCreate } from 'src/components/OrderElementCreate';
import { BillingJournalCreate } from 'src/components/BillingJournalCreate';
import { BillingJournalDialog } from 'src/components/BillingJournalDialog';
import { useGetBillingJournal } from 'src/api/billingApi';
import { OrderElementDialog } from 'src/components/OrderElementDialog';
import { OrderToSupplierElementDialog } from 'src/components/OrderToSupplierElementDialog';
import { WithConfirm } from 'src/components/WithConfirm';
import { Customer } from 'src/components/Customer';
import { WithLoading } from 'src/components/common/with-loading';
import { DeliverySelect } from 'src/components/DeliverySelect';
import { CustomerSelect } from '../CustomerSelect';
import { Delivery } from './Delivery';
import { CustomerCreateDialog } from '../CustomerCreateDialog';
import { ContractorSelect } from '../ContractorSelect';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      cheaper: {
        color: 'red',
      },
      expensive: {
        color: 'green',
      },
    }),
  { name: 'OrderPage' },
);

export const OrderPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});
  const { orderId } = useParams<{ orderId: string }>();

  const [state, setState] = useState<{
    customer?: typeCustomer | null;
    contractor?: typeContractor | null;
    privateComment?: string | null;
  }>({
    privateComment: '',
  });

  const order = useGetOrder({
    variables: { orderId },
  });

  useEffect(() => {
    if (order.data) {
      setState({
        ...state,
        privateComment: order.data?.privateComment,
      });
    }
  }, [order.data]);

  const orderRefetch = () => order.refetch({ orderId });

  const journal = useGetBillingJournal({
    variables: { orderId },
  });

  const [createRealization] = useCreateOrderRealization({
    variables: { orderId },
  });
  const [createOrderToSupplier] = useCreateOrderToSupplier({
    variables: { orderId },
  });
  const [createOrderElementReturn] = useCreateOrderElementReturn({
    variables: { orderId },
  });
  const [changeOrder, changeOrderRequest] = useChangeOrder({
    variables: { orderId },
  });

  const handleCreateOrderToSupplier = () => {
    createOrderToSupplier({
      order: { id: order?.data?.id },
      elements: order?.data?.elements?.map(orderElement => ({
        orderElement,
        startPrice: orderElement.offerPrice,
        requestedQuantity: orderElement.quantity,
        providedQuantity: orderElement.quantity,
      })),
    }).then(orderRefetch);
  };

  const handleCreateRealization = () => {
    createRealization({
      order: order?.data,
      elements: order?.data?.elements?.map(orderElement => ({
        orderElement,
        offerPrice: orderElement.offerPrice,
        quantity: orderElement.orderToSupplierElement?.providedQuantity,
      })),
    }).then(orderRefetch);
  };

  const handleCreateReturnRealizationElement = (
    item: typeOrderRealizationElement,
  ) => {
    createOrderElementReturn({
      orderElement: item?.orderElement,
      quantity: item?.quantity,
    }).then(orderRefetch);
  };

  const handeChangeOrder = (order: typeOrder) =>
    changeOrder(order).then(orderRefetch);

  const handleDeleteElement = (id?: number) =>
    id && deleteOrderElementReturn(id)?.then(orderRefetch);

  const isQuantityError = (item: typeOrderElement) =>
    Boolean(
      item?.stockItem &&
        item?.quantity &&
        item?.quantity > 0 &&
        item?.quantity > item?.stockItem?.quantity,
    );

  const paymentsValue =
    journal?.data?.data?.reduce((prev, curr) => {
      return (
        (curr?.posts?.reduce((prevPostValue, currPost) => {
          if (!currPost?.account?.contractor) {
            return prevPostValue;
          }

          if (currPost?.value && currPost?.value < 0) {
            return prevPostValue;
          }
          return (currPost?.value || 0) + prevPostValue;
        }, 0) || 0) + prev
      );
    }, 0) || 0;

  const paymentsReturnValue =
    journal?.data?.data?.reduce((prev, curr) => {
      return (
        (curr?.posts
          ?.filter(
            post =>
              [1, 2].includes(post.journal?.reason?.id as number) &&
              post?.value &&
              post?.value < 0,
          )
          ?.reduce((prevPostValue, currPost) => {
            if (
              currPost?.account?.contractor?.id !== order?.data?.contractor?.id
            ) {
              return prevPostValue;
            }

            return (currPost?.value || 0) + prevPostValue;
          }, 0) || 0) + prev
      );
    }, 0) || 0;

  const remainCost = order?.data?.remainingCost
    ? order?.data?.remainingCost
    : 0;

  const returnsCost = order?.data?.returnsCost ? order?.data?.returnsCost : 0;

  const returns = order?.data?.elements?.reduce(
    (prev: any, curr: any) => {
      if (curr?.orderElementReturns) {
        return [
          ...prev,
          ...curr?.orderElementReturns?.map((item: any) => ({
            ...item,
            orderElement: curr,
          })),
        ];
      }
      return [...prev];
    },

    [],
  );

  return (
    <div className={classes.root}>
      <Typography variant="h4">
        Заказ #{orderId} от{' '}
        {order?.data?.createdAt &&
          format(new Date(order?.data?.createdAt), 'yyyy-MM-dd HH:mm:ss')}
      </Typography>

      <Paper>
        <Box mt={2} p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <ContractorSelect
                contractor={order.data?.contractor}
                onSelect={contractor => handeChangeOrder({ contractor })}
              />

              <Box display="flex" justifyItems="center" mt={2}>
                <CustomerSelect
                  label="Выбор клиента"
                  customer={state.customer}
                  onSelect={customer => setState({ ...state, customer })}
                />
              </Box>
              {order.data?.customer && (
                <Box>
                  <Typography variant="h6">Клиент</Typography>
                  <Box mt={2}>
                    <Customer
                      customer={order.data?.customer}
                      onChange={orderRefetch}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              {order.data?.comment && (
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Комментарий клиента"
                    multiline
                    variant="outlined"
                    disabled
                    value={order.data?.comment || ''}
                  />
                </Box>
              )}

              <TextField
                fullWidth
                label="Приватный комментарий"
                multiline
                variant="outlined"
                onChange={e =>
                  setState({ ...state, privateComment: e.target.value })
                }
                value={state.privateComment || ''}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DeliverySelect
                disabled={Boolean(order.data?.delivery?.finishedAt)}
                customer={order.data?.customer}
                delivery={order.data?.delivery}
                order={order.data}
                onCreate={delivery => handeChangeOrder({ ...state, delivery })}
                onChange={delivery => handeChangeOrder({ ...state, delivery })}
              />
              <Delivery delivery={order.data?.delivery} />
            </Grid>
            <Grid item xs={12} md={3}>
              {order.data?.orderCustomerSource && (
                <TextField
                  fullWidth
                  label="Где нашел"
                  variant="outlined"
                  disabled
                  value={order.data?.orderCustomerSource.name}
                />
              )}
              {order.data?.orderSource && (
                <Box mt={2}>
                  <TextField
                    fullWidth
                    label="Как обратился"
                    variant="outlined"
                    disabled
                    value={order.data?.orderSource.name || ''}
                  />
                </Box>
              )}
            </Grid>
            <Grid xs={12}>
              <Box p={1} display="flex" justifyContent="flex-end">
                <WithLoading loading={changeOrderRequest.loading}>
                  <Button
                    disabled={changeOrderRequest.loading}
                    color="primary"
                    variant="contained"
                    onClick={() => handeChangeOrder(state)}
                  >
                    Сохранить
                  </Button>
                </WithLoading>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box mt={6}>
        <Grid container>
          <Grid item sm={12} md={7}>
            {!order?.data?.ordersToSuppliers?.length && (
              <OrderElementCreate orderId={orderId} onChange={orderRefetch} />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              height="100%"
              fontSize="1.5rem"
            >
              <Box mr={1}>
                Сумма товаров в заказе:{' '}
                <b title="Сумма товаров в заказе">
                  {order?.data?.totalCost && order?.data?.totalCost / 100} ₽
                </b>
              </Box>
              {Boolean(returnsCost) && (
                <>
                  Возвраты товаров:{' '}
                  <Box ml={1} mr={1} title="Возвраты товаров">
                    <b>{returnsCost / 100} ₽</b>
                  </Box>
                </>
              )}
              <BillingJournalDialog
                journal={journal?.data?.data}
                cancel={journal?.cancel}
                refetch={() => journal?.refetch({ orderId })}
                loading={journal?.loading}
                totalCount={journal?.data?.totalCount}
              >
                <Box display="flex" style={{ cursor: 'pointer' }}>
                  {Boolean(paymentsValue) && (
                    <>
                      -
                      <Box
                        ml={1}
                        mr={1}
                        color="green"
                        title="Поступления средств"
                      >
                        <b>{paymentsValue / 100} ₽</b>
                      </Box>
                    </>
                  )}
                  {Boolean(paymentsReturnValue) && (
                    <>
                      +
                      <Box ml={1} mr={1} color="red" title="Возвраты средств">
                        <b>{(paymentsReturnValue / 100) * -1} ₽</b>
                      </Box>
                    </>
                  )}
                </Box>
              </BillingJournalDialog>
              =
              <Box
                ml={1}
                color={remainCost < 0 ? 'green' : 'red'}
                title={remainCost < 0 ? 'Переплата' : 'Долг'}
              >
                <b>{remainCost / 100} ₽</b>
              </Box>
              <Box title="Оплата" ml={4}>
                <BillingJournalCreate
                  filterType={(item: typeBillingJournalType) =>
                    Boolean(item?.id && [1].includes(item?.id))
                  }
                  filterReason={(item: typeBillingJournalReason) =>
                    Boolean(item?.id && [1, 2, 37].includes(item?.id))
                  }
                  value={(remainCost < 0 ? remainCost * -1 : remainCost) / 100}
                  onCreate={() => {
                    journal?.refetch({ orderId });
                    orderRefetch();
                  }}
                  order={order?.data}
                  contractor={order?.data?.contractor}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Paper>
          <Table
            withPagination={false}
            title="Товары"
            data={
              order?.data?.elements?.sort((a, b) =>
                a?.id && b?.id && a?.id > b?.id ? -1 : 1,
              ) || []
            }
            totalCount={order?.data?.elements?.length || 0}
            actions={[
              () => (
                <Fab
                  color="primary"
                  size="small"
                  target="_blank"
                  href={`https://rs125.ru/orders/${order?.data?.id}?h=${order?.data?.hash}`}
                >
                  <Print />
                </Fab>
              ),
              () => (
                <Fab
                  color="primary"
                  size="small"
                  target="_blank"
                  href={`https://rs125.ru/orders/${order?.data?.id}?warehousePlace=true&h=${order?.data?.hash}`}
                >
                  <Store />
                </Fab>
              ),
            ]}
            headers={[
              {
                title: 'Товар',
                extract: (item: typeOrderElement) =>
                  item?.stockItem && (
                    <StockItemListItem
                      withVehicle
                      stockItem={item?.stockItem}
                      withPrice={false}
                    />
                  ),

                scope: 'row',
              },
              {
                title: 'Количество',
                extract: (item: typeOrderElement) => (
                  <Tooltip
                    title="Недостаточное количество"
                    open={isQuantityError(item) ? undefined : false}
                  >
                    <Badge
                      invisible={!isQuantityError(item)}
                      color="secondary"
                      badgeContent={item?.stockItem?.quantity}
                    >
                      <span>{item?.quantity} шт.</span>
                    </Badge>
                  </Tooltip>
                ),

                scope: 'row',
              },
              {
                title: 'Сумма',
                align: 'right',
                extract: (item: typeOrderElement) => (
                  <Tooltip
                    title={`Актуальная продажная цена за штуку ${
                      item?.stockItem?.offerPrice &&
                      item?.stockItem?.offerPrice / 100
                    } ₽`}
                  >
                    <Box
                      style={{ cursor: 'pointer' }}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                      className={cn(
                        item?.offerPrice &&
                          item?.stockItem?.offerPrice &&
                          item?.offerPrice > item?.stockItem?.offerPrice &&
                          classes.expensive,
                        item?.offerPrice &&
                          item?.stockItem?.offerPrice &&
                          item?.offerPrice < item?.stockItem?.offerPrice &&
                          classes.cheaper,
                      )}
                    >
                      <div title="Сумма">
                        <b>{item?.totalCost && item?.totalCost / 100} ₽</b>
                      </div>
                      <div title="Цена за штуку">
                        {item?.offerPrice && item?.offerPrice / 100} ₽
                      </div>
                    </Box>
                  </Tooltip>
                ),

                scope: 'row',
              },
              {
                title: 'Действия',
                align: 'right',
                extract: (item: typeOrderElement) => (
                  <Tooltip
                    title="Товар уже в заказе поставщику"
                    open={
                      Boolean(item.orderToSupplierElement) ? undefined : false
                    }
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <Box>
                        <OrderElementDialog
                          orderElement={item}
                          onSave={orderRefetch}
                          disabled={Boolean(item.orderToSupplierElement)}
                        >
                          <IconButton
                            disabled={Boolean(item.orderToSupplierElement)}
                          >
                            <Settings />
                          </IconButton>
                        </OrderElementDialog>
                      </Box>
                      <Box ml={1}>
                        <WithConfirm
                          onConfirm={() => handleDeleteElement(item?.id)}
                          confirmTitle="Удалить"
                          disabled={Boolean(item.orderToSupplierElement)}
                        >
                          <IconButton
                            disabled={Boolean(item.orderToSupplierElement)}
                          >
                            <Close />
                          </IconButton>
                        </WithConfirm>
                      </Box>
                    </Box>
                  </Tooltip>
                ),

                scope: 'row',
              },
            ]}
            loading={order?.loading}
            limit={order?.data?.elements?.length || 0}
            offset={0}
          />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <WithConfirm
              onConfirm={handleCreateOrderToSupplier}
              confirmTitle="Создать"
              disabled={Boolean(
                !order?.data?.elements?.length ||
                  order?.data?.ordersToSuppliers?.length,
              )}
            >
              <Button
                color="primary"
                variant="contained"
                disabled={Boolean(
                  !order?.data?.elements?.length ||
                    order?.data?.ordersToSuppliers?.length,
                )}
              >
                Создать заказ поставщику
              </Button>
            </WithConfirm>
          </Box>
        </Paper>
      </Box>
      {order?.data?.ordersToSuppliers?.map(orderToSupplier => (
        <Box mt={2} key={orderToSupplier.id}>
          <Paper>
            <Table
              collapsable
              withPagination={false}
              title={`Заказ поставщику от ${
                orderToSupplier.createdAt &&
                format(
                  new Date(orderToSupplier.createdAt),
                  'yyyy-MM-dd HH:mm:ss',
                )
              }`}
              data={
                orderToSupplier?.elements?.sort((a, b) =>
                  a?.id && b?.id && a?.id > b?.id ? -1 : 1,
                ) || []
              }
              totalCount={orderToSupplier?.elements?.length || 0}
              headers={[
                {
                  title: 'Товар',
                  extract: (item: typeOrderToSupplierElement) =>
                    item?.orderElement?.stockItem && (
                      <StockItemListItem
                        stockItem={item?.orderElement?.stockItem}
                        withPrice={false}
                        withVehicle
                      />
                    ),

                  scope: 'row',
                },
                {
                  title: 'Место',
                  extract: (item: typeOrderToSupplierElement) =>
                    item?.orderElement?.stockItem?.warehousePlace?.name,

                  scope: 'row',
                },
                {
                  title: 'Требуемое количество',
                  extract: (item: typeOrderToSupplierElement) => (
                    <span>{item?.requestedQuantity} шт.</span>
                  ),

                  scope: 'row',
                },
                {
                  title: 'Поставленное количество',
                  extract: (item: typeOrderToSupplierElement) =>
                    item?.providedQuantity !== null && (
                      <span>{item?.providedQuantity} шт.</span>
                    ),

                  scope: 'row',
                },
                {
                  title: 'Сумма',
                  align: 'right',
                  extract: (item: typeOrderToSupplierElement) => (
                    <Box
                      style={{ cursor: 'pointer' }}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                    >
                      <div title="Сумма">
                        <b>{item?.totalCost && item?.totalCost / 100} ₽</b>
                      </div>
                      <div title="Цена за штуку">
                        {item?.startPrice && item?.startPrice / 100} ₽
                      </div>
                    </Box>
                  ),

                  scope: 'row',
                },
                {
                  title: 'Действия',
                  align: 'right',
                  extract: (item: typeOrderToSupplierElement) => (
                    <OrderToSupplierElementDialog
                      item={item}
                      onSave={orderRefetch}
                      disabled={
                        item.providedQuantity === item.requestedQuantity
                      }
                    >
                      <IconButton
                        disabled={
                          item.providedQuantity === item.requestedQuantity
                        }
                      >
                        <Settings />
                      </IconButton>
                    </OrderToSupplierElementDialog>
                  ),

                  scope: 'row',
                },
              ]}
              loading={order?.loading}
              limit={orderToSupplier?.elements?.length || 0}
              offset={0}
            />
            <Box p={2} display="flex" justifyContent="flex-end">
              <WithConfirm
                onConfirm={handleCreateRealization}
                confirmTitle="Создать"
                disabled={!order?.data?.ordersToSuppliers?.length}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={Boolean(
                    order?.data?.ordersToSuppliers
                      ?.map(item => item.isAllProvided)
                      ?.some(isAllProvided => !isAllProvided) ||
                      !order?.data?.ordersToSuppliers?.length ||
                      order?.data?.realizations?.length,
                  )}
                >
                  Создать реализацию
                </Button>
              </WithConfirm>
            </Box>
          </Paper>
        </Box>
      ))}
      {order?.data?.realizations?.map(realization => (
        <Box mt={2} key={realization.id}>
          <Paper>
            <Table
              withPagination={false}
              title={`Реализация от ${
                realization.createdAt &&
                format(new Date(realization.createdAt), 'yyyy-MM-dd HH:mm:ss')
              }`}
              actions={[
                () => (
                  <Fab
                    color="primary"
                    size="small"
                    target="_blank"
                    href={`https://rs125.ru/realizations/${realization.id}?h=${realization.hash}&print`}
                  >
                    <Print />
                  </Fab>
                ),
              ]}
              data={
                realization?.elements?.sort((a, b) =>
                  a?.id && b?.id && a?.id > b?.id ? -1 : 1,
                ) || []
              }
              totalCount={realization?.elements?.length || 0}
              headers={[
                {
                  title: 'Товар',
                  extract: (item: typeOrderRealizationElement) =>
                    item?.orderElement?.stockItem && (
                      <StockItemListItem
                        stockItem={item?.orderElement?.stockItem}
                        withPrice={false}
                        withVehicle
                      />
                    ),

                  scope: 'row',
                },
                {
                  title: 'Количество',
                  extract: (item: typeOrderRealizationElement) => (
                    <span>{item?.quantity} шт.</span>
                  ),

                  scope: 'row',
                },
                {
                  title: 'Сумма',
                  align: 'right',
                  extract: (item: typeOrderRealizationElement) => (
                    <Box
                      style={{ cursor: 'pointer' }}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                    >
                      <div title="Сумма">
                        <b>{item?.totalCost && item?.totalCost / 100} ₽</b>
                      </div>
                      <div title="Цена за штуку">
                        {item?.offerPrice && item?.offerPrice / 100} ₽
                      </div>
                    </Box>
                  ),

                  scope: 'row',
                },
                {
                  title: 'Действия',
                  align: 'right',
                  extract: (item: typeOrderRealizationElement) => (
                    <WithConfirm
                      confirmTitle="Вернуть"
                      onConfirm={() =>
                        handleCreateReturnRealizationElement(item)
                      }
                      disabled={Boolean(
                        item?.orderElement?.orderElementReturns?.length,
                      )}
                    >
                      <IconButton
                        title="Вернут товар"
                        disabled={Boolean(
                          item?.orderElement?.orderElementReturns?.length,
                        )}
                      >
                        <Replay />
                      </IconButton>
                    </WithConfirm>
                  ),
                  scope: 'row',
                },
              ]}
              loading={order?.loading}
              limit={realization?.elements?.length || 0}
              offset={0}
            />
          </Paper>
        </Box>
      ))}
      {Boolean(returns && returns?.length) && (
        <Box mt={2}>
          <Paper>
            <Table
              withPagination={false}
              title="Возвраты"
              data={returns}
              totalCount={returns?.length || 0}
              headers={[
                {
                  title: 'Дата',
                  extract: (item: typeOrderElementRetrun) =>
                    item.createdAt &&
                    format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss'),

                  scope: 'row',
                },
                {
                  title: 'Товар',
                  extract: (item: typeOrderElementRetrun) =>
                    item?.orderElement?.stockItem && (
                      <StockItemListItem
                        stockItem={item?.orderElement?.stockItem}
                        withPrice={false}
                        withVehicle
                      />
                    ),

                  scope: 'row',
                },
                {
                  title: 'Количество',
                  extract: (item: typeOrderElementRetrun) => (
                    <span>{item?.quantity} шт.</span>
                  ),

                  scope: 'row',
                },
                {
                  title: 'Сумма',
                  align: 'right',
                  extract: (item: typeOrderElementRetrun) => (
                    <Box
                      style={{ cursor: 'pointer' }}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                    >
                      <div title="Сумма">
                        <b>
                          {item?.orderElement?.orderRealizationElement
                            ?.totalCost &&
                            item?.orderElement?.orderRealizationElement
                              ?.totalCost / 100}{' '}
                          ₽
                        </b>
                      </div>
                      <div title="Цена за штуку">
                        {item?.orderElement?.orderRealizationElement
                          ?.offerPrice &&
                          item?.orderElement?.orderRealizationElement
                            ?.offerPrice / 100}{' '}
                        ₽
                      </div>
                    </Box>
                  ),

                  scope: 'row',
                },
                // {
                //   title: 'Действия',
                //   align: 'right',
                //   extract: (item: typeOrderElementRetrun) => (
                //     <WithConfirm
                //       confirmTitle="Вернуть"
                //       onConfirm={() =>
                //         handleCreateReturnRealizationElement(item)
                //       }
                //       disabled={Boolean(item?.orderElement?.orderElementRetrun)}
                //     >
                //       <IconButton title="Вернут товар">
                //         <Replay />
                //       </IconButton>
                //     </WithConfirm>
                //   ),
                //   scope: 'row',
                // },
              ]}
              loading={order?.loading}
              limit={returns?.length || 0}
              offset={0}
            />
          </Paper>
        </Box>
      )}
    </div>
  );
};
