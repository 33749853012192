import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import { typeCustomer } from 'src/types';

export function useGetCustomers(
  {
    variables,
    skip,
  }: {
    variables: { phone?: number | string };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeCustomer[], typeof variables>({
    url: '/customers',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useChangeCustomer() {
  return useRequestWithPayload<typeCustomer, typeCustomer>({
    url: '/customers',
    method: 'patch',
  });
}


export function useCreateCustomer() {
  return useRequestWithPayload<typeCustomer, typeCustomer>({
    url: '/customers',
    method: 'post',
  });
}
