import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  IconButton,
  InputAdornment,
  TextField,
  Theme,
} from '@material-ui/core';
import fuzzy from 'fuzzy';
import { Close } from '@material-ui/icons';
import { useDebounce } from 'src/hooks/useDebounce';

type typeProps = {
  list: any[];
  extract: (item: any) => string;
  onChange: (list: any[] | null) => any;
  label?: string;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'FuzzySearch' },
);

export const FuzzySearch: React.FunctionComponent<typeProps> = ({
  list = [],
  extract,
  onChange,
  label,
}) => {
  const classes = useStyles({});

  const [state, setState] = useState('');

  const debouncedValue = useDebounce(state, 500);

  useEffect(() => {
    if (debouncedValue) {
      // const results = fuzzy.filter(debouncedValue as string, list, { extract });
      // onChange(results.map(item => item.original));
      const results = list.filter(
        item =>
          debouncedValue &&
          extract(item)
            ?.toLocaleLowerCase()
            ?.search((debouncedValue  as string).toLocaleLowerCase()) > 0,
      );
      onChange(results);
    }
  }, [debouncedValue]);

  const handleOnChange = (value: string) => {
    setState(value);
    if (!value) {
      onChange(list);
    }
  };

  return (
    <div className={classes.root}>
      <TextField
        variant="outlined"
        fullWidth
        label={label || 'Поиск'}
        value={state}
        onChange={e => handleOnChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {Boolean(state) && (
                <IconButton onClick={() => handleOnChange('')}>
                  <Close />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
