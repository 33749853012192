import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Grid, ButtonBase } from '@material-ui/core';
import cn from 'classnames';

type typePositions = {
  leftRight: string | null;
  topBottom: string | null;
  frontRear: string | null;
};
type typeProps = typePositions & {
  onChange: (position: any) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '300px',
      },
      button: {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.grey[600],
        padding: theme.spacing(2),
        fontFamily: theme.typography.fontFamily,
      },
      selected: {
        backgroundColor: theme.palette.primary.dark,
        color: '#fff',
      },
    }),
  { name: 'InstallationPositions' },
);

export const InstallationPositions: React.FunctionComponent<typeProps> = ({
  leftRight,
  topBottom,
  frontRear,
  onChange,
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <ButtonBase
              className={cn(
                classes.button,
                frontRear === 'F' && classes.selected,
              )}
              onClick={() =>
                onChange({ frontRear: frontRear === 'F' ? null : 'F' })
              }
            >
              Front
            </ButtonBase>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <ButtonBase
            className={cn(
              classes.button,
              leftRight === 'L' && classes.selected,
            )}
            onClick={() =>
              onChange({ leftRight: leftRight === 'L' ? null : 'L' })
            }
          >
            Left
          </ButtonBase>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ButtonBase
                className={cn(
                  classes.button,
                  topBottom === 'T' && classes.selected,
                )}
                onClick={() =>
                  onChange({ topBottom: topBottom === 'T' ? null : 'T' })
                }
              >
                Top
              </ButtonBase>
            </Grid>
            <Grid item xs={12}>
              <ButtonBase
                className={cn(
                  classes.button,
                  topBottom === 'B' && classes.selected,
                )}
                onClick={() =>
                  onChange({ topBottom: topBottom === 'B' ? null : 'B' })
                }
              >
                Bottom
              </ButtonBase>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <ButtonBase
            className={cn(
              classes.button,
              leftRight === 'R' && classes.selected,
            )}
            onClick={() =>
              onChange({ leftRight: leftRight === 'R' ? null : 'R' })
            }
          >
            Right
          </ButtonBase>
        </Grid>
        <Grid item xs={12}>
          <ButtonBase
            className={cn(
              classes.button,
              frontRear === 'R' && classes.selected,
            )}
            onClick={() =>
              onChange({ frontRear: frontRear === 'R' ? null : 'R' })
            }
          >
            Rear
          </ButtonBase>
        </Grid>
      </Grid>
    </div>
  );
};
