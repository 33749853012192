import { useRequest } from "src/hooks/useRequest";

export function useQuickSearch(
    {
      variables,
      skip,
    }: {
      variables: { query?: string };
      skip?: boolean;
    } = { variables: {} },
  ) {
    return useRequest<any, typeof variables>({
      url: `/quick-search`,
      method: 'get',
      skip,
      query: variables,
    });
  }