import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Theme,
  Typography,
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { Close } from '@material-ui/icons';

interface typeProps {
  onChange: (values: string[]) => any;
  codes?: string[];
}

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'ProductCodeSelect',
});

export const ProductCodeSelect: React.FunctionComponent<typeProps> = ({
  onChange,
  codes,
}) => {
  const classes = useStyles({});

  const [value, setValue] = useState<string[]>([]);
  useEffect(() => {
    if (codes) {
      setValue(codes);
    }
  }, [codes]);
  const handleOnDelete = (codeForDelete: string) => {
    const nextValues = value.filter(item => item !== codeForDelete);
    setValue(nextValues);
    onChange(nextValues);
  };
  const handleOnChange = (addValue: string) => {
    const values = Array.isArray(addValue.split(/,| /))
      ? addValue.split(/,| /)
      : [addValue];

    const nextValues = Array.from(
      new Set([...value, ...values].filter(item => item)),
    ).map(item => item.replace(/\D/g, ''));

    setValue(nextValues);
    onChange(nextValues);
  };

  return (
    <>
      <ChipInput
        label="Код товара"
        fullWidth
        value={value}
        variant="outlined"
        onAdd={handleOnChange}
        onDelete={handleOnDelete}
      />
      <Box mt={1}>
        <Typography variant="subtitle2">
          Количество кодов: {value.length}{' '}
          {Boolean(value.length) && (
            <Button
              size="small"
              color="primary"
              onClick={() => {
                setValue([]);
                onChange([]);
              }}
            >
              очистить
            </Button>
          )}
        </Typography>
      </Box>
    </>
  );
};
