import { useRequest } from 'src/hooks/useRequest';
import { typeModel, typeBrand, typeListObject } from 'src/types';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';

export function useGetModels({
  variables,
  skip,
}: {
  variables: {
    brandId?: number | null;
    limit?: number;
    offset?: number;
  };
  skip?: boolean;
} = { variables: {} }) {
  return useRequest<typeListObject<typeModel>, typeof variables>({
    url: '/models',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useGetModel({
  variables,
  skip,
}: {
  variables: { modelId?: number };
  skip: boolean;
}) {
  return useRequest<typeModel>({
    url: `/models/${variables.modelId}`,
    method: 'get',
    skip,
  });
}

export function useCreateModel() {
  return useRequestWithPayload<typeModel, { name: string; brand: typeBrand }>({
    url: '/models',
    method: 'post',
  });
}
