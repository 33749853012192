import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  List,
  ListItemSecondaryAction,
  Typography,
  ListItemIcon,
  Radio,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';
import { useBillingAccounts, useGetContractors } from 'src/api/billingApi';
import { typeBillingAccount, typeContractor } from 'src/types';
import { green } from '@material-ui/core/colors';

type typeProps = {
  account?: typeBillingAccount | null;
  from?: typeBillingAccount | null;
  to?: typeBillingAccount | null;
  label?: string | null;
  title?: string | null;
  disabled?: boolean;
  contractor?: typeContractor | null;
  onChange?: (account: typeBillingAccount) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      cashBook: {
        fontWeight: 900,
        color: green[300],
        textTransform: 'uppercase',
      },
    }),
  { name: 'BillingAccountSelect' },
);

export const BillingAccountSelect: React.FunctionComponent<typeProps> = ({
  account,
  onChange,
  disabled,
  contractor,
  from,
  to,
  label = 'Счет',
}) => {
  const classes = useStyles({});
  const [open, setOpen] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [value, setValue] = useState<typeBillingAccount | null>(null);

  const { data, loading } = useBillingAccounts();

  const accounts = data?.data?.filter(account => !account?.isClosed);

  useEffect(() => {
    if (accounts && account) {
      setValue(accounts.find(item => item.id === account.id) || null);
    }
  }, [accounts, account]);

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
    setOpen(false);
  };

  const accountsByContractors = useMemo(
    () =>
      data?.data?.reduce(
        (prev, curr) =>
          //@ts-ignore
          prev[curr?.contractor?.alias]
            ? {
                ...prev,
                //@ts-ignore
                [curr?.contractor?.alias]: [
                  //@ts-ignore
                  ...prev[curr?.contractor?.alias],
                  curr,
                ],
              }
            : //@ts-ignore
              { ...prev, [curr?.contractor?.alias]: [curr] },
        {},
      ),
    [data],
  ) as any;

  return (
    <Box className={classes.root} display="flex">
      <TextField
        InputLabelProps={{ shrink: true }}
        label={label}
        value={value?.fullName}
        variant="outlined"
        fullWidth
        onClick={() => setOpen(true)}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          {(from || to) && (
            <Box py={2}>
              {from && `${from?.fullName} (${from.currency})`}
              {' => '}
              {to && `${to?.fullName} (${to.currency})`}
            </Box>
          )}
          <Grid container spacing={4}>
            <Grid xs={12}>
              <Box p={2} display="flex" justifyContent="space-between">
                {
                  //@ts-ignore
                  accountsByContractors?.['undefined']?.map(item => (
                    <Button
                      key={item.id}
                      variant={
                        account?.id === item?.id ? 'contained' : 'outlined'
                      }
                      color="primary"
                      onClick={() => hangdeOnChange(item)}
                    >
                      {item.name}
                    </Button>
                  ))
                }
                <FormControlLabel
                  control={<Checkbox checked={empty} />}
                  onChange={() => setEmpty(!empty)}
                  label="Пустые счета"
                />
              </Box>
            </Grid>
            {accountsByContractors &&
              Object.keys(accountsByContractors)
                ?.sort((a, b) => (a > b ? 1 : -1))
                .map(key => {
                  if (key === 'undefined') return null;
                  return (
                    <Grid
                      xs={12}
                      md={4}
                      key={key}
                      style={{
                        opacity:
                          contractor && contractor.alias !== key ? '0.5' : 1,
                      }}
                    >
                      <Box p={2}>
                        <Typography>
                          {accountsByContractors[key]?.[0]?.contractor?.name}
                        </Typography>
                        <List disablePadding>
                          {accountsByContractors[key]
                            ?.sort(
                              (a: typeBillingAccount, b: typeBillingAccount) =>
                                a.name && b.name && a.name > b.name ? 1 : -1,
                            )
                            ?.map((item: typeBillingAccount) => {
                              if (item.isClosed) return null;
                              if (!empty && !item.balance) return null;
                              return (
                                <ListItem
                                  key={item.id}
                                  dense
                                  divider
                                  button
                                  onClick={() => hangdeOnChange(item)}
                                >
                                  <ListItemIcon>
                                    <Radio checked={account?.id === item.id} />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={`${item.fullName} (${item.currency})`}
                                  />
                                  <ListItemSecondaryAction>
                                    {item.balance &&
                                      (item.balance / 100).toLocaleString(
                                        'ru-RU',
                                      )}
                                  </ListItemSecondaryAction>
                                </ListItem>
                              );
                            })}
                        </List>
                      </Box>
                    </Grid>
                  );
                })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
      {/* <Autocomplete
        disabled={disabled}
        fullWidth
        value={value}
        onChange={(e, value: any) => hangdeOnChange(value)}
        options={accounts || []}
        renderOption={(option: any) => (
          <span
            className={
              Boolean(option?.id && option?.id === 1) ? classes.cashBook : ''
            }
          >
            {`${option.name} ${option.currency ? `[${option.currency}]` : ''}`}
          </span>
        )}
        getOptionLabel={(option: any) =>
          `${option.name} ${option.currency ? `[${option.currency}]` : ''}`
        }
        label={label || 'Счет'}
        loading={loading}
      /> */}
    </Box>
  );
};
