import axios, { AxiosRequestConfig, Canceler } from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
});

type Options = {
  setCanceler: (canceler: Canceler) => any;
};

const defaultOptions: Options = {
  setCanceler: () => null,
};

export const request = {
  get(
    url: string,
    { setCanceler, ...rest }: AxiosRequestConfig & Options = defaultOptions,
  ) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setCanceler(source.cancel);

    return axiosInstance.get(url, {
      ...rest,
      cancelToken: source.token,
    });
  },
  delete(
    url: string,
    { setCanceler, ...rest }: AxiosRequestConfig & Options = defaultOptions,
  ) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setCanceler(source.cancel);

    return axiosInstance.delete(url, {
      ...rest,
      cancelToken: source.token,
    });
  },
  post(
    url: string,
    data: any,
    { setCanceler, ...rest }: AxiosRequestConfig & Options = defaultOptions,
  ) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setCanceler(source.cancel);

    return axiosInstance.post(url, data, {
      ...rest,
      cancelToken: source.token,
    });
  },
  put(
    url: string,
    data: any,
    { setCanceler, ...rest }: AxiosRequestConfig & Options = defaultOptions,
  ) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setCanceler(source.cancel);

    return axiosInstance.put(url, data, {
      ...rest,
      cancelToken: source.token,
    });
  },
  patch(
    url: string,
    data: any,
    { setCanceler, ...rest }: AxiosRequestConfig & Options = defaultOptions,
  ) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setCanceler(source.cancel);

    return axiosInstance.patch(url, data, {
      ...rest,
      cancelToken: source.token,
    });
  },
};
