import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { useCreateAttribute } from 'src/entities/attribute';

import { ProductTypeAttributeType } from 'src/shared/api';

interface Props {
  onResponse: () => any;
}

const hints = {
  [ProductTypeAttributeType.bool]: 'да / нет',
  [ProductTypeAttributeType.string]: 'сезон, цвет, и т.д',
  [ProductTypeAttributeType.numeric]: 'ширина, объем, вес, количество и т.д',
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'AttributesPage' },
);

export const AttributeCreateDialog: React.FunctionComponent<Props> = ({
  onResponse,
}) => {
  const [open, setOpen] = useState(false);

  const [name, setName] = useState('');

  const [type, setType] = useState<ProductTypeAttributeType>(
    ProductTypeAttributeType.string,
  );

  const [create] = useCreateAttribute();

  const handleOpen = () => {
    setOpen(true);
    setName('');
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
  };

  const handleChangeName = (e: any) => setName(e.currentTarget.value);

  const handleConfirm = () =>
    create({ name: name.trim(), type }).then(() => {
      onResponse();
      handleClose();
    });

  return (
    <>
      <Fab
        size="small"
        onClick={handleOpen}
        color="secondary"
        title="Создать атрибут"
      >
        <Add />
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Создание атрибута</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            value={name}
            onChange={handleChangeName}
            label="Название"
          />
          <Box mt={2}>
            <Select
              fullWidth
              variant="outlined"
              value={type}
              onChange={e =>
                setType(e.target.value as ProductTypeAttributeType)
              }
            >
              <MenuItem value={ProductTypeAttributeType.string}>
                {ProductTypeAttributeType.string}
              </MenuItem>
              <MenuItem value={ProductTypeAttributeType.numeric}>
                {ProductTypeAttributeType.numeric}
              </MenuItem>
              <MenuItem value={ProductTypeAttributeType.bool}>
                {ProductTypeAttributeType.bool}
              </MenuItem>
            </Select>
          </Box>
          <Box mt={2}>{hints[type]}</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!name}
            onClick={handleConfirm}
          >
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
