import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { typeBrand } from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';
import { useCreateBrand } from 'src/api/brandApi';

type typeProps = {
  onCreate: () => any;
  originalName?: string;
  undefinedBrand?: any;
};

type typeState = {
  dialog: boolean;
  isCarManufacturer: boolean;
  originalName?: string;
};

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'BrandCreate',
});

export const BrandCreate: React.FunctionComponent<typeProps> = ({
  onCreate,
  undefinedBrand,
}) => {
  const classes = useStyles({});

  const [state, changeState] = useState<typeState>({
    dialog: false,
    originalName: '',
    isCarManufacturer: false,
  });

  const setState = (data: Partial<typeState>) =>
    changeState((prevState: typeState) => ({ ...prevState, ...data }));

  useEffect(() => {
    if (undefinedBrand?.name) {
      setState({
        dialog: Boolean(undefinedBrand),
        originalName: undefinedBrand?.name,
      });
    }
  }, [undefinedBrand]);

  const [createBrand, { loading }] = useCreateBrand();

  const handleCreateModel = () => {
    if (state.originalName) {
      createBrand({
        undefinedBrandId: undefinedBrand?.id,
        originalName: state.originalName,
        isCarManufacturer: state.isCarManufacturer,
      }).then(() => {
        setState({ dialog: false, originalName: '', isCarManufacturer: false });
        onCreate();
      });
    }
  };

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setState({ dialog: true })}>
        <Add />
      </Fab>
      <Dialog open={state.dialog} onClose={() => setState({ dialog: false })}>
        <DialogContent>
          <TextField
            label="Бренд"
            autoFocus
            onChange={e => setState({ originalName: e.target.value })}
            value={state.originalName}
          />
          <Box mt={2}>
            <FormControlLabel
              onChange={() =>
                setState({ isCarManufacturer: !state.isCarManufacturer })
              }
              control={<Checkbox checked={state.isCarManufacturer} />}
              label="Автопроизводитель"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState({ dialog: false })}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreateModel}
              color="primary"
              variant="contained"
              disabled={!state.originalName}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
