import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { WithLoading } from 'src/components/common/with-loading';
import { typeProduct } from 'src/types';
import { ProductSelect } from 'src/components/product-select';
import { useAddComponent } from 'src/api/productApi';
import { useAddAnalog } from 'src/api/analogsApi';

type typeProps = {
  product?: typeProduct | null;
  onChange: () => any;
  size?: 'small' | 'medium' | 'large';
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'AnalogCreate' },
);

export const AnalogCreate: React.FunctionComponent<typeProps> = ({
  product,
  onChange,
  size = 'medium',
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);

  const [analog, setAnalog] = useState<typeProduct | null>(null);

  const [reversed, setReversed] = useState(true);

  const [add, { data, loading }] = useAddAnalog();

  const handleAdd = () => {
    if (product && analog) {
      add({ product, analog, source: 'restan', reversed }).then(() => {
        setAnalog(null);
        setDialog(false);
        onChange();
      });
    }
  };

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setDialog(true)} size={size}>
        <Add />
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)} fullWidth>
        <DialogContent>
          <Box mt={2}>
            <ProductSelect disabled product={product} />
          </Box>
          <Box mt={2}>
            <ProductSelect
              label="Аналог"
              product={analog}
              onChange={(value: typeProduct | null) => setAnalog(value)}
            />
          </Box>
          <Box mt={2}>
            <FormControlLabel
              onChange={() => setReversed(!reversed)}
              control={<Checkbox checked={reversed} />}
              label="Добавить продукт в качестве аналога к аналогу"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleAdd}
              color="primary"
              variant="contained"
              disabled={!analog || !analog}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
