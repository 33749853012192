import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, IconButton, Theme } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';
import { typeProductType, typeProductTypeAlias } from 'src/types';
import { orderBy } from 'lodash';
import { useGetProductAliases } from 'src/api/productTypeApi';
import { ProductTypeCreate } from './ProductTypeCreate';
import { Link } from '@material-ui/icons';

type typeProps = {
  type?: typeProductType | null;
  withCreate?: boolean | null;
  onSelect: (type?: typeProductType | null) => any;
  onChange?: (type?: typeProductType | null) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      muted: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.grey[300],
      },
    }),
  { name: 'ProductTypeSelect' },
);

export const ProductTypeSelect: React.FunctionComponent<typeProps> = ({
  type,
  onSelect,
  onChange,
  withCreate = true,
}) => {
  const classes = useStyles({});

  const { data, loading, refetch } = useGetProductAliases();

  const [value, setValue] = useState<typeProductTypeAlias | null>(null);

  useEffect(() => {
    const valueFromArray =
      data && type
        ? (data?.find(
            (item: any) => type && item?.type?.id === type?.id,
          ) as typeProductTypeAlias)
        : null;
    if (valueFromArray) {
      setValue(valueFromArray);
    }
  }, [type, data]);

  return (
    <Box className={classes.root} display="flex" alignItems="center">
      <Autocomplete
        clearOnBlur={false}
        fullWidth
        value={value}
        onChange={(e: any, type: typeProductTypeAlias) => onSelect(type?.type)}
        options={orderBy(data || [], 'name')}
        getOptionLabel={(option: typeProductTypeAlias) => option.name}
        renderOption={(option: typeProductTypeAlias) => (
          <div>
            <div>{option.type?.name}</div>
            <div className={classes.muted}>{option.name}</div>
          </div>
        )}
        onChangeTextField={(e: any) => {
          if (onChange) onChange(e.target.value?.type);
        }}
        label="Тип продукта"
        loading={loading}
        actions={
          value || type
            ? [
                <IconButton
                  size="small"
                  target="_blank"
                  href={`/product-types/${value?.id || type?.id}`}
                  title="Открыть тип товара"
                  onClick={e => e.stopPropagation()}
                >
                  <Link />
                </IconButton>,
              ]
            : []
        }
      />
      {withCreate && (
        <Box ml={1}>
          <ProductTypeCreate onCreate={() => refetch()} />
        </Box>
      )}
    </Box>
  );
};
