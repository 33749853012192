import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';

import { useGetCoutries } from 'src/entities/country';
import { Country } from 'src/shared/api';

interface typeProps {
  onChange?: (value: any) => any;
  country?: Country | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'CountrySelect' },
);

export const CountrySelect: React.FunctionComponent<typeProps> = ({
  onChange,
  country,
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState<Country | null>(null);

  const request = useGetCoutries();

  useEffect(() => {
    if (request.data?.data && country) {
      setValue(
        request.data?.data.find((item: Country) => item.id === country.id) ||
          null,
      );
    }
  }, [request.data?.data, country]);

  const hangdeOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        fullWidth
        value={value}
        onChange={(e, value: any) => hangdeOnChange(value)}
        options={request.data?.data || []}
        getOptionLabel={(option: any) => option.name}
        label="Страна"
        loading={request.loading}
      />
    </div>
  );
};
