import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useGetSupplier } from 'src/api/suppliersApi';
import { MarkupRange } from '../markup-range';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { MarkupCalculate } from '../MarkupCalculate';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'SupplierPage' },
);

export const SupplierPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});
  const { supplierId } = useParams<{ supplierId: string }>();
  const { data, refetch } = useGetSupplier({
    variables: { supplierId },
  });

  return (
    <div className={classes.root}>
      <Typography variant="h4">Поставщик</Typography>
      <Box mt={2}>
        <Typography variant="h5">{data?.name}</Typography>
      </Box>
      <Box mt={2}>
        <LineChart
          width={1800}
          height={300}
          data={data?.markupRanges?.map(item => ({
            ...item,
            price: item.price / 100,
            markup: item.markup / 100,
            offerPrice: (item.price + item.markup) / 100,
          }))}
          margin={{ top: 5, right: 0, bottom: 20, left: 0 }}
        >
          <Line
            type="monotone"
            stroke="#228833"
            dataKey="offerPrice"
            name="Продажная, ₽"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="price"
            name="Закупочная, ₽"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="markup"
            stroke="#8856d8"
            name="Маржа"
            strokeWidth={2}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="price" name="Закупочная, ₽" />
          <YAxis />
          <Tooltip />
        </LineChart>
        <LineChart
          width={1800}
          height={300}
          data={data?.markupRanges?.map(item => ({
            ...item,
            percent: item.markup / 100 / (item.price / 100 / 100),
            price: item.price / 100,
          }))}
          margin={{ top: 5, right: 0, bottom: 20, left: 0 }}
        >
          <Line
            type="monotone"
            dataKey="percent"
            name="Наценка, %"
            strokeWidth={2}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="price" name="Закупочная, ₽" />
          <YAxis />
          <Tooltip />
        </LineChart>
      </Box>
      <Box mt={2}>
        <MarkupCalculate supplier={data} updateTrigger={data} />
      </Box>
      <Box mt={4}>
        {data?.markupRanges
          ?.sort((a, b) => a.price - b.price)
          .map(range => (
            <MarkupRange
              range={range}
              supplier={data}
              onChange={() => refetch({ supplierId })}
            />
          ))}
        <MarkupRange
          range={null}
          supplier={data}
          onChange={() => refetch({ supplierId })}
        />
      </Box>
    </div>
  );
};
