import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import {
  typeOrderRealizationElement,
} from 'src/types';
import {
  LineChart,
  YAxis,
  Tooltip,
  XAxis,
  CartesianGrid,
  Line,
  ReferenceLine,
} from 'recharts';
import { format } from 'date-fns';
import { differenceInDays } from 'date-fns/esm';

interface typeProps {
  items: typeOrderRealizationElement[] | null;
  limit?: number;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'SummaryChart' },
);

export const SummaryChart: React.FunctionComponent<typeProps> = ({
  items,
  limit,
}) => {
  const classes = useStyles({});

  const itemsWithSummary = items
    ?.reduce((prev: any[], cur: typeOrderRealizationElement, index) => {
      if (!prev.length) {
        return [
          {
            createdAt:
              cur.orderElement?.orderRealizationElement?.createdAt &&
              format(
                new Date(cur.orderElement?.orderRealizationElement?.createdAt),
                'yyyy-MM-dd',
              ),
            dateDiff: 0,
            summ: cur.orderElement?.orderRealizationElement?.totalCost,
          },
        ];
      }
      return [
        ...prev,
        {
          createdAt:
            cur.orderElement?.orderRealizationElement?.createdAt &&
            format(
              new Date(cur.orderElement?.orderRealizationElement?.createdAt),
              'yyyy-MM-dd',
            ),
          dateDiff:
            cur.orderElement?.orderRealizationElement?.createdAt &&
            differenceInDays(
              new Date(cur.orderElement?.orderRealizationElement?.createdAt),
              new Date(prev[0].createdAt),
            ),
          totalCost: cur?.orderElement?.orderRealizationElement?.totalCost,
          summ:
            cur?.orderElement?.orderRealizationElement?.totalCost &&
            prev[index - 1] &&
            prev[index - 1].summ
              ? cur?.orderElement?.orderRealizationElement?.totalCost +
                prev[index - 1].summ
              : cur.orderElement?.orderRealizationElement?.totalCost,
        },
      ];
    }, [])
    .reduce((prev, curr, index) => {
      if (!prev.length) return [curr];

      if (prev[prev.length - 1].dateDiff === curr.dateDiff)
        return [
          ...prev.slice(0, prev.length - 1),
          { ...curr, summ: curr.totalCost + prev[prev.length - 1].summ },
        ];
      return [...prev, curr];
    }, [])
    ?.map((item: any) => ({
      ...item,
      //@ts-ignore
      summ: item.summ / 100,
    }));
  return (
    <div className={classes.root}>
      {limit}
      <LineChart
        width={1800}
        height={300}
        data={itemsWithSummary}
        margin={{ top: 5, right: 0, bottom: 20, left: 0 }}
      >
        <ReferenceLine
          y={limit}
          stroke="red"
          // @ts-ignore
          label={limit ? `${limit}` : ''}
          alwaysShow
        />
        <Line
          type="monotone"
          dataKey="summ"
          stroke="#8856d8"
          name="Суммарные продажи"
          strokeWidth={2}
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="1" />
        <XAxis
          dataKey="dateDiff"
          name="Дни"
          type="number"
          interval={0}
          ticks={itemsWithSummary.map((item: any) => item.dateDiff)}
        />
        <YAxis dataKey="summ" name="Сумма" />
        <Tooltip />
      </LineChart>
    </div>
  );
};
