import React, { ChangeEvent, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { createSTOWorkPrice } from 'src/entities/sto-work';
import { ModelSelect } from 'src/components/model-select';
import { BodySelect } from 'src/components/body-select';
import { EngineSelect } from 'src/components/engine-select';
import { STOWork } from 'src/shared/api';

interface typeProps {
  onSave?: () => any;
  stoWork?: STOWork | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'STOWorkPriceCreate' },
);

export const STOWorkPriceCreate: React.FunctionComponent<typeProps> = ({
  onSave,
  stoWork,
}) => {
  const classes = useStyles({});

  const [open, setOpen] = useState(false);

  const [price, setPrice] = useState<null | string>(null);
  const [comment, setComment] = useState<null | string>(null);
  const [model, setModel] = useState<any>(null);
  const [body, setBody] = useState<any>(null);
  const [engine, setEngine] = useState<any>(null);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSave = () => {
    if (price && stoWork) {
      createSTOWorkPrice({
        id: stoWork.id,
        value: {
          comment,
          price: parseInt(price, 10),
          model: model ? { id: model.id } : null,
          body: body ? { id: body.id } : null,
          engine: engine ? { id: engine.id } : null,
        },
      }).then(() => {
        if (onSave) onSave();
        setPrice(null);
        setComment(null);
      });
    }
    handleClose();
  };

  const disabledOpen = !stoWork;

  return (
    <>
      <Fab
        size="small"
        onClick={handleOpen}
        color="secondary"
        disabled={disabledOpen}
      >
        <Add />
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Добавить цену на работу «{stoWork?.name}» для модели
        </DialogTitle>
        <DialogContent>
          <ModelSelect model={model} onChange={setModel} />
          <Box mt={2}>
            <BodySelect model={model} body={body} onChange={setBody} />
          </Box>
          <Box mt={2}>
            <EngineSelect body={body} engine={engine} onChange={setEngine} />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              label="Цена"
              value={price || ''}
              onChange={e => setPrice(e.target.value)}
              type="number"
              variant="outlined"
            />
          </Box>

          <Box mt={2}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Описание"
              value={comment || ''}
              onChange={e => setComment(e.target.value)}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
