import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Box,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { WithLoading } from 'src/components/common/with-loading';
import { useProductType } from 'src/api/productTypeApi';

type typeProps = {
  onCreate: () => any;
  originalName?: string;
  undefinedBrand?: any;
};

type typeState = {
  dialog: boolean;
  isCarManufacturer: boolean;
  originalName?: string;
};

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'ProductTypeCreate',
});

export const ProductTypeCreate: React.FunctionComponent<typeProps> = ({
  onCreate,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);
  const [name, setValue] = useState('');
  const [gender, setGender] = useState(1);

  const [create, { loading }] = useProductType();

  const handleCreateModel = () => {
    if (name) {
      create({
        name,
        target: null,
        category: null,
        gender,
      }).then(() => {
        setValue('');
        onCreate();
        setDialog(false);
      });
    }
  };

  const handleChangeHender = () =>
    gender + 1 > 4 ? setGender(1) : setGender(gender + 1);

  return (
    <div>
      <Fab color="primary" onClick={() => setDialog(true)} size="small">
        <Add />
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <TextField
            variant="outlined"
            label="Название типа"
            autoFocus
            onChange={e => setValue(e.target.value)}
            value={name}
          />
          <Box mt={2}>
            <Button
              onClick={handleChangeHender}
              color="primary"
              variant="contained"
            >
              {['Он', 'Она', 'Оно', 'Они'][gender - 1]}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreateModel}
              color="primary"
              variant="contained"
              disabled={!name}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
};
