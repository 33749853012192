import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { typeDelivery } from 'src/types';
import { format } from 'date-fns';

interface typeProps {
  delivery?: typeDelivery | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'Delivery' },
);

export const Delivery: React.FunctionComponent<typeProps> = ({ delivery }) => {
  const classes = useStyles({});

  if (!delivery) return null;

  return (
    <div className={classes.root}>
      <Box mt={2} fontSize="16px">
        {Boolean(delivery.finishedAt) && (
          <Box mb={1}>
            Коставка закрыта{' '}
            {delivery?.finishedAt &&
              format(new Date(delivery?.finishedAt), 'yyyy-MM-dd HH:mm:ss')}
          </Box>
        )}
        <Box>{delivery?.deliveryCompany?.name}</Box>
        <Box mt={1}>{delivery?.address}</Box>
        {Boolean(delivery.phone) && <Box mt={1}>+7{delivery.phone}</Box>}
        <Box mt={1}>{delivery?.fullName}</Box>
        <Box mt={1}>{delivery?.passportCode}</Box>
        <Box mt={1}>Доставка {delivery?.cost && delivery?.cost / 100} ₽</Box>
        {Boolean(delivery?.isPackage) && (
          <Box mt={1}>
            Дополнительная упаковка{' '}
            {Boolean(delivery?.packageCost) &&
              `${delivery?.packageCost / 100} ₽`}
          </Box>
        )}
        {Boolean(delivery?.insurance) && (
          <Box mt={1}>
            Страховка {delivery?.insurance && delivery?.insurance / 100} ₽
          </Box>
        )}
        {Boolean(delivery?.comment) && <Box mt={1}>{delivery?.comment}</Box>}
      </Box>
    </div>
  );
};
