import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Box,
  Typography,
  Button,
  Grid,
  Link,
  TextField,
} from '@material-ui/core';
import { typeProductType, typeStockItem } from 'src/types';
import { ImageForm } from 'src/components/image-form';
import { useAddImages } from 'src/api/imagesApi';
import { InstallationPositions } from 'src/components/installation-positions';
import { useChangeProduct, useChangeProductName } from 'src/api/productApi';
import { NameSelect } from 'src/components/name-select';
import { WithLoading } from 'src/components/common/with-loading';
import { Analogs } from 'src/components/Analogs';
import { Images } from '../images';
import { ProductUsageCreate } from '../product-usage-create';
import { ProductTypeSelect } from '../ProductTypeSelect';

import { ProductCharacteristicsEdit } from 'src/features/characteristic-edit';

import { Country, Series, Product as typeProduct } from 'src/shared/api';
import { CountrySelect } from 'src/features/coutry-select';
import { SeriesSelect } from 'src/features/series-select';

const excludedAttributes = ['Глубина протектора', 'Износ'];

type typeProps = {
  product?: typeProduct | null;
  stockItem?: typeStockItem | null;
  loading: boolean;
  onChange: () => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        padding: 0,
      },
    }),
  { name: 'Product' },
);

type typeState = {
  name: string | null;
  type: typeProductType | null;
  description: string | null;
  country: Country | null;
  series: Series | null;
  extendedName: string | null;
};

const initialState = {
  name: null,
  type: null,
  description: null,
  country: null,
  series: null,
  extendedName: null,
};

export const Product: React.FunctionComponent<typeProps> = props => {
  const classes = useStyles({});

  const [product, setProduct] = useState<typeProduct | null>(null);

  const [changeName, { loading }] = useChangeProductName();

  const [state, changeState] = useState<typeState>(initialState);

  const setState = (data: Partial<typeState>) =>
    changeState({ ...state, ...data });

  useEffect(() => {
    if (props.product) {
      setProduct(props.product);
    }
  }, [props.product]);

  const [addImages] = useAddImages();

  const [change] = useChangeProduct();

  const handleChangeProduct = (changedProps: any) => {
    if (product) {
      change({ id: product.id, ...changedProps }).then(() => props.onChange());
    }
  };

  const handleSave = () => {
    if (!product) return null;

    if (state.name && product) {
      changeName({
        product,
        supplier: { id: 1, alias: 'restan' },
        ...product?.name,
        value: state.name,
      }).then(() => {
        changeState(initialState);
        props.onChange();
      });
    }

    let nextProduct = {};

    if (state.type) {
      nextProduct = { ...nextProduct, type: state.type };
    }

    if (state.description) {
      nextProduct = { ...nextProduct, description: state.description };
    }

    if (state.extendedName) {
      nextProduct = { ...nextProduct, extendedName: state.extendedName };
    }

    if (state.country) {
      nextProduct = { ...nextProduct, country: state.country };
    }

    if (state.series) {
      nextProduct = { ...nextProduct, series: state.series };
    }

    if (Object.keys(nextProduct)?.length) {
      handleChangeProduct(nextProduct);
    }
  };

  const handleAddImages = (files: File[]) => {
    const images = new FormData();

    if (!props.product) return null;

    images.append('entityId', props?.product?.id?.toString());
    images.append('entityName', 'product');

    files.forEach((file, index) => images.append(`image[${index}]`, file));

    return addImages(images).then((res: any) => {
      props.onChange();
      return res;
    });
  };

  // const handleDeleteImage = (image: typeImage) =>
  // del(image).then(() => {
  //   props.onChange();
  // });

  if (!product) return null;

  const bodies = product?.usage
    ?.filter(item => item.body)
    ?.map(item => item.body?.name);
  const engines = product?.usage
    ?.filter(item => item.engine)
    .map(item => item.engine?.name);

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h4">Продукт</Typography>
        <Typography variant="h5">
          {product?.brand?.originalName}{' '}
          <Link href={`/products/${product.id}`}>{product?.article}</Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NameSelect
          disabled
          name={state.name !== null ? state.name : product?.name?.value}
          onChange={name => setState({ name })}
          onSelect={name => setState({ name })}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductTypeSelect
          type={state.type !== null ? state.type : product?.type}
          onChange={type => setState({ type })}
          onSelect={type => setState({ type })}
        />
      </Grid>
      <Grid item xs={12}>
      <Grid item xs={12}>
        <TextField
          label="Расширенное название"
          variant="outlined"
          fullWidth
          multiline
          onChange={e => setState({ extendedName: e.target.value })}
          value={
            state.extendedName !== null
              ? state.extendedName
              : product?.extendedName || ''
          }
        />
      </Grid>
      </Grid>
      {product?.brand?.id && (
        <Grid item xs={12}>
          <SeriesSelect
            brand={product.brand}
            onChange={series => setState({ series })}
            series={product.series}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          label="Описание"
          variant="outlined"
          fullWidth
          multiline
          onChange={e => setState({ description: e.target.value })}
          value={
            state.description !== null
              ? state.description
              : product?.description || ''
          }
        />
      </Grid>
      <Grid item xs={12}>
        <InstallationPositions
          leftRight={product?.leftRight}
          topBottom={product?.topBottom}
          frontRear={product?.frontRear}
          onChange={handleChangeProduct}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <WithLoading loading={loading}>
            <Button
              disabled={!Object.values(state).some(value => value !== null)}
              onClick={handleSave}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </WithLoading>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ImageForm onAdd={handleAddImages} id="attachProductImages" />
      </Grid>
      <Grid item xs={12}>
        <Images images={product?.images} onChange={props.onChange} />
      </Grid>
      <Grid item xs={12}>
        <CountrySelect
          country={product?.country}
          onChange={country => setState({ country })}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductCharacteristicsEdit
          product={product}
          onSave={props.onChange}
          attributes={product?.type?.attributes?.filter(
            item => !excludedAttributes.includes(item.name),
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Применимость</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={10}>
            {Boolean(bodies?.length) && (
              <Box mt={2}>Кузова: {bodies?.join(', ')}</Box>
            )}
            {Boolean(engines?.length) && (
              <Box mt={2}>Двигатели: {engines?.join(', ')}</Box>
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <ProductUsageCreate product={product} onChange={props.onChange} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Analogs
          withPagination={false}
          product={product}
          analogs={product?.analogs?.map(item => ({ ...item, product }))}
          loading={props.loading}
          onFetch={props.onChange}
        />
      </Grid>
    </Grid>
  );
};
