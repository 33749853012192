import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useGetBillingJournal } from 'src/api/billingApi';
import { typeBillingJournal } from 'src/types';
import { BillingJournal } from './BillingJournal';
import { ExpandMore } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'LoansJournals' },
);

export const LoansJournals: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const variables = {
    from: '2023-08-12+00:00:00',
    reasons: '87,88',
  };

  const { data, loading, refetch, cancel } = useGetBillingJournal({
    variables,
  });

  const postsByOwner = data?.data?.reduce(
    (prev: any, curr: typeBillingJournal) => {
      const { posts } = curr;

      let next: any = { ...prev };

      const sender =
        curr?.reason?.id === 87
          ? posts?.[0]?.account?.ownerName || posts?.[0]?.account?.name
          : posts?.[1]?.account?.ownerName || posts?.[1]?.account?.name;

      const reciever =
        curr?.reason?.id === 87
          ? posts?.[1]?.account?.ownerName || posts?.[1]?.account?.name
          : posts?.[0]?.account?.ownerName || posts?.[0]?.account?.name;

      next[sender as string]
        ? (next = {
            ...next,
            [sender as string]: {
              ...next[sender as string],
              [reciever as string]: next[sender as string]?.[reciever as string]
                ? [
                    ...next[sender as string]?.[reciever as string],
                    { ...posts?.[0], journal: curr },
                  ]
                : [{ ...posts?.[0], journal: curr }],
            },
          })
        : (next = {
            ...next,
            [sender as string]: {
              [reciever as string]: [{ ...posts?.[0], journal: curr }],
            },
          });

      next[reciever as string]
        ? (next = {
            ...next,
            [reciever as string]: {
              ...next[reciever as string],
              [sender as string]: next[reciever as string]?.[sender as string]
                ? [
                    ...next[reciever as string]?.[sender as string],
                    { ...posts?.[1], journal: curr },
                  ]
                : [{ ...posts?.[1], journal: curr }],
            },
          })
        : (next = {
            ...next,
            [reciever as string]: {
              [sender as string]: [{ ...posts?.[1], journal: curr }],
            },
          });
      // posts?.forEach(post =>
      //   next[sender as string]
      //     ? (next = {
      //         ...next,
      //         [sender as string]: {
      //           ...next[sender as string],
      //           [reciever as string]: next[sender as string]?.[
      //             reciever as string
      //           ]
      //             ? [
      //                 ...next[sender as string]?.[reciever as string],
      //                 { ...post, journal: curr },
      //               ]
      //             : [{ ...post, journal: curr }],
      //         },
      //       })
      //     : (next = {
      //         ...next,
      //         [sender as string]: {
      //           [reciever as string]: [{ ...post, journal: curr }],
      //         },
      //       }),
      // );

      return next;
    },
    {},
  );

  return (
    <div className={classes.root}>
      {postsByOwner &&
        Object.entries(postsByOwner)?.map((item: any) => (
          <Box key={item[0]} mb={2} p={2}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">{item[0]?.toUpperCase()}</Typography>

                {Object.entries(item[1])?.map((reciever: any) => {
                  const value =
                    reciever[1]?.reduce(
                      (prev: number, curr: any) =>
                        item[0] === curr?.account?.ownerName
                          ? prev + curr?.value
                          : prev - curr?.value,
                      0,
                    ) / 100;
                  return (
                    <div key={reciever[0]}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="subtitle2">
                            {reciever[0]?.toUpperCase()}
                            {': '}
                            {
                              <Box
                                component="span"
                                color={value < 0 ? red[500] : green[400]}
                              >
                                {value}
                              </Box>
                            }
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box width="100%">
                            <BillingJournal
                              journal={reciever[1]?.map(
                                (item: any) => item.journal,
                              )}
                              totalCount={reciever[1]?.length}
                              loading={loading}
                              refetch={() => refetch()}
                              onFetch={() => refetch()}
                              cancel={cancel}
                            />
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
              </Box>
            </Paper>
          </Box>
        ))}

      {/* {Object.entries(result)?.map(item => (
        <div key={item[0]}>
          {item[1]?.reduce((prev, curr))}
          {<JSONPretty data={item[1]} />}
        </div>
      ))} */}
    </div>
  );
};
