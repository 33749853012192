import { useRequest } from 'src/hooks/useRequest';
import { request } from 'src/services/request';

export function useGetBrandSeries({
  variables,
  skip,
}: {
  variables: { brandId: string | number };
  skip?: boolean;
}) {
  return useRequest<any, typeof variables>({
    url: `/brands/${variables?.brandId}/series`,
    method: 'get',
    query: variables,
    skip,
  });
}



export function addSeriesToBrand(
  id: number | string,
  data: { name: string },
) {
  return request.post(`/brands/${id}/series`, data);
}

