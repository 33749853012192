import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogActions,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { typeWarehouse, typeWarehousePlace } from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';
import { useCreateWarehousePlace } from 'src/api/warehousesApi';

type typeProps = {
  warehouse?: typeWarehouse | null;
  onCreate: (newPlace: typeWarehousePlace) => any;
  name: string;
};

type typeState = {
  warehouse: typeWarehouse | null;
  name: string;
};

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'WarehousePlaceCreate',
});

export const WarehousePlaceCreate: React.FunctionComponent<typeProps> = ({
  warehouse,
  onCreate,
  name,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);

  const [state, changeState] = useState<typeState>({
    name: '',
    warehouse: { id: 1, alias: 'restan.used' },
  });

  const setState = (data: Partial<typeState>) =>
    changeState((prevState: typeState) => ({ ...prevState, ...data }));

  useEffect(() => {
    if (warehouse) {
      setState({ warehouse });
    }
    if (name) {
      setState({ name });
    }
  }, [warehouse, name]);

  const [create, { loading }] = useCreateWarehousePlace();

  const handleCreate = () => {
    if (state.name && state.warehouse) {
      create({ name: state.name, warehouse: state.warehouse }).then(
        (newPlace: typeWarehousePlace) => {
          setDialog(false);
          setState({ name: '' });
          if (newPlace) {
            onCreate(newPlace);
          }
        },
      );
    }
  };

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setDialog(true)}>
        <Add />
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <TextField
            variant="outlined"
            label="Место хранения"
            autoFocus
            onChange={e => setState({ name: e.target.value })}
            value={state.name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={!state.name}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
