import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Fab,
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Box,
  FormControlLabel,
  FormControl,
  Radio,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { typeBillingAccount, typeWarehouse } from 'src/types';
import { WithLoading } from 'src/components/common/with-loading';
import { useCreateBillingAccount } from 'src/api/billingApi';
import { BillingCurrencySelect } from './BillingCurrencySelect';

type typeProps = {
  warehouse?: typeWarehouse | null;
  onCreate: (newPlace?: typeBillingAccount | null) => any;
};

type typeState = {
  currency: any;
  name: string;
  ownerName: 'restan' | 'sto' | 'tyurin';
};

const useStyles = makeStyles((theme: Theme) => createStyles({}), {
  name: 'BillingAccountCreate',
});

export const BillingAccountCreate: React.FunctionComponent<typeProps> = ({
  onCreate,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);

  const [state, changeState] = useState<typeState>({
    name: '',
    currency: 'RUB',
    ownerName: 'restan',
  });

  const setState = (data: Partial<typeState>) =>
    changeState((prevState: typeState) => ({ ...prevState, ...data }));

  const [create, { loading }] = useCreateBillingAccount();

  const handleCreate = () => {
    if (state.name && state.currency) {
      create({
        name: state.name,
        currency: state.currency,
        ownerName: state.ownerName,
      }).then((account: typeBillingAccount) => {
        setDialog(false);
        setState({ name: '' });
        if (onCreate) {
          onCreate(account);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Fab color="primary" onClick={() => setDialog(true)}>
        <Add />
      </Fab>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <TextField
            variant="outlined"
            label="Название"
            autoFocus
            onChange={e => setState({ name: e.target.value })}
            value={state.name}
          />
          <Box mt={2}>
            <BillingCurrencySelect
              onChange={currency => setState({ currency })}
            />
          </Box>
          <Box mt={2}>
            <FormControlLabel
              onChange={e => setState({ ownerName: 'restan' })}
              value={state.ownerName}
              checked={state.ownerName === 'restan'}
              label="RESTAN"
              control={<Radio />}
            />
            <FormControlLabel
              onChange={e => setState({ ownerName: 'sto' })}
              value={state.ownerName}
              checked={state.ownerName === 'sto'}
              label="STO"
              control={<Radio />}
            />
            <FormControlLabel
              onChange={e => setState({ ownerName: 'tyurin' })}
              value={state.ownerName}
              checked={state.ownerName === 'tyurin'}
              label="TYURIN"
              control={<Radio />}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreate}
              color="primary"
              variant="contained"
              disabled={!state.name}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
