import { useRequest } from 'src/hooks/useRequest';
import {
  typeProduct,
  typeListObject,
  typeProductComponent,
  typeSupplier,
  typeProductName,
  typeProductUsage,
} from 'src/types';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';

export function useGetProduct(
  {
    variables,
    skip,
  }: {
    variables: { productId?: number | string };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeProduct>({
    url: `/products/${variables.productId}`,
    method: 'get',
    skip,
  });
}

export function useSearchProduct(
  {
    variables,
    skip,
  }: {
    variables: { article?: string };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<{ products: typeProduct[] }, typeof variables>({
    url: `/search`,
    method: 'get',
    skip,
    query: variables,
  });
}

export function useAddComponent() {
  return useRequestWithPayload<
    typeProductComponent,
    { product: typeProduct; component: typeProduct }
  >({
    url: '/components',
    method: 'post',
  });
}

export function useDeleteComponent() {
  return useRequestWithPayload<typeProductComponent, typeProductComponent>({
    url: `/components/delete`,
    method: 'post',
  });
}

export function useChangeProduct() {
  return useRequestWithPayload<
    typeProduct,
    {
      id: number;
      mainComponent?: typeProductComponent | null;
    }
  >({
    url: '/products',
    method: 'patch',
  });
}

export function useCreateProduct() {
  return useRequestWithPayload<
    typeProduct,
    {
      supplier: typeSupplier;
      name: typeProductName | null;
      topBottom: string | null,
      leftRight: string | null,
      frontRear: string | null,
    }
  >({
    url: '/products',
    method: 'post',
  });
}

export function useGetProductNames() {
  return useRequest<typeProductName[]>({
    url: '/names',
    method: 'get',
  });
}

export function useChangeProductName() {
  return useRequestWithPayload<typeProductName, typeProductName>({
    url: '/names',
    method: 'post',
  });
}

export function useCreateProductUsage() {
  return useRequestWithPayload<typeProductUsage, Partial<typeProductUsage>>({
    url: '/products/usage',
    method: 'post',
  });
}

export function useGetProductsByCmponent(
  {
    variables,
    skip,
  }: {
    variables: { productComponentId?: number | string };
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<typeProductComponent[]>({
    url: '/products/by-component',
    method: 'get',
    query: variables,
    skip,
  });
}
