import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Button, Popover, Theme } from '@material-ui/core';

interface typeProps {
  confirmTitle?: string;
  cancelTitle?: string;
  onConfirm?: () => any;
  disabled?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'WithConfirm' },
);

export const WithConfirm: React.FunctionComponent<typeProps> = ({
  confirmTitle = 'Подтвердить',
  cancelTitle = 'Отмена',
  onConfirm,
  children,
  disabled = false,
}) => {
  const classes = useStyles({});

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    setAnchorEl(null);
  };

  return (
    <>
      <div onClick={e => !disabled && setAnchorEl(e.currentTarget)}>
        {children}
      </div>
      <Popover
        className={classes.root}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box display="flex">
          <Box mr={2}>
            <Button onClick={() => setAnchorEl(null)}>{cancelTitle}</Button>
          </Box>
          <Button color="primary" onClick={handleConfirm}>
            {confirmTitle}
          </Button>
        </Box>
      </Popover>
    </>
  );
};
