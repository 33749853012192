import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import cn from 'classnames';

type typeProps = {
  data: any[];
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: '#fff',
        color: '#000',
        maxWidth: '740px',
        fontSize: theme.typography.pxToRem(16),
      },

      item: {
        margin: '3px',
        width: '240px',
        height: '80px',
        padding: '10px',
        border: '1px solid',
        borderStyle: 'dashed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      group: {
        marginTop: '16px',
        flexWrap: 'wrap',
        display: 'flex',
      },
      pagebreak: {
        pageBreakAfter: 'always',
      },
      '@media print': {
        pagebreak: {
          clear: 'both',
          pageBreakAfter: 'always',
        },
      },
      '@page': {
        size: 'A4 portrait',
        margin: '2cm 3cm',
      },
    }),
  { name: 'LabelsPrint' },
);

function chunkArray(myArray: any[], chunk_size: number) {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}

export const LabelsPrint: React.FunctionComponent<typeProps> = ({ data }) => {
  const classes = useStyles({});

  const groups = chunkArray(data, 36);

  return (
    <div className={classes.root}>
      {groups.map((group: any) => (
        <div className={cn(classes.group, classes.pagebreak)}>
          {group.map((item: any, index: number) => (
            <div key={index} className={classes.item}>
              {item}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
