import { useRequest } from 'src/hooks/useRequest';
import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import { request } from 'src/services/request';

export function useGetAttributes(
  {
    variables,
    skip,
  }: {
    variables?: {};
    skip?: boolean;
  } = { variables: {} },
) {
  return useRequest<any, typeof variables>({
    url: '/attributes',
    method: 'get',
    query: variables,
    skip,
  });
}

export function useCreateAttribute(
  {
    variables,
  }: {
    variables: any;
  } = { variables: {} },
) {
  return useRequestWithPayload<any, any>({
    url: `/attributes`,
    method: 'post',
  });
}


export function addAValueToStringAttribure(
  typeId: number | string,
  attribute: { value: string },
) {
  return request.post(`/attributes/${typeId}/values`, attribute);
}

