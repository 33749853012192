import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
} from '@material-ui/core';
import {
  BillingJournal,
  typeProps as typePropsBillingJournal,
} from './BillingJournal';

interface typeProps extends typePropsBillingJournal {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'BillingJournalDialog' },
);

export const BillingJournalDialog: React.FunctionComponent<typeProps> = ({
  children,
  journal,
  totalCount,
  loading,
  cancel,
  refetch,
}) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);

  return (
    <div className={classes.root}>
      <div onClick={() => setDialog(true)}>{children}</div>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <BillingJournal
            journal={journal}
            totalCount={totalCount}
            loading={loading}
            refetch={refetch}
            cancel={cancel}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
